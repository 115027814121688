import React, { useState } from 'react';
import { Text } from '~/ui/index.js';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { actions, useDispatch } from '~/data/index.js';
import { useHistory, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Search from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import { useDebounceValue } from '~/app/utils/debounce';
import Loader from '~/app/components/core/preloader';
import { manageClientPath } from '~/app/constants/url/auditor';
import ThreeDotsLoader from '~/ui/components/three-dots-loader';
import s from './styles.module.scss';
import Modal from '~/ui/components/modal/index';
import { useQuery } from '@tanstack/react-query';
import { dazzler } from '~/data/dazzler-api';
import { Direction, type UserDto, UserSort } from '~/data/openapi-client/index';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { queryKeyBuilder } from '~/data/utils/helpers/query-key.builder';
import { useCleanup } from '~/app/hooks';

interface AvailableUsersPopupProps {
  closeFunction: () => void;
}

const AvailableUsersPopup = ({ closeFunction }: AvailableUsersPopupProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { clientId } = useParams<{ clientId: string }>();

  const [selectedUsers, setSelectedUsers] = useState<Array<UserDto>>([]);
  const [error, setError] = useState('');

  const [params, setParams] = useQueryParams({
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, 10),
    sort: withDefault(StringParam, UserSort.FamilyName),
    direction: withDefault(StringParam, Direction.Asc),
    search: withDefault(StringParam, ''),
  });

  const debouncedSearch = useDebounceValue(params.search);

  const requestParams = {
    ...params,
    search: debouncedSearch,
  };

  const { data: userList, isSuccess } = useQuery({
    queryFn: async function fetchUsers({ signal }) {
      const response = await dazzler.clientAdmins.getUserList(
        {
          id: clientId,
          connectivityType: 'Available',
          search: debouncedSearch,
          offset: requestParams.offset,
          limit: requestParams.limit,
          sort: requestParams.sort as UserSort,
          direction: requestParams.direction as Direction,
        },
        { signal },
      );

      return response.data;
    },
    keepPreviousData: true,
    queryKey: queryKeyBuilder('admin', 'clients', clientId, 'users', 'list', 'available', requestParams),
  });

  const connectUsers = (values) => dispatch(actions.users.connectUsers(clientId, values));

  useCleanup(() => {
    dispatch(actions.users.clear());
  });

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedUsers(userList?.items ?? []);
      return;
    }
    setSelectedUsers([]);
  };

  const isSelected = (id: string) => !!selectedUsers.find((el) => el.id === id);

  const handleClick = (event: React.MouseEvent<unknown>, user) => {
    const selectedIndex = selectedUsers.map((el) => el.id).indexOf(user.id);
    let newSelected: Array<UserDto> = [];

    switch (selectedIndex) {
      case -1:
        newSelected = newSelected.concat(selectedUsers, user);
        break;
      case 0:
        newSelected = newSelected.concat(selectedUsers.slice(1));
        break;
      case selectedUsers.length - 1:
        newSelected = newSelected.concat(selectedUsers.slice(0, -1));
        break;
      default:
        newSelected = newSelected.concat(selectedUsers.slice(0, selectedIndex), selectedUsers.slice(selectedIndex + 1));
        break;
    }

    setSelectedUsers(newSelected);
  };

  const handleConnectClients = async () => {
    if (!selectedUsers.length) {
      setError('Please select client');
    } else {
      const res = await connectUsers({ emails: selectedUsers.map((el) => el.email) });
      // @ts-expect-error - This condition will always return true since this function is always defined. Did you mean to call it instead?
      if (res) {
        history.push(manageClientPath(clientId));
      }
    }
  };

  return (
    <Modal
      open
      closeIcon
      maxWidth="md"
      onClose={closeFunction}
      modalTitle={<Text weight={Text.weight.medium}>Add user access</Text>}
    >
      <Box marginBottom="20px" display="flex">
        <Box marginRight="20px" display="flex" flexGrow="1">
          <TextField
            placeholder="Search user"
            InputProps={{
              startAdornment: <Search htmlColor={Text.color.light} />,
            }}
            onChange={({ target: { value } }) => setParams({ search: value })}
            value={params.search}
            size="small"
            color="primary"
            fullWidth
          />
        </Box>
      </Box>
      <Box id="scrollableDiv" style={{ height: 400, overflow: 'auto' }}>
        <TableContainer sx={{ maxHeight: 390 }}>
          <InfiniteScroll
            dataLength={userList?.items.length || 0}
            hasMore={!!userList && userList.items.length !== userList.count}
            next={() =>
              setParams((p) => ({
                offset: p.offset + 10,
              }))
            }
            loader={<ThreeDotsLoader />}
            height={390}
            style={{ width: '100%' }}
            scrollableTarget="scrollableDiv"
          >
            {isSuccess && !userList?.items.length ? (
              <Loader />
            ) : (
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell width="10%" key="checkbox">
                      <Checkbox
                        size="small"
                        color="primary"
                        indeterminate={
                          !!userList?.items.length && selectedUsers.length > 0 && selectedUsers.length < userList.count
                        }
                        checked={
                          !!userList?.items.length &&
                          userList.count > 0 &&
                          selectedUsers.length === userList?.items.length
                        }
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>

                    <TableCell key="name" width="30%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Name
                      </Text>
                    </TableCell>

                    <TableCell key="familyName" width="40%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Family name
                      </Text>
                    </TableCell>
                    <TableCell key="familyName" width="30%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Email
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {userList?.items.map((user) => {
                    const isItemSelected = isSelected(user.id!);
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={user.id}
                        onClick={(event) => handleClick(event, user)}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox size="small" color="primary" checked={isItemSelected} />
                        </TableCell>

                        <TableCell>
                          <Text size={Text.size.s}>{user.firstName}</Text>
                        </TableCell>
                        <TableCell>
                          <Text size={Text.size.s}>{user.familyName}</Text>
                        </TableCell>
                        <TableCell>
                          <Text size={Text.size.s}>{user.email}</Text>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </InfiniteScroll>
        </TableContainer>
      </Box>
      <Box padding="0 16px">
        <Text size={Text.size.xs} color={Text.color.red}>
          {error}
        </Text>
      </Box>
      <Box className={s.formButtonsWrap}>
        <Button onClick={closeFunction} variant="outlined">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleConnectClients}>
          Select
        </Button>
      </Box>
    </Modal>
  );
};

export default AvailableUsersPopup;
