import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import cx from '~/data/utils/helpers/cx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReviewedValidationCheckRepresentation, ValidationCheckReviewStatus } from '~/data/openapi-client/index';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Paper } from '@mui/material';
import { CellRange } from '~/app/components/visual-excel/worksheet';

const statusMapper = {
  [ValidationCheckReviewStatus.Pending]: 'To review',
  [ValidationCheckReviewStatus.Flagged]: 'Flagged',
  Passed: 'Passed',
  [ValidationCheckReviewStatus.BelowThreshold]: 'Below threshold',
  [ValidationCheckReviewStatus.Waived]: 'Waived',
  Total: 'Total',
};

const statusTagClasses = {
  [ValidationCheckReviewStatus.Pending]: 'text-orange bg-orange bg-opacity-10',
  [ValidationCheckReviewStatus.Flagged]: 'text-[#EB8C00] bg-[#EB8C00] bg-opacity-10',
  Passed: 'text-[#22992E] bg-[#22992E] bg-opacity-10',
  [ValidationCheckReviewStatus.BelowThreshold]: 'text-[#22992E] bg-[#22992E] bg-opacity-10',
  [ValidationCheckReviewStatus.Waived]: 'text-[#2B9AF3] bg-[#2B9AF3] bg-opacity-10',
  Total: 'text-text-dark-grey bg-text-dark-grey bg-opacity-10',
};

const statusCardClasses = {
  [ValidationCheckReviewStatus.Pending]: 'border-orange',
  [ValidationCheckReviewStatus.Flagged]: 'border-[#EB8C00]',
  Passed: 'border-[#22992E]',
  [ValidationCheckReviewStatus.BelowThreshold]: 'border-[#22992E]',
  [ValidationCheckReviewStatus.Waived]: 'border-[#2B9AF3]',
  Total: 'border-text-dark-grey',
};

interface CheckCardProps {
  item: ReviewedValidationCheckRepresentation;
  handleSelectRange: (versionParticlesFileId: string, sheet: string, range: CellRange) => void;
  setOpenedCheckId: (checkId: string | null) => void;
  open: boolean;
  checkList: ReviewedValidationCheckRepresentation[];
}

export const ReadonlyCheckCard = ({ item, handleSelectRange, setOpenedCheckId, open, checkList }: CheckCardProps) => {
  const versionParticlesFileId = item.versionParticlesFileId!;

  const getExpression = (expression: string, cellValues: ReviewedValidationCheckRepresentation['cellValues']) => {
    const array: { value: string; visualExcelMapping?: { fileId: string; sheet: string; range: CellRange } }[] = [];
    let prevIndex = 0;

    cellValues?.forEach((val, i) => {
      if (val.expression) {
        const startIndex = expression.indexOf(val.expression);
        const endIndex = startIndex + val.expression.length;
        array.push({ value: expression.slice(prevIndex, startIndex) });
        array.push({
          value: val.expression,
          visualExcelMapping: {
            fileId: val.combinationFileId ?? versionParticlesFileId,
            sheet: val.sheet ?? '',
            range: {
              start: { row: Number(val.row) - 1, col: Number(val.column) - 1 },
              end: { row: Number(val.row) - 1, col: Number(val.column) - 1 },
            },
          },
        });

        if (i === cellValues.length - 1) {
          array.push({ value: expression.slice(endIndex) });
        }
        prevIndex = endIndex;
      }
    });

    if (!array.length) return expression;

    return array.map((item, i) => (
      <>
        {item.visualExcelMapping ? (
          <span
            role="button"
            className="underline"
            data-expression={i}
            onClick={() => {
              if (item.visualExcelMapping) {
                handleSelectRange(
                  item.visualExcelMapping.fileId,
                  item.visualExcelMapping.sheet,
                  item.visualExcelMapping.range,
                );
              }
            }}
          >
            {item.value}
          </span>
        ) : (
          item.value
        )}
      </>
    ));
  };

  const getSubstitution = (substitution: string, cellValues: ReviewedValidationCheckRepresentation['cellValues']) => {
    const array: { value: string; visualExcelMapping?: { fileId: string; sheet: string; range: CellRange } }[] = [];
    let prevIndex = 0;

    cellValues?.forEach((val, i) => {
      const stringVal = val.value?.toString();
      if (stringVal) {
        const startIndex = substitution.indexOf(stringVal);
        const endIndex = startIndex + stringVal.length;
        array.push({ value: substitution.slice(prevIndex, startIndex) });
        array.push({
          value: stringVal,
          visualExcelMapping: {
            fileId: val.combinationFileId ?? versionParticlesFileId,
            sheet: val.sheet ?? '',
            range: {
              start: { row: Number(val.row) - 1, col: Number(val.column) - 1 },
              end: { row: Number(val.row) - 1, col: Number(val.column) - 1 },
            },
          },
        });
        if (i === cellValues.length - 1) {
          array.push({ value: substitution.slice(endIndex) });
        }
        prevIndex = endIndex;
      }
    });

    if (!array.length) return substitution;

    return array.map((item, i) => (
      <>
        {item.visualExcelMapping ? (
          <span
            role="button"
            className="underline"
            onClick={() => {
              if (item.visualExcelMapping) {
                handleSelectRange(
                  item.visualExcelMapping.fileId,
                  item.visualExcelMapping.sheet,
                  item.visualExcelMapping.range,
                );
              }
            }}
          >
            {item.value}
          </span>
        ) : (
          item.value
        )}
      </>
    ));
  };

  const status = (
    <div className={cx('flex items-center', open && 'items-end flex-col grow')}>
      <div className="flex items-center">
        <div>
          <button
            aria-describedby="status-popover"
            className={cx('px-[7px] py-[1px] rounded-sm', statusTagClasses[item.status as ValidationCheckReviewStatus])}
          >
            <Typography className="whitespace-nowrap" fontSize={12}>
              {statusMapper[item.status as ValidationCheckReviewStatus]}
            </Typography>
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <Paper
      className={cx(
        'border-0 border-r-[3px] flex py-0 pl-0 pr-6',
        open && 'pr-0',
        statusCardClasses[item.status as ValidationCheckReviewStatus],
      )}
    >
      <Accordion
        className="border-0"
        classes={{
          root: 'px-6 py-4',
          expanded: '!m-0',
        }}
        expanded={!!open}
        onChange={(e, expended) => {
          const val = item?.cellValues?.[0];
          if (expended && val) {
            const sheet = val.sheet;
            const cell = { row: Number(val.row) - 1, col: Number(val.column) - 1 };

            handleSelectRange(val.combinationFileId || versionParticlesFileId, sheet ?? '', {
              start: { ...cell },
              end: { ...cell },
            });
          }
          setOpenedCheckId(expended ? item.id ?? null : null);
        }}
      >
        <AccordionSummary
          classes={{
            root: 'p-0 min-h-0',
            content: 'm-0',
            expanded: '[&_svg]:rotate-180 !m-0 !min-h-0',
          }}
        >
          <div className="w-full flex justify-between">
            <div>
              <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                  <ExpandMoreIcon fontSize="medium" className="text-orange" />
                  <Typography fontSize={14} className="m-0 text-[#333333]">
                    Amounts
                  </Typography>
                </div>
              </div>
              <div className="flex gap-6 ml-8 mt-2">
                <div className="flex flex-col items-start">
                  <Typography fontSize={14} className="font-bold text-text-dark-grey">
                    Check code
                  </Typography>
                  <Typography fontSize={14} className="text-text-dark-grey normal-case">
                    {item.code}
                  </Typography>
                </div>
                <div className="flex flex-col items-start">
                  <Typography fontSize={14} className="font-bold text-text-dark-grey">
                    Description
                  </Typography>
                  <Typography fontSize={14} className="text-text-dark-grey">
                    {item.description}
                  </Typography>
                </div>
                <div className="flex flex-col items-start">
                  <Typography fontSize={14} className="font-bold text-text-dark-grey">
                    Difference
                  </Typography>
                  <Typography fontSize={14} className="text-text-dark-grey">
                    {item.difference ? Intl.NumberFormat().format(+item.difference) : '-'}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="ml-4 pr-0 pb-0">
          <div className="flex justify-between items-end">
            <div className="w-full">
              <div>
                <Typography fontSize={14} className="font-bold">
                  Expression
                </Typography>
                <Typography fontSize={14} fontFamily="Roboto">
                  {item.expression ? getExpression(item.expression, item.cellValues) : '-'}
                </Typography>
              </div>
              <div className="mt-2">
                <Typography fontSize={14} className="font-bold">
                  Result
                </Typography>
                <Typography fontSize={14} fontFamily="Roboto">
                  {item.substitution ? getSubstitution(item.substitution, item.cellValues) : '-'}
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex flex-end w-full mt-1">{open && status}</div>
        </AccordionDetails>
      </Accordion>
      {!open && status}
    </Paper>
  );
};
