/* tslint:disable */
/* eslint-disable */
/**
 * Dazzler API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccessTokenRepresentation
 */
export interface AccessTokenRepresentation {
    /**
     * 
     * @type {string}
     * @memberof AccessTokenRepresentation
     */
    'accessToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenRepresentation
     */
    'refreshToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenRepresentation
     */
    'scope'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenRepresentation
     */
    'idToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenRepresentation
     */
    'tokenType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccessTokenRepresentation
     */
    'expiresIn'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenRepresentation
     */
    'nonce'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApimRegistrationStatus = {
    Success: 'Success',
    NotAuthenticated: 'NotAuthenticated',
    UserNotFound: 'UserNotFound',
    DuplicateUser: 'DuplicateUser',
    MissingRequiredField: 'MissingRequiredField',
    DataDoesNotMeetRequirements: 'DataDoesNotMeetRequirements',
    DataDoesNotMeetMinimumOrMaximumLength: 'DataDoesNotMeetMinimumOrMaximumLength',
    Fail: 'Fail',
    InvalidTerritory: 'InvalidTerritory',
    ExistsIngroup: 'ExistsIngroup',
    ValidationErrors: 'ValidationErrors',
    DifferentUser: 'DifferentUser',
    UserAddedToGroup: 'UserAddedToGroup'
} as const;

export type ApimRegistrationStatus = typeof ApimRegistrationStatus[keyof typeof ApimRegistrationStatus];


/**
 * 
 * @export
 * @interface ApplyActionToAllValidationChecksValidator
 */
export interface ApplyActionToAllValidationChecksValidator {
    /**
     * 
     * @type {ValidationChecksAction}
     * @memberof ApplyActionToAllValidationChecksValidator
     */
    'action': ValidationChecksAction;
    /**
     * When listing the validation checks, you always receive a filter hash that encodes the filter parameters. To apply an action to those filtered checks, you need to pass along the hash value
     * @type {string}
     * @memberof ApplyActionToAllValidationChecksValidator
     */
    'filterHash': string;
    /**
     * Motivation is applied to the bulk of specified checks. It can be required depending on the type of action
     * @type {string}
     * @memberof ApplyActionToAllValidationChecksValidator
     */
    'motivation'?: string | null;
}


/**
 * 
 * @export
 * @interface AuditLogRepresentation
 */
export interface AuditLogRepresentation {
    /**
     * 
     * @type {string}
     * @memberof AuditLogRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogRepresentation
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogRepresentation
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogRepresentation
     */
    'contextJson': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogRepresentation
     */
    'relatedEntityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditLogRepresentation
     */
    'action': string;
    /**
     * 
     * @type {LogActorRepresentation}
     * @memberof AuditLogRepresentation
     */
    'actor': LogActorRepresentation;
}
/**
 * 
 * @export
 * @interface AuditLogRepresentationListRepresentation
 */
export interface AuditLogRepresentationListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof AuditLogRepresentationListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<AuditLogRepresentation>}
     * @memberof AuditLogRepresentationListRepresentation
     */
    'items': Array<AuditLogRepresentation>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AuditLogSort = {
    Timestamp: 'Timestamp',
    Id: 'Id'
} as const;

export type AuditLogSort = typeof AuditLogSort[keyof typeof AuditLogSort];


/**
 * 
 * @export
 * @enum {string}
 */

export const BftStatus = {
    NotYetStarted: 'NotYetStarted',
    Processing: 'Processing',
    Done: 'Done',
    Saving: 'Saving',
    Failed: 'Failed'
} as const;

export type BftStatus = typeof BftStatus[keyof typeof BftStatus];


/**
 * 
 * @export
 * @interface Claim
 */
export interface Claim {
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'issuer'?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'originalIssuer'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Claim
     */
    'properties'?: { [key: string]: string; };
    /**
     * 
     * @type {ClaimsIdentity}
     * @memberof Claim
     */
    'subject'?: ClaimsIdentity;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'valueType'?: string;
}
/**
 * 
 * @export
 * @interface ClaimsIdentity
 */
export interface ClaimsIdentity {
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    'authenticationType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIdentity
     */
    'isAuthenticated'?: boolean;
    /**
     * 
     * @type {ClaimsIdentity}
     * @memberof ClaimsIdentity
     */
    'actor'?: ClaimsIdentity;
    /**
     * 
     * @type {any}
     * @memberof ClaimsIdentity
     */
    'bootstrapContext'?: any | null;
    /**
     * 
     * @type {Array<Claim>}
     * @memberof ClaimsIdentity
     */
    'claims'?: Array<Claim>;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    'nameClaimType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    'roleClaimType'?: string;
}
/**
 * 
 * @export
 * @interface ClaimsPrincipal
 */
export interface ClaimsPrincipal {
    /**
     * 
     * @type {Array<Claim>}
     * @memberof ClaimsPrincipal
     */
    'claims'?: Array<Claim>;
    /**
     * 
     * @type {Array<ClaimsIdentity>}
     * @memberof ClaimsPrincipal
     */
    'identities'?: Array<ClaimsIdentity>;
    /**
     * 
     * @type {IIdentity}
     * @memberof ClaimsPrincipal
     */
    'identity'?: IIdentity;
}
/**
 * 
 * @export
 * @interface ClientAdminDto
 */
export interface ClientAdminDto {
    /**
     * 
     * @type {string}
     * @memberof ClientAdminDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientAdminDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientAdminDto
     */
    'vatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientAdminDto
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {ClientType}
     * @memberof ClientAdminDto
     */
    'type'?: ClientType;
    /**
     * 
     * @type {Array<ClientUserRepresentation>}
     * @memberof ClientAdminDto
     */
    'clientUsers'?: Array<ClientUserRepresentation> | null;
    /**
     * 
     * @type {string}
     * @memberof ClientAdminDto
     */
    'parentClientId'?: string | null;
    /**
     * 
     * @type {CountryCode}
     * @memberof ClientAdminDto
     */
    'countryCode'?: CountryCode;
    /**
     * 
     * @type {MaterialityDto}
     * @memberof ClientAdminDto
     */
    'materiality'?: MaterialityDto;
}


/**
 * 
 * @export
 * @interface ClientAdminDtoListRepresentation
 */
export interface ClientAdminDtoListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof ClientAdminDtoListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<ClientAdminDto>}
     * @memberof ClientAdminDtoListRepresentation
     */
    'items': Array<ClientAdminDto>;
}
/**
 * 
 * @export
 * @interface ClientAdminValidator
 */
export interface ClientAdminValidator {
    /**
     * 
     * @type {MaterialityDto}
     * @memberof ClientAdminValidator
     */
    'materiality'?: MaterialityDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ClientComparisonSort = {
    Inserted: 'Inserted',
    LastUpdated: 'LastUpdated'
} as const;

export type ClientComparisonSort = typeof ClientComparisonSort[keyof typeof ClientComparisonSort];


/**
 * 
 * @export
 * @interface ClientCreateValidator
 */
export interface ClientCreateValidator {
    /**
     * 
     * @type {string}
     * @memberof ClientCreateValidator
     */
    'iPowerClientCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateValidator
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateValidator
     */
    'vatNumber'?: string | null;
    /**
     * 
     * @type {CountryCode}
     * @memberof ClientCreateValidator
     */
    'countryCode': CountryCode;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateValidator
     */
    'parentClientId'?: string | null;
}


/**
 * 
 * @export
 * @interface ClientDto
 */
export interface ClientDto {
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'vatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {ClientType}
     * @memberof ClientDto
     */
    'type'?: ClientType;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    'parentClientId'?: string | null;
    /**
     * 
     * @type {CountryCode}
     * @memberof ClientDto
     */
    'countryCode'?: CountryCode;
}


/**
 * 
 * @export
 * @interface ClientDtoListRepresentation
 */
export interface ClientDtoListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof ClientDtoListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<ClientDto>}
     * @memberof ClientDtoListRepresentation
     */
    'items': Array<ClientDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ClientSort = {
    Name: 'Name',
    VatNumber: 'VatNumber',
    Inserted: 'Inserted',
    LastUpdated: 'LastUpdated'
} as const;

export type ClientSort = typeof ClientSort[keyof typeof ClientSort];


/**
 * 
 * @export
 * @enum {string}
 */

export const ClientType = {
    Audit: 'Audit',
    NonAudit: 'NonAudit'
} as const;

export type ClientType = typeof ClientType[keyof typeof ClientType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ClientUserConnectionType = {
    Regular: 'Regular',
    Manual: 'Manual'
} as const;

export type ClientUserConnectionType = typeof ClientUserConnectionType[keyof typeof ClientUserConnectionType];


/**
 * 
 * @export
 * @interface ClientUserRepresentation
 */
export interface ClientUserRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ClientUserRepresentation
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientUserRepresentation
     */
    'clientId'?: string;
    /**
     * 
     * @type {ClientUserConnectionType}
     * @memberof ClientUserRepresentation
     */
    'type'?: ClientUserConnectionType;
    /**
     * 
     * @type {boolean}
     * @memberof ClientUserRepresentation
     */
    'isAdmin'?: boolean;
}


/**
 * 
 * @export
 * @interface ClientUserValidator
 */
export interface ClientUserValidator {
    /**
     * 
     * @type {boolean}
     * @memberof ClientUserValidator
     */
    'isAdmin': boolean;
}
/**
 * 
 * @export
 * @interface ClientUsersValidator
 */
export interface ClientUsersValidator {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientUsersValidator
     */
    'emails': Array<string>;
}
/**
 * 
 * @export
 * @interface ClientValidator
 */
export interface ClientValidator {
    /**
     * 
     * @type {string}
     * @memberof ClientValidator
     */
    'vatNumber': string;
}
/**
 * 
 * @export
 * @interface ComparisonFileRepresentation
 */
export interface ComparisonFileRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ComparisonFileRepresentation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonFileRepresentation
     */
    'inserted'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonFileRepresentation
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ComparisonFileRepresentationListRepresentation
 */
export interface ComparisonFileRepresentationListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof ComparisonFileRepresentationListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<ComparisonFileRepresentation>}
     * @memberof ComparisonFileRepresentationListRepresentation
     */
    'items': Array<ComparisonFileRepresentation>;
}
/**
 * 
 * @export
 * @interface ComparisonRepresentation
 */
export interface ComparisonRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ComparisonRepresentation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRepresentation
     */
    'inserted'?: string;
    /**
     * 
     * @type {ComparisonStatus}
     * @memberof ComparisonRepresentation
     */
    'status'?: ComparisonStatus;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRepresentation
     */
    'visualExcelFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRepresentation
     */
    'clientId'?: string;
    /**
     * 
     * @type {ComparisonFileRepresentation}
     * @memberof ComparisonRepresentation
     */
    'leftFile'?: ComparisonFileRepresentation;
    /**
     * 
     * @type {ComparisonFileRepresentation}
     * @memberof ComparisonRepresentation
     */
    'rightFile'?: ComparisonFileRepresentation;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRepresentation
     */
    'creatorUserId'?: string | null;
    /**
     * 
     * @type {UserContactDetailsRepresentation}
     * @memberof ComparisonRepresentation
     */
    'creator'?: UserContactDetailsRepresentation;
}


/**
 * 
 * @export
 * @interface ComparisonRepresentationListRepresentation
 */
export interface ComparisonRepresentationListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof ComparisonRepresentationListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<ComparisonRepresentation>}
     * @memberof ComparisonRepresentationListRepresentation
     */
    'items': Array<ComparisonRepresentation>;
}
/**
 * Comparison between 2 bft rows on the same position
 * @export
 * @interface ComparisonRow
 */
export interface ComparisonRow {
    /**
     * 
     * @type {string}
     * @memberof ComparisonRow
     */
    'Id': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRow
     */
    'TableName': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRow
     */
    'TableCode': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRow
     */
    'RowCode': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRow
     */
    'ColumnCode': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRow
     */
    'PageCode': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRow
     */
    'ZAxis': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRow
     */
    'ZAxisLabel': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRow
     */
    'ModuleLabel': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRow
     */
    'LeftValueRaw': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRow
     */
    'RightValueRaw': string;
    /**
     * Numeric representation of the initial BFT row value. If the raw value is not a number, the parsed value is null
     * @type {number}
     * @memberof ComparisonRow
     */
    'LeftValueParsed'?: number | null;
    /**
     * Numeric representation of the initial BFT row value. If the raw value is not a number, the parsed value is null
     * @type {number}
     * @memberof ComparisonRow
     */
    'RightValueParsed'?: number | null;
    /**
     * Absolute difference between LeftValueParsed and RightValueParsed
     * @type {number}
     * @memberof ComparisonRow
     */
    'Difference'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ComparisonRow
     */
    'Unit': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ComparisonStatus = {
    Idle: 'Idle',
    Success: 'Success',
    Error: 'Error',
    Processing: 'Processing'
} as const;

export type ComparisonStatus = typeof ComparisonStatus[keyof typeof ComparisonStatus];


/**
 * 
 * @export
 * @interface ComparisonValidator
 */
export interface ComparisonValidator {
    /**
     * 
     * @type {string}
     * @memberof ComparisonValidator
     */
    'leftComparisonFileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonValidator
     */
    'rightComparisonFileId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ConnectivityType = {
    Available: 'Available',
    Connected: 'Connected'
} as const;

export type ConnectivityType = typeof ConnectivityType[keyof typeof ConnectivityType];


/**
 * ISO 3166-1 alpha-2 = ISO 3166-1 numeric  https://www.iso.org/iso-3166-country-codes.html
 * @export
 * @enum {string}
 */

export const CountryCode = {
    Af: 'AF',
    Al: 'AL',
    Aq: 'AQ',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ag: 'AG',
    Az: 'AZ',
    Ar: 'AR',
    Au: 'AU',
    At: 'AT',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Am: 'AM',
    Bb: 'BB',
    Be: 'BE',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Ba: 'BA',
    Bw: 'BW',
    Br: 'BR',
    Bz: 'BZ',
    Io: 'IO',
    Sb: 'SB',
    Vg: 'VG',
    Bn: 'BN',
    Bg: 'BG',
    Mm: 'MM',
    Bi: 'BI',
    By: 'BY',
    Kh: 'KH',
    Cm: 'CM',
    Ca: 'CA',
    Cv: 'CV',
    Ky: 'KY',
    Cf: 'CF',
    Lk: 'LK',
    Td: 'TD',
    Cl: 'CL',
    Cn: 'CN',
    Tw: 'TW',
    Co: 'CO',
    Km: 'KM',
    Yt: 'YT',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Hr: 'HR',
    Cu: 'CU',
    Cy: 'CY',
    Cz: 'CZ',
    Bj: 'BJ',
    Dk: 'DK',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Sv: 'SV',
    Gq: 'GQ',
    Et: 'ET',
    Er: 'ER',
    Ee: 'EE',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Dj: 'DJ',
    Ga: 'GA',
    Ge: 'GE',
    Gm: 'GM',
    Ps: 'PS',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Ki: 'KI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gn: 'GN',
    Gy: 'GY',
    Ht: 'HT',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Il: 'IL',
    It: 'IT',
    Ci: 'CI',
    Jm: 'JM',
    Jp: 'JP',
    Kz: 'KZ',
    Jo: 'JO',
    Ke: 'KE',
    Kr: 'KR',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lb: 'LB',
    Ls: 'LS',
    Lv: 'LV',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Mx: 'MX',
    Mc: 'MC',
    Mn: 'MN',
    Md: 'MD',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Om: 'OM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Cw: 'CW',
    Aw: 'AW',
    Sx: 'SX',
    Nc: 'NC',
    Vu: 'VU',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    No: 'NO',
    Mp: 'MP',
    Fm: 'FM',
    Mh: 'MH',
    Pw: 'PW',
    Pk: 'PK',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Gw: 'GW',
    Tl: 'TL',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Ai: 'AI',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sk: 'SK',
    Vn: 'VN',
    Si: 'SI',
    So: 'SO',
    Za: 'ZA',
    Zw: 'ZW',
    Es: 'ES',
    Ss: 'SS',
    Sd: 'SD',
    Eh: 'EH',
    Sr: 'SR',
    Sz: 'SZ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tj: 'TJ',
    Th: 'TH',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Ae: 'AE',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Mk: 'MK',
    Eg: 'EG',
    Gb: 'GB',
    Gg: 'GG',
    Je: 'JE',
    Im: 'IM',
    Tz: 'TZ',
    Us: 'US',
    Vi: 'VI',
    Bf: 'BF',
    Uy: 'UY',
    Uz: 'UZ',
    Ve: 'VE',
    Ws: 'WS',
    Ye: 'YE',
    Zm: 'ZM'
} as const;

export type CountryCode = typeof CountryCode[keyof typeof CountryCode];


/**
 * 
 * @export
 * @interface CreatePowerBiExplanationSectionValidator
 */
export interface CreatePowerBiExplanationSectionValidator {
    /**
     * 
     * @type {string}
     * @memberof CreatePowerBiExplanationSectionValidator
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePowerBiExplanationSectionValidator
     */
    'htmlContent': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePowerBiExplanationSectionValidator
     */
    'position': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof CreatePowerBiExplanationSectionValidator
     */
    'scopeType': ScopeType;
}


/**
 * 
 * @export
 * @interface DetailedComparisonRepresentation
 */
export interface DetailedComparisonRepresentation {
    /**
     * 
     * @type {string}
     * @memberof DetailedComparisonRepresentation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedComparisonRepresentation
     */
    'inserted'?: string;
    /**
     * 
     * @type {ComparisonStatus}
     * @memberof DetailedComparisonRepresentation
     */
    'status'?: ComparisonStatus;
    /**
     * 
     * @type {string}
     * @memberof DetailedComparisonRepresentation
     */
    'visualExcelFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedComparisonRepresentation
     */
    'clientId'?: string;
    /**
     * 
     * @type {ComparisonFileRepresentation}
     * @memberof DetailedComparisonRepresentation
     */
    'leftFile'?: ComparisonFileRepresentation;
    /**
     * 
     * @type {ComparisonFileRepresentation}
     * @memberof DetailedComparisonRepresentation
     */
    'rightFile'?: ComparisonFileRepresentation;
    /**
     * 
     * @type {string}
     * @memberof DetailedComparisonRepresentation
     */
    'creatorUserId'?: string | null;
    /**
     * 
     * @type {UserContactDetailsRepresentation}
     * @memberof DetailedComparisonRepresentation
     */
    'creator'?: UserContactDetailsRepresentation;
    /**
     * The latest significant actions happened throughout the processing. This may not include all the results
     * @type {Array<ProcessingLogRepresentation>}
     * @memberof DetailedComparisonRepresentation
     */
    'processingLogs'?: Array<ProcessingLogRepresentation>;
}


/**
 * ASC -> Ascending (A-Z); DESC -> Descending (Z-A)
 * @export
 * @enum {string}
 */

export const Direction = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type Direction = typeof Direction[keyof typeof Direction];


/**
 * 
 * @export
 * @interface DownloadRepresentation
 */
export interface DownloadRepresentation {
    /**
     * 
     * @type {string}
     * @memberof DownloadRepresentation
     */
    'name'?: string | null;
    /**
     * 
     * @type {DownloadStatus}
     * @memberof DownloadRepresentation
     */
    'status'?: DownloadStatus;
    /**
     * Human-readable message describing the reason for the download status
     * @type {string}
     * @memberof DownloadRepresentation
     */
    'details'?: string | null;
    /**
     * Relative URI to the download endpoint
     * @type {string}
     * @memberof DownloadRepresentation
     */
    'downloadURI'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DownloadStatus = {
    Unavailable: 'Unavailable',
    Incomplete: 'Incomplete',
    Processing: 'Processing',
    Ready: 'Ready'
} as const;

export type DownloadStatus = typeof DownloadStatus[keyof typeof DownloadStatus];


/**
 * 
 * @export
 * @interface EmailValidator
 */
export interface EmailValidator {
    /**
     * 
     * @type {string}
     * @memberof EmailValidator
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ExportDashboardStatusDto
 */
export interface ExportDashboardStatusDto {
    /**
     * 
     * @type {string}
     * @memberof ExportDashboardStatusDto
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExportDashboardStatusDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExportDashboardStatusDto
     */
    'percentComplete'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FailedOnStep = {
    UploadingFile: 'UploadingFile',
    CheckUploadedFile: 'CheckUploadedFile',
    GettingTaxonomies: 'GettingTaxonomies',
    GettingBft: 'GettingBft',
    GettingValidationResults: 'GettingValidationResults',
    ReceivingFiles: 'ReceivingFiles',
    VisualExcel: 'VisualExcel',
    ReferenceIndicators: 'ReferenceIndicators'
} as const;

export type FailedOnStep = typeof FailedOnStep[keyof typeof FailedOnStep];


/**
 * 
 * @export
 * @interface FlagValidationChecksValidator
 */
export interface FlagValidationChecksValidator {
    /**
     * 
     * @type {Array<string>}
     * @memberof FlagValidationChecksValidator
     */
    'checkIds'?: Array<string>;
    /**
     * Motivation is applied to the bulk of specified checks
     * @type {string}
     * @memberof FlagValidationChecksValidator
     */
    'motivation'?: string;
}
/**
 * 
 * @export
 * @interface GetAccessTokenDto
 */
export interface GetAccessTokenDto {
    /**
     * 
     * @type {string}
     * @memberof GetAccessTokenDto
     */
    'grantType': string;
    /**
     * 
     * @type {string}
     * @memberof GetAccessTokenDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAccessTokenDto
     */
    'redirectUri': string;
    /**
     * 
     * @type {string}
     * @memberof GetAccessTokenDto
     */
    'refreshToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAccessTokenDto
     */
    'clientId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HttpStatusCode = {
    Continue: 'Continue',
    SwitchingProtocols: 'SwitchingProtocols',
    Processing: 'Processing',
    EarlyHints: 'EarlyHints',
    Ok: 'OK',
    Created: 'Created',
    Accepted: 'Accepted',
    NonAuthoritativeInformation: 'NonAuthoritativeInformation',
    NoContent: 'NoContent',
    ResetContent: 'ResetContent',
    PartialContent: 'PartialContent',
    MultiStatus: 'MultiStatus',
    AlreadyReported: 'AlreadyReported',
    ImUsed: 'IMUsed',
    MultipleChoices: 'MultipleChoices',
    MovedPermanently: 'MovedPermanently',
    Found: 'Found',
    SeeOther: 'SeeOther',
    NotModified: 'NotModified',
    UseProxy: 'UseProxy',
    Unused: 'Unused',
    RedirectKeepVerb: 'RedirectKeepVerb',
    PermanentRedirect: 'PermanentRedirect',
    BadRequest: 'BadRequest',
    Unauthorized: 'Unauthorized',
    PaymentRequired: 'PaymentRequired',
    Forbidden: 'Forbidden',
    NotFound: 'NotFound',
    MethodNotAllowed: 'MethodNotAllowed',
    NotAcceptable: 'NotAcceptable',
    ProxyAuthenticationRequired: 'ProxyAuthenticationRequired',
    RequestTimeout: 'RequestTimeout',
    Conflict: 'Conflict',
    Gone: 'Gone',
    LengthRequired: 'LengthRequired',
    PreconditionFailed: 'PreconditionFailed',
    RequestEntityTooLarge: 'RequestEntityTooLarge',
    RequestUriTooLong: 'RequestUriTooLong',
    UnsupportedMediaType: 'UnsupportedMediaType',
    RequestedRangeNotSatisfiable: 'RequestedRangeNotSatisfiable',
    ExpectationFailed: 'ExpectationFailed',
    MisdirectedRequest: 'MisdirectedRequest',
    UnprocessableEntity: 'UnprocessableEntity',
    Locked: 'Locked',
    FailedDependency: 'FailedDependency',
    UpgradeRequired: 'UpgradeRequired',
    PreconditionRequired: 'PreconditionRequired',
    TooManyRequests: 'TooManyRequests',
    RequestHeaderFieldsTooLarge: 'RequestHeaderFieldsTooLarge',
    UnavailableForLegalReasons: 'UnavailableForLegalReasons',
    InternalServerError: 'InternalServerError',
    NotImplemented: 'NotImplemented',
    BadGateway: 'BadGateway',
    ServiceUnavailable: 'ServiceUnavailable',
    GatewayTimeout: 'GatewayTimeout',
    HttpVersionNotSupported: 'HttpVersionNotSupported',
    VariantAlsoNegotiates: 'VariantAlsoNegotiates',
    InsufficientStorage: 'InsufficientStorage',
    LoopDetected: 'LoopDetected',
    NotExtended: 'NotExtended',
    NetworkAuthenticationRequired: 'NetworkAuthenticationRequired'
} as const;

export type HttpStatusCode = typeof HttpStatusCode[keyof typeof HttpStatusCode];


/**
 * 
 * @export
 * @interface IIdentity
 */
export interface IIdentity {
    /**
     * 
     * @type {string}
     * @memberof IIdentity
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IIdentity
     */
    'authenticationType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IIdentity
     */
    'isAuthenticated'?: boolean;
}
/**
 * 
 * @export
 * @interface ImportRepresentation
 */
export interface ImportRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ImportRepresentation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportRepresentation
     */
    'inserted'?: string;
    /**
     * 
     * @type {ImportStatus}
     * @memberof ImportRepresentation
     */
    'status'?: ImportStatus;
    /**
     * 
     * @type {string}
     * @memberof ImportRepresentation
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportRepresentation
     */
    'finishedOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportRepresentation
     */
    'createdBy'?: string | null;
}


/**
 * 
 * @export
 * @interface ImportRepresentationListRepresentation
 */
export interface ImportRepresentationListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof ImportRepresentationListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<ImportRepresentation>}
     * @memberof ImportRepresentationListRepresentation
     */
    'items': Array<ImportRepresentation>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ImportStatus = {
    Created: 'Created',
    Importing: 'Importing',
    Calculating: 'Calculating',
    Done: 'Done',
    Failed: 'Failed'
} as const;

export type ImportStatus = typeof ImportStatus[keyof typeof ImportStatus];


/**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'jsonPayload'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'runAt'?: string;
    /**
     * 
     * @type {JobStatus}
     * @memberof Job
     */
    'status'?: JobStatus;
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    'retryCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'serverId'?: string | null;
    /**
     * 
     * @type {JobConcurrencyType}
     * @memberof Job
     */
    'concurrencyType'?: JobConcurrencyType;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'rowVersion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'errorMessagesJson'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Job
     */
    'errorMessages'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'inserted'?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'lastUpdated'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const JobConcurrencyType = {
    Concurrent: 'Concurrent',
    ExclusiveOfType: 'ExclusiveOfType',
    Exclusive: 'Exclusive'
} as const;

export type JobConcurrencyType = typeof JobConcurrencyType[keyof typeof JobConcurrencyType];


/**
 * 
 * @export
 * @interface JobListRepresentation
 */
export interface JobListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof JobListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<Job>}
     * @memberof JobListRepresentation
     */
    'items': Array<Job>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const JobStatus = {
    Created: 'Created',
    Running: 'Running',
    Succeeded: 'Succeeded',
    Failed: 'Failed',
    Timeout: 'Timeout'
} as const;

export type JobStatus = typeof JobStatus[keyof typeof JobStatus];


/**
 * 
 * @export
 * @interface JobStatusDto
 */
export interface JobStatusDto {
    /**
     * 
     * @type {string}
     * @memberof JobStatusDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobStatusDto
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobStatusDto
     */
    'runAt'?: string;
    /**
     * 
     * @type {JobStatus}
     * @memberof JobStatusDto
     */
    'status'?: JobStatus;
    /**
     * 
     * @type {number}
     * @memberof JobStatusDto
     */
    'retryCount'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobStatusDto
     */
    'errorMessages'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface LogActorRepresentation
 */
export interface LogActorRepresentation {
    /**
     * 
     * @type {string}
     * @memberof LogActorRepresentation
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof LogActorRepresentation
     */
    'properties': string;
}
/**
 * 
 * @export
 * @interface MarkValidationChecksBelowThresholdValidator
 */
export interface MarkValidationChecksBelowThresholdValidator {
    /**
     * 
     * @type {Array<string>}
     * @memberof MarkValidationChecksBelowThresholdValidator
     */
    'checkIds'?: Array<string>;
    /**
     * Motivation is applied to the bulk of specified checks
     * @type {string}
     * @memberof MarkValidationChecksBelowThresholdValidator
     */
    'motivation': string;
}
/**
 * 
 * @export
 * @interface MarkValidationChecksPendingValidator
 */
export interface MarkValidationChecksPendingValidator {
    /**
     * 
     * @type {Array<string>}
     * @memberof MarkValidationChecksPendingValidator
     */
    'checkIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface MaterialityDto
 */
export interface MaterialityDto {
    /**
     * 
     * @type {number}
     * @memberof MaterialityDto
     */
    'bank': number;
    /**
     * 
     * @type {number}
     * @memberof MaterialityDto
     */
    'controllingCompany'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MaterialityDto
     */
    'consolidated'?: number | null;
}
/**
 * Dazzler.API.Core.Report.Descriptions.ParticlesFile
 * @export
 * @interface ParticlesFile
 */
export interface ParticlesFile {
    /**
     * A ParticlesFileId in the format \'pf_\' followed by a GUID
     * @type {string}
     * @memberof ParticlesFile
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticlesFile
     */
    'inserted'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticlesFile
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {ProcessorType}
     * @memberof ParticlesFile
     */
    'processorType'?: ProcessorType;
    /**
     * Dazzler.API.Core.Report.Descriptions.XbrlSchemaRef
     * @type {string}
     * @memberof ParticlesFile
     */
    'xbrlSchemaRef': string;
    /**
     * Dazzler.API.Core.Report.Descriptions.BucketObjectId  Dazzler.API.Core.Report.Descriptions.BFT
     * @type {string}
     * @memberof ParticlesFile
     */
    'bftBucketObjectId'?: string;
    /**
     * 
     * @type {BftStatus}
     * @memberof ParticlesFile
     */
    'bftStatus'?: BftStatus;
    /**
     * Dazzler.API.Core.Report.Descriptions.BucketObjectId  Dazzler.API.Core.Report.Descriptions.VisualExcel
     * @type {string}
     * @memberof ParticlesFile
     */
    'visualExcelBucketObjectId'?: string;
    /**
     * Dazzler.API.Core.Report.Descriptions.VisualExcel
     * @type {string}
     * @memberof ParticlesFile
     */
    'visualExcelFilename'?: string;
    /**
     * 
     * @type {VisualExcelStatus}
     * @memberof ParticlesFile
     */
    'visualExcelStatus'?: VisualExcelStatus;
    /**
     * Dazzler.API.Core.Report.Descriptions.Taxonomy
     * @type {Array<ParticlesTaxonomy>}
     * @memberof ParticlesFile
     */
    'taxonomies'?: Array<ParticlesTaxonomy>;
    /**
     * A ReportFileId in the format \'rf_\' followed by a GUID
     * @type {string}
     * @memberof ParticlesFile
     */
    'reportFileId': string;
    /**
     * 
     * @type {ReportFile}
     * @memberof ParticlesFile
     */
    'reportFile'?: ReportFile;
}


/**
 * 
 * @export
 * @interface ParticlesFileRepresentation
 */
export interface ParticlesFileRepresentation extends ReportFileRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ParticlesFileRepresentation
     */
    'type': string;
    /**
     * A ParticlesFileId in the format \'pf_\' followed by a GUID
     * @type {string}
     * @memberof ParticlesFileRepresentation
     */
    'particlesFileId': string;
    /**
     * 
     * @type {BftStatus}
     * @memberof ParticlesFileRepresentation
     */
    'bftStatus': BftStatus;
    /**
     * 
     * @type {VisualExcelStatus}
     * @memberof ParticlesFileRepresentation
     */
    'visualExcelStatus': VisualExcelStatus;
}


/**
 * 
 * @export
 * @interface ParticlesMetadata
 */
export interface ParticlesMetadata {
    /**
     * 
     * @type {string}
     * @memberof ParticlesMetadata
     */
    'serviceVersion'?: string | null;
}
/**
 * 
 * @export
 * @interface ParticlesReport
 */
export interface ParticlesReport {
    /**
     * Dazzler.API.Core.Report.Descriptions.TaxonomyPackageGuid
     * @type {string}
     * @memberof ParticlesReport
     */
    'packageGuid'?: string;
    /**
     * Dazzler.API.Core.Report.Descriptions.Taxonomy
     * @type {string}
     * @memberof ParticlesReport
     */
    'taxonomy'?: string | null;
    /**
     * ID of the report in ATOME Particles API
     * @type {string}
     * @memberof ParticlesReport
     */
    'particlesId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticlesReport
     */
    'taxonomyCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticlesReport
     * @deprecated
     */
    'containerVersion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticlesReport
     * @deprecated
     */
    'validationVersion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticlesReport
     * @deprecated
     */
    'particlesUploadTaskId'?: string | null;
    /**
     * 
     * @type {ParticlesReportStatus}
     * @memberof ParticlesReport
     */
    'status'?: ParticlesReportStatus;
    /**
     * 
     * @type {Array<ValidationProgress>}
     * @memberof ParticlesReport
     */
    'validationProgresses'?: Array<ValidationProgress> | null;
    /**
     * A VersionParticlesFileId in the format \'vpf_\' followed by a GUID
     * @type {string}
     * @memberof ParticlesReport
     */
    'versionParticlesFileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticlesReport
     * @deprecated
     */
    'fileInfoId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticlesReport
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticlesReport
     */
    'inserted'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticlesReport
     */
    'lastUpdated'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ParticlesReportStatus = {
    Created: 'Created',
    Uploading: 'Uploading',
    Uploaded: 'Uploaded',
    ValidationProgressesCreated: 'ValidationProgressesCreated',
    ValidationCombinationsCreated: 'ValidationCombinationsCreated',
    Completed: 'Completed'
} as const;

export type ParticlesReportStatus = typeof ParticlesReportStatus[keyof typeof ParticlesReportStatus];


/**
 * Dazzler.API.Core.Report.Descriptions.Taxonomy
 * @export
 * @interface ParticlesTaxonomy
 */
export interface ParticlesTaxonomy {
    /**
     * 
     * @type {string}
     * @memberof ParticlesTaxonomy
     */
    'name'?: string;
    /**
     * Dazzler.API.Core.Report.Descriptions.TaxonomyPackageGuid
     * @type {string}
     * @memberof ParticlesTaxonomy
     */
    'packageGuid'?: string;
}
/**
 * Dazzler.API.Core.Report.Descriptions.Taxonomy
 * @export
 * @interface ParticlesTaxonomyRepresentation
 */
export interface ParticlesTaxonomyRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ParticlesTaxonomyRepresentation
     */
    'name': string;
    /**
     * Dazzler.API.Core.Report.Descriptions.TaxonomyPackageGuid
     * @type {string}
     * @memberof ParticlesTaxonomyRepresentation
     */
    'packageGuid': string;
}
/**
 * 
 * @export
 * @interface PowerBiEmbeddedDto
 */
export interface PowerBiEmbeddedDto {
    /**
     * 
     * @type {string}
     * @memberof PowerBiEmbeddedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PowerBiEmbeddedDto
     */
    'embedToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PowerBiEmbeddedDto
     */
    'embedUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PowerBiEmbeddedDto
     */
    'lastRefreshDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PowerBiEmbeddedDto
     */
    'lastReportDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PowerBiEmbeddedDto
     */
    'isAllDataLoaded'?: boolean;
}
/**
 * 
 * @export
 * @interface PowerBiExplanationSection
 */
export interface PowerBiExplanationSection {
    /**
     * 
     * @type {string}
     * @memberof PowerBiExplanationSection
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PowerBiExplanationSection
     */
    'htmlContent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PowerBiExplanationSection
     */
    'position'?: string | null;
    /**
     * 
     * @type {ScopeType}
     * @memberof PowerBiExplanationSection
     */
    'scopeType'?: ScopeType;
    /**
     * 
     * @type {string}
     * @memberof PowerBiExplanationSection
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBiExplanationSection
     */
    'inserted'?: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBiExplanationSection
     */
    'lastUpdated'?: string | null;
}


/**
 * 
 * @export
 * @interface ProcessingLogRepresentation
 */
export interface ProcessingLogRepresentation {
    /**
     * Time-based sortable ID
     * @type {string}
     * @memberof ProcessingLogRepresentation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessingLogRepresentation
     */
    'timestamp'?: string;
    /**
     * Message that is safe to show to the user. No sensitive information is stored here
     * @type {string}
     * @memberof ProcessingLogRepresentation
     */
    'message'?: string;
    /**
     * 
     * @type {Severity}
     * @memberof ProcessingLogRepresentation
     */
    'severity'?: Severity;
    /**
     * 
     * @type {string}
     * @memberof ProcessingLogRepresentation
     */
    'relatedEntityId'?: string | null;
}


/**
 * 
 * @export
 * @interface ProcessingLogRepresentationListRepresentation
 */
export interface ProcessingLogRepresentationListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof ProcessingLogRepresentationListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<ProcessingLogRepresentation>}
     * @memberof ProcessingLogRepresentationListRepresentation
     */
    'items': Array<ProcessingLogRepresentation>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProcessingLogSort = {
    Timestamp: 'Timestamp',
    Id: 'Id',
    Severity: 'Severity'
} as const;

export type ProcessingLogSort = typeof ProcessingLogSort[keyof typeof ProcessingLogSort];


/**
 * Dazzler.API.Contracts.Enums.ProcessorType
 * @export
 * @interface ProcessorMetadata
 */
export interface ProcessorMetadata {
    /**
     * 
     * @type {SouthAfricaMetadata}
     * @memberof ProcessorMetadata
     */
    'southAfrica'?: SouthAfricaMetadata;
    /**
     * 
     * @type {ParticlesMetadata}
     * @memberof ProcessorMetadata
     */
    'particles'?: ParticlesMetadata;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProcessorType = {
    Particles: 'Particles',
    SouthAfrica: 'SouthAfrica'
} as const;

export type ProcessorType = typeof ProcessorType[keyof typeof ProcessorType];


/**
 * Dazzler.API.Core.Report.Descriptions.ReportFile
 * @export
 * @interface ReportFile
 */
export interface ReportFile {
    /**
     * A ReportFileId in the format \'rf_\' followed by a GUID
     * @type {string}
     * @memberof ReportFile
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportFile
     */
    'inserted'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportFile
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportFile
     */
    'deleteTime'?: string | null;
    /**
     * 
     * @type {ProcessorType}
     * @memberof ReportFile
     */
    'processorType': ProcessorType;
    /**
     * 
     * @type {string}
     * @memberof ReportFile
     */
    'name': string;
    /**
     * Dazzler.API.Core.Report.Descriptions.BucketObjectId
     * @type {string}
     * @memberof ReportFile
     */
    'bucketObjectId'?: string;
    /**
     * Dazzler.API.Core.Report.Descriptions.ReportingFramework              i.e              COREP (COmmon REPorting Standards)              FINREP (FINancial REPorting Standards)              ALMM (Additional Liquidity Monitoring Metrics).              NSFR (Net Stable Funding Ratio)              LCR (Liquidity Coverage Ratio)
     * @type {string}
     * @memberof ReportFile
     */
    'reportingFramework'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportFile
     */
    'reportScopeId': string;
}


/**
 * 
 * @export
 * @interface ReportFileRepresentation
 */
export interface ReportFileRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ReportFileRepresentation
     */
    'Type': string;
    /**
     * 
     * @type {string}
     * @memberof ReportFileRepresentation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportFileRepresentation
     */
    'reportingFramework': string;
    /**
     * Dazzler.API.Contracts.ReportFileRepresentation.FileTypeParticles  Dazzler.API.Contracts.ReportFileRepresentation.FileTypeSouthAfrica
     * @type {string}
     * @memberof ReportFileRepresentation
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ReportFileRepresentation
     */
    'reportScopeId': string;
    /**
     * A ReportFileId in the format \'rf_\' followed by a GUID
     * @type {string}
     * @memberof ReportFileRepresentation
     */
    'reportFileId': string;
}
/**
 * 
 * @export
 * @interface ReportFileRepresentationListRepresentation
 */
export interface ReportFileRepresentationListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof ReportFileRepresentationListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<UploadReportFile200Response>}
     * @memberof ReportFileRepresentationListRepresentation
     */
    'items': Array<UploadReportFile200Response>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportFileStatus = {
    Processing: 'Processing',
    Deleting: 'Deleting',
    Error: 'Error',
    Ready: 'Ready'
} as const;

export type ReportFileStatus = typeof ReportFileStatus[keyof typeof ReportFileStatus];


/**
 * 
 * @export
 * @interface ReportFileUploadProgressRepresentation
 */
export interface ReportFileUploadProgressRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ReportFileUploadProgressRepresentation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportFileUploadProgressRepresentation
     */
    'inserted': string;
    /**
     * 
     * @type {string}
     * @memberof ReportFileUploadProgressRepresentation
     */
    'reportingFramework': string;
    /**
     * 
     * @type {string}
     * @memberof ReportFileUploadProgressRepresentation
     */
    'reportScopeId': string;
    /**
     * A ReportFileId in the format \'rf_\' followed by a GUID
     * @type {string}
     * @memberof ReportFileUploadProgressRepresentation
     */
    'reportFileId': string;
    /**
     * 
     * @type {ReportFileStatus}
     * @memberof ReportFileUploadProgressRepresentation
     */
    'status': ReportFileStatus;
    /**
     * Additional information regarding the file processing stage
     * @type {string}
     * @memberof ReportFileUploadProgressRepresentation
     */
    'details': string;
    /**
     * A VersionFileId in the format \'vf_\' followed by a GUID
     * @type {string}
     * @memberof ReportFileUploadProgressRepresentation
     */
    'versionFileId'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ReportLifecycleStatus = {
    Draft: 'Draft',
    Locked: 'Locked'
} as const;

export type ReportLifecycleStatus = typeof ReportLifecycleStatus[keyof typeof ReportLifecycleStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ReportProcessingOutcome = {
    NotProcessed: 'NotProcessed',
    Processing: 'Processing',
    Succeeded: 'Succeeded',
    Failed: 'Failed'
} as const;

export type ReportProcessingOutcome = typeof ReportProcessingOutcome[keyof typeof ReportProcessingOutcome];


/**
 * 
 * @export
 * @interface ReportScopeRepresentation
 */
export interface ReportScopeRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ReportScopeRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportScopeRepresentation
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportScopeRepresentation
     */
    'inserted': string;
    /**
     * 
     * @type {string}
     * @memberof ReportScopeRepresentation
     */
    'lockTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportScopeRepresentation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportScopeRepresentation
     */
    'periodEnd': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof ReportScopeRepresentation
     */
    'scopeType': ScopeType;
    /**
     * 
     * @type {ReportLifecycleStatus}
     * @memberof ReportScopeRepresentation
     */
    'lifecycleStatus': ReportLifecycleStatus;
    /**
     * 
     * @type {ReportProcessingOutcome}
     * @memberof ReportScopeRepresentation
     */
    'processingOutcome': ReportProcessingOutcome;
    /**
     * 
     * @type {ProcessorType}
     * @memberof ReportScopeRepresentation
     */
    'processorType': ProcessorType;
    /**
     * 
     * @type {ProcessorMetadata}
     * @memberof ReportScopeRepresentation
     */
    'processorMetadata'?: ProcessorMetadata;
    /**
     * 
     * @type {string}
     * @memberof ReportScopeRepresentation
     */
    'clientId': string;
    /**
     * 
     * @type {Array<VersionRepresentation>}
     * @memberof ReportScopeRepresentation
     */
    'versions': Array<VersionRepresentation>;
}


/**
 * 
 * @export
 * @interface ReportScopeRepresentationListRepresentation
 */
export interface ReportScopeRepresentationListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof ReportScopeRepresentationListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<ReportScopeRepresentation>}
     * @memberof ReportScopeRepresentationListRepresentation
     */
    'items': Array<ReportScopeRepresentation>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportScopeSort = {
    PeriodEnd: 'PeriodEnd',
    Inserted: 'Inserted',
    Name: 'Name',
    LockTime: 'LockTime',
    LastUpdated: 'LastUpdated'
} as const;

export type ReportScopeSort = typeof ReportScopeSort[keyof typeof ReportScopeSort];


/**
 * 
 * @export
 * @interface ReportScopeValidator
 */
export interface ReportScopeValidator {
    /**
     * 
     * @type {string}
     * @memberof ReportScopeValidator
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportScopeValidator
     */
    'periodEnd': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof ReportScopeValidator
     */
    'scopeType': ScopeType;
}


/**
 * 
 * @export
 * @interface ReportStatusInformationRepresentation
 */
export interface ReportStatusInformationRepresentation {
    /**
     * 
     * @type {boolean}
     * @memberof ReportStatusInformationRepresentation
     */
    'isFailed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportStatusInformationRepresentation
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportStatusInformationRepresentation
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportStatusInformationRepresentation
     */
    'failingFileName'?: string | null;
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof ReportStatusInformationRepresentation
     */
    'responseCode'?: HttpStatusCode;
    /**
     * 
     * @type {FailedOnStep}
     * @memberof ReportStatusInformationRepresentation
     */
    'failedOnStep'?: FailedOnStep;
    /**
     * 
     * @type {number}
     * @memberof ReportStatusInformationRepresentation
     */
    'retryAtomeParticlesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportStatusInformationRepresentation
     */
    'retryInternalErrorCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportStatusInformationRepresentation
     */
    'externalErrorMessagesJson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportStatusInformationRepresentation
     */
    'internalErrorMessagesJson'?: string | null;
}


/**
 * 
 * @export
 * @interface ReportingEngineProgressRepresentation
 */
export interface ReportingEngineProgressRepresentation {
    /**
     * Null in case there are no versions that have been processed
     * @type {string}
     * @memberof ReportingEngineProgressRepresentation
     */
    'latestProcessedVersionId'?: string | null;
    /**
     * Null in case there are no versions have been processed
     * @type {number}
     * @memberof ReportingEngineProgressRepresentation
     */
    'latestProcessedVersionOrder'?: number | null;
    /**
     * File count of the latest processed version. By default - 0
     * @type {number}
     * @memberof ReportingEngineProgressRepresentation
     */
    'fileCount'?: number;
    /**
     * When the processing of the latest version has finished – success or failure
     * @type {string}
     * @memberof ReportingEngineProgressRepresentation
     */
    'processingEndTime'?: string | null;
    /**
     * 
     * @type {ReportingEngineStatus}
     * @memberof ReportingEngineProgressRepresentation
     */
    'status': ReportingEngineStatus;
    /**
     * 
     * @type {string}
     * @memberof ReportingEngineProgressRepresentation
     */
    'message'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ReportingEngineStatus = {
    Idle: 'Idle',
    Processing: 'Processing',
    Error: 'Error',
    Success: 'Success'
} as const;

export type ReportingEngineStatus = typeof ReportingEngineStatus[keyof typeof ReportingEngineStatus];


/**
 * 
 * @export
 * @interface ReportingFrameworksRepresentation
 */
export interface ReportingFrameworksRepresentation {
    /**
     * Key is the name of the framework, and value is the allowed extensions of the file. i.e. Key: COREP, Value: [xbrl, xml]; Key: FINREP: [xbrl, xml]
     * @type {{ [key: string]: Array<string> | null; }}
     * @memberof ReportingFrameworksRepresentation
     */
    'frameworks'?: { [key: string]: Array<string> | null; } | null;
}
/**
 * 
 * @export
 * @interface ReviewedValidationCell
 */
export interface ReviewedValidationCell {
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCell
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCell
     */
    'expression'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCell
     */
    'sheet'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReviewedValidationCell
     */
    'row'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReviewedValidationCell
     */
    'column'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCell
     */
    'combinationFileId'?: string | null;
}
/**
 * Dazzler.API.Entities.Particles.ReviewedValidationCheck
 * @export
 * @interface ReviewedValidationCheckRepresentation
 */
export interface ReviewedValidationCheckRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'inserted'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'context'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'scope'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'substitution'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'expression'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'leftSide'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'rightSide'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'difference'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'motivation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'severity'?: string;
    /**
     * 
     * @type {ValidationCheckReviewStatus}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'status'?: ValidationCheckReviewStatus;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'versionId'?: string;
    /**
     * A VersionParticlesFileId in the format \'vpf_\' followed by a GUID
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'versionParticlesFileId'?: string;
    /**
     * 
     * @type {Array<ReviewedValidationCell>}
     * @memberof ReviewedValidationCheckRepresentation
     */
    'cellValues'?: Array<ReviewedValidationCell>;
}


/**
 * 
 * @export
 * @interface ReviewedValidationCheckRepresentationFilteredListRepresentation
 */
export interface ReviewedValidationCheckRepresentationFilteredListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof ReviewedValidationCheckRepresentationFilteredListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<ReviewedValidationCheckRepresentation>}
     * @memberof ReviewedValidationCheckRepresentationFilteredListRepresentation
     */
    'items': Array<ReviewedValidationCheckRepresentation>;
    /**
     * 
     * @type {string}
     * @memberof ReviewedValidationCheckRepresentationFilteredListRepresentation
     */
    'filterHash': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReviewedValidationCheckSort = {
    UniqueHash: 'UniqueHash'
} as const;

export type ReviewedValidationCheckSort = typeof ReviewedValidationCheckSort[keyof typeof ReviewedValidationCheckSort];


/**
 * 
 * @export
 * @enum {string}
 */

export const ScopeType = {
    Bank: 'Bank',
    Consolidated: 'Consolidated',
    Group: 'Group',
    Ba610: 'BA610',
    BankStatutory: 'BankStatutory',
    BankConsolidated: 'BankConsolidated',
    InsuranceStatutory: 'InsuranceStatutory',
    InsuranceConsolidated: 'InsuranceConsolidated',
    InsuranceBranch: 'InsuranceBranch',
    Unknown: 'Unknown'
} as const;

export type ScopeType = typeof ScopeType[keyof typeof ScopeType];


/**
 * 
 * @export
 * @enum {string}
 */

export const Severity = {
    None: 'None',
    Debug: 'Debug',
    Information: 'Information',
    Warning: 'Warning',
    Error: 'Error',
    Critical: 'Critical'
} as const;

export type Severity = typeof Severity[keyof typeof Severity];


/**
 * 
 * @export
 * @interface SouthAfricaFileRepresentation
 */
export interface SouthAfricaFileRepresentation extends ReportFileRepresentation {
    /**
     * 
     * @type {string}
     * @memberof SouthAfricaFileRepresentation
     */
    'type': string;
    /**
     * A SouthAfricaFileId in the format \'saf_\' followed by a GUID
     * @type {string}
     * @memberof SouthAfricaFileRepresentation
     */
    'southAfricaFileId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SouthAfricaFileRepresentation
     */
    'archiveEntryNames': Array<string>;
}
/**
 * 
 * @export
 * @interface SouthAfricaMetadata
 */
export interface SouthAfricaMetadata {
    /**
     * 
     * @type {string}
     * @memberof SouthAfricaMetadata
     * @deprecated
     */
    'objectId'?: string | null;
}
/**
 * 
 * @export
 * @interface TaxonomyDto
 */
export interface TaxonomyDto {
    /**
     * 
     * @type {string}
     * @memberof TaxonomyDto
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyDto
     */
    'key'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TaxonomyDto
     */
    'isLatest'?: boolean;
}
/**
 * 
 * @export
 * @interface TaxonomyDtoListRepresentation
 */
export interface TaxonomyDtoListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof TaxonomyDtoListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<TaxonomyDto>}
     * @memberof TaxonomyDtoListRepresentation
     */
    'items': Array<TaxonomyDto>;
}
/**
 * 
 * @export
 * @interface UpdatePowerBiExplanationSectionValidator
 */
export interface UpdatePowerBiExplanationSectionValidator {
    /**
     * 
     * @type {string}
     * @memberof UpdatePowerBiExplanationSectionValidator
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePowerBiExplanationSectionValidator
     */
    'htmlContent': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePowerBiExplanationSectionValidator
     */
    'position': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof UpdatePowerBiExplanationSectionValidator
     */
    'scopeType': ScopeType;
}


/**
 * @type UploadReportFile200Response
 * @export
 */
export type UploadReportFile200Response = ParticlesFileRepresentation | SouthAfricaFileRepresentation;

/**
 * 
 * @export
 * @interface UserClientsValidator
 */
export interface UserClientsValidator {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserClientsValidator
     */
    'clientIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UserContactDetailsRepresentation
 */
export interface UserContactDetailsRepresentation {
    /**
     * 
     * @type {string}
     * @memberof UserContactDetailsRepresentation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserContactDetailsRepresentation
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserContactDetailsRepresentation
     */
    'familyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserContactDetailsRepresentation
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCountriesValidator
 */
export interface UserCountriesValidator {
    /**
     * 
     * @type {Array<CountryCode>}
     * @memberof UserCountriesValidator
     */
    'countries'?: Array<CountryCode> | null;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'familyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {Array<ClientUserRepresentation>}
     * @memberof UserDto
     */
    'clientUsers'?: Array<ClientUserRepresentation> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isAdmin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isSuperAdmin'?: boolean;
    /**
     * 
     * @type {UserRegistrationStatus}
     * @memberof UserDto
     */
    'creationStatus'?: UserRegistrationStatus;
    /**
     * 
     * @type {ApimRegistrationStatus}
     * @memberof UserDto
     */
    'apimStatus'?: ApimRegistrationStatus;
    /**
     * 
     * @type {Array<CountryCode>}
     * @memberof UserDto
     */
    'countries'?: Array<CountryCode> | null;
}


/**
 * 
 * @export
 * @interface UserDtoListRepresentation
 */
export interface UserDtoListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof UserDtoListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof UserDtoListRepresentation
     */
    'items': Array<UserDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserRegistrationStatus = {
    ApimCreationRequested: 'ApimCreationRequested',
    Created: 'Created',
    Failed: 'Failed'
} as const;

export type UserRegistrationStatus = typeof UserRegistrationStatus[keyof typeof UserRegistrationStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const UserSort = {
    Email: 'Email',
    FirstName: 'FirstName',
    FamilyName: 'FamilyName',
    Inserted: 'Inserted',
    LastUpdated: 'LastUpdated'
} as const;

export type UserSort = typeof UserSort[keyof typeof UserSort];


/**
 * 
 * @export
 * @interface UserValidator
 */
export interface UserValidator {
    /**
     * 
     * @type {string}
     * @memberof UserValidator
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserValidator
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof UserValidator
     */
    'email': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ValidationCheckReviewStatus = {
    Pending: 'Pending',
    Waived: 'Waived',
    Flagged: 'Flagged',
    BelowThreshold: 'BelowThreshold'
} as const;

export type ValidationCheckReviewStatus = typeof ValidationCheckReviewStatus[keyof typeof ValidationCheckReviewStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ValidationChecksAction = {
    MarkPending: 'MarkPending',
    Waive: 'Waive',
    Flag: 'Flag',
    MarkBelowThreshold: 'MarkBelowThreshold'
} as const;

export type ValidationChecksAction = typeof ValidationChecksAction[keyof typeof ValidationChecksAction];


/**
 * 
 * @export
 * @interface ValidationProgress
 */
export interface ValidationProgress {
    /**
     * 
     * @type {number}
     * @memberof ValidationProgress
     */
    'particlesReferenceIndicatorId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProgress
     */
    'particlesReferenceIndicatorKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProgress
     */
    'particlesReportId'?: string;
    /**
     * 
     * @type {ParticlesReport}
     * @memberof ValidationProgress
     */
    'particlesReport'?: ParticlesReport;
    /**
     * 
     * @type {Array<ValidationProgressCombination>}
     * @memberof ValidationProgress
     */
    'validationProgressCombinations'?: Array<ValidationProgressCombination> | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProgress
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProgress
     */
    'inserted'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProgress
     */
    'lastUpdated'?: string | null;
}
/**
 * 
 * @export
 * @interface ValidationProgressCombination
 */
export interface ValidationProgressCombination {
    /**
     * 
     * @type {string}
     * @memberof ValidationProgressCombination
     */
    'validationProgressId'?: string;
    /**
     * 
     * @type {ValidationProgress}
     * @memberof ValidationProgressCombination
     */
    'validationProgress'?: ValidationProgress;
    /**
     * 
     * @type {string}
     * @memberof ValidationProgressCombination
     */
    'linkedProcessProgressId'?: string | null;
    /**
     * 
     * @type {ParticlesReport}
     * @memberof ValidationProgressCombination
     */
    'linkedProcessProgress'?: ParticlesReport;
    /**
     * 
     * @type {ValidationProgressCombinationStatus}
     * @memberof ValidationProgressCombination
     */
    'status'?: ValidationProgressCombinationStatus;
    /**
     * 
     * @type {string}
     * @memberof ValidationProgressCombination
     * @deprecated
     */
    'localValidationsJsonPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProgressCombination
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProgressCombination
     */
    'inserted'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProgressCombination
     */
    'lastUpdated'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ValidationProgressCombinationStatus = {
    Created: 'Created',
    Validating: 'Validating',
    Done: 'Done',
    Inserting: 'Inserting'
} as const;

export type ValidationProgressCombinationStatus = typeof ValidationProgressCombinationStatus[keyof typeof ValidationProgressCombinationStatus];


/**
 * 
 * @export
 * @interface ValidationsMetadata
 */
export interface ValidationsMetadata {
    /**
     * 
     * @type {string}
     * @memberof ValidationsMetadata
     */
    'particlesVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationsMetadata
     */
    'containerVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationsMetadata
     */
    'validationVersion'?: string;
}
/**
 * Dazzler.API.Core.Report.Descriptions.VersionFile
 * @export
 * @interface VersionFile
 */
export interface VersionFile {
    /**
     * A VersionFileId in the format \'vf_\' followed by a GUID
     * @type {string}
     * @memberof VersionFile
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionFile
     */
    'inserted'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionFile
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {ProcessorType}
     * @memberof VersionFile
     */
    'processorType': ProcessorType;
    /**
     * Dazzler.API.Core.Report.Descriptions.BucketObjectId  M:Dazzler.API.Core.Report.ReportStorage.VersionFileObjectID(System.Guid,System.Guid,System.Guid,System.Int32,Dazzler.API.Core.Files.VersionFileId,System.String)              Instead of pointing to the original file location, we make a copy and put it in a dedicated version directory.              This simplified the report download functionality
     * @type {string}
     * @memberof VersionFile
     */
    'bucketObjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionFile
     */
    'versionId': string;
    /**
     * A ReportFileId in the format \'rf_\' followed by a GUID
     * @type {string}
     * @memberof VersionFile
     */
    'reportFileId': string;
    /**
     * 
     * @type {ReportFile}
     * @memberof VersionFile
     */
    'reportFile'?: ReportFile;
}


/**
 * 
 * @export
 * @interface VersionFileMetadataRepresentation
 */
export interface VersionFileMetadataRepresentation {
    /**
     * A VersionFileId in the format \'vf_\' followed by a GUID
     * @type {string}
     * @memberof VersionFileMetadataRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VersionFileMetadataRepresentation
     */
    'inserted': string;
    /**
     * 
     * @type {string}
     * @memberof VersionFileMetadataRepresentation
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VersionFileMetadataRepresentation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof VersionFileMetadataRepresentation
     */
    'reportingFramework': string;
    /**
     * 
     * @type {ProcessorType}
     * @memberof VersionFileMetadataRepresentation
     */
    'processorType': ProcessorType;
    /**
     * A ReportFileId in the format \'rf_\' followed by a GUID
     * @type {string}
     * @memberof VersionFileMetadataRepresentation
     */
    'reportFileId': string;
    /**
     * 
     * @type {string}
     * @memberof VersionFileMetadataRepresentation
     */
    'versionId': string;
    /**
     * 
     * @type {string}
     * @memberof VersionFileMetadataRepresentation
     */
    'particlesVersion': string;
    /**
     * 
     * @type {string}
     * @memberof VersionFileMetadataRepresentation
     */
    'containerVersion': string;
    /**
     * 
     * @type {string}
     * @memberof VersionFileMetadataRepresentation
     */
    'validationVersion': string;
    /**
     * 
     * @type {Array<ParticlesTaxonomyRepresentation>}
     * @memberof VersionFileMetadataRepresentation
     */
    'taxonomies': Array<ParticlesTaxonomyRepresentation>;
}


/**
 * 
 * @export
 * @interface VersionFileRepresentation
 */
export interface VersionFileRepresentation {
    /**
     * A VersionFileId in the format \'vf_\' followed by a GUID
     * @type {string}
     * @memberof VersionFileRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VersionFileRepresentation
     */
    'inserted': string;
    /**
     * 
     * @type {string}
     * @memberof VersionFileRepresentation
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VersionFileRepresentation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof VersionFileRepresentation
     */
    'reportingFramework': string;
    /**
     * 
     * @type {ProcessorType}
     * @memberof VersionFileRepresentation
     */
    'processorType': ProcessorType;
    /**
     * A ReportFileId in the format \'rf_\' followed by a GUID
     * @type {string}
     * @memberof VersionFileRepresentation
     */
    'reportFileId': string;
    /**
     * 
     * @type {string}
     * @memberof VersionFileRepresentation
     */
    'versionId': string;
}


/**
 * Dazzler.API.Core.Report.Descriptions.VersionParticlesFile
 * @export
 * @interface VersionParticlesFile
 */
export interface VersionParticlesFile {
    /**
     * A VersionParticlesFileId in the format \'vpf_\' followed by a GUID
     * @type {string}
     * @memberof VersionParticlesFile
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionParticlesFile
     */
    'inserted'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionParticlesFile
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {ProcessorType}
     * @memberof VersionParticlesFile
     */
    'processorType'?: ProcessorType;
    /**
     * Dazzler.API.Core.Report.Descriptions.XbrlSchemaRef              This is denormalized column for faster lookups
     * @type {string}
     * @memberof VersionParticlesFile
     */
    'xbrlSchemaRef': string;
    /**
     * Dazzler.API.Core.Report.Descriptions.BucketObjectId  Dazzler.API.Core.Report.Descriptions.VisualExcel              Instead of pointing to the original file location, we make a copy and put it in a dedicated version directory.              This simplified the report download functionality
     * @type {string}
     * @memberof VersionParticlesFile
     */
    'visualExcelBucketObjectId'?: string;
    /**
     * Dazzler.API.Core.Report.Descriptions.BucketObjectId  Dazzler.API.Core.Report.Descriptions.BFT              Instead of pointing to the original file location, we make a copy and put it in a dedicated version directory.              This simplified the report download functionality
     * @type {string}
     * @memberof VersionParticlesFile
     */
    'bftBucketObjectId'?: string;
    /**
     * 
     * @type {ValidationsMetadata}
     * @memberof VersionParticlesFile
     */
    'validationsMetadata'?: ValidationsMetadata;
    /**
     * A ParticlesFileId in the format \'pf_\' followed by a GUID
     * @type {string}
     * @memberof VersionParticlesFile
     */
    'particlesFileId': string;
    /**
     * A VersionFileId in the format \'vf_\' followed by a GUID
     * @type {string}
     * @memberof VersionParticlesFile
     */
    'versionFileId': string;
    /**
     * 
     * @type {Array<ParticlesReport>}
     * @memberof VersionParticlesFile
     */
    'particlesReports'?: Array<ParticlesReport>;
    /**
     * 
     * @type {VersionFile}
     * @memberof VersionParticlesFile
     */
    'versionFile'?: VersionFile;
    /**
     * 
     * @type {ParticlesFile}
     * @memberof VersionParticlesFile
     */
    'particlesFile'?: ParticlesFile;
}


/**
 * 
 * @export
 * @interface VersionRepresentation
 */
export interface VersionRepresentation {
    /**
     * 
     * @type {string}
     * @memberof VersionRepresentation
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof VersionRepresentation
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof VersionRepresentation
     */
    'reportScopeId': string;
    /**
     * 
     * @type {VersionStatus}
     * @memberof VersionRepresentation
     */
    'status': VersionStatus;
    /**
     * 
     * @type {string}
     * @memberof VersionRepresentation
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionRepresentation
     */
    'inserted': string;
    /**
     * 
     * @type {ReportStatusInformationRepresentation}
     * @memberof VersionRepresentation
     */
    'statusInfo'?: ReportStatusInformationRepresentation;
    /**
     * 
     * @type {number}
     * @memberof VersionRepresentation
     */
    'validationChecksCount': number;
    /**
     * 
     * @type {number}
     * @memberof VersionRepresentation
     */
    'successfulChecksCount': number;
    /**
     * 
     * @type {number}
     * @memberof VersionRepresentation
     */
    'failedChecksCount': number;
    /**
     * 
     * @type {number}
     * @memberof VersionRepresentation
     */
    'waivedChecksCount': number;
    /**
     * 
     * @type {number}
     * @memberof VersionRepresentation
     */
    'flaggedChecksCount': number;
    /**
     * 
     * @type {number}
     * @memberof VersionRepresentation
     */
    'belowThresholdChecksCount': number;
    /**
     * 
     * @type {number}
     * @memberof VersionRepresentation
     */
    'pendingChecksCount': number;
    /**
     * Reporting subject, also known as reporting subcategory - subdomains of the selected reporting scope type
     * @type {Array<string>}
     * @memberof VersionRepresentation
     */
    'subjects': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof VersionRepresentation
     */
    'actualFileCount'?: number | null;
    /**
     * 
     * @type {Array<VersionFileRepresentation>}
     * @memberof VersionRepresentation
     */
    'files'?: Array<VersionFileRepresentation>;
}


/**
 * 
 * @export
 * @interface VersionRepresentationListRepresentation
 */
export interface VersionRepresentationListRepresentation {
    /**
     * 
     * @type {number}
     * @memberof VersionRepresentationListRepresentation
     */
    'count': number;
    /**
     * 
     * @type {Array<VersionRepresentation>}
     * @memberof VersionRepresentationListRepresentation
     */
    'items': Array<VersionRepresentation>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VersionSort = {
    Order: 'Order',
    Inserted: 'Inserted',
    LastUpdated: 'LastUpdated',
    SealTime: 'SealTime'
} as const;

export type VersionSort = typeof VersionSort[keyof typeof VersionSort];


/**
 * 
 * @export
 * @enum {string}
 */

export const VersionStatus = {
    Started: 'Started',
    ReceivingFiles: 'ReceivingFiles',
    Uploaded: 'Uploaded',
    ValidationCombinationsCreated: 'ValidationCombinationsCreated',
    Done: 'Done',
    ScopeFailed: 'ScopeFailed',
    VatFailed: 'VatFailed',
    PeriodFailed: 'PeriodFailed',
    ExternalError: 'ExternalError',
    InvalidInput: 'InvalidInput',
    UnknownError: 'UnknownError',
    NotAllFilesReceived: 'NotAllFilesReceived',
    Validated: 'Validated',
    Deleting: 'Deleting',
    PendingApproval: 'PendingApproval',
    Rejected: 'Rejected',
    Deleted: 'Deleted',
    TimedOut: 'TimedOut',
    DoneWithErrors: 'DoneWithErrors',
    Draft: 'Draft',
    Sealed: 'Sealed',
    InternalError: 'InternalError'
} as const;

export type VersionStatus = typeof VersionStatus[keyof typeof VersionStatus];


/**
 * 
 * @export
 * @interface VersionVisualExcelRepresentation
 */
export interface VersionVisualExcelRepresentation {
    /**
     * 
     * @type {string}
     * @memberof VersionVisualExcelRepresentation
     */
    'filename': string;
    /**
     * Relative URI to download the file
     * @type {string}
     * @memberof VersionVisualExcelRepresentation
     */
    'downloadURI': string;
    /**
     * 
     * @type {VisualExcelStatus}
     * @memberof VersionVisualExcelRepresentation
     */
    'visualExcelStatus': VisualExcelStatus;
    /**
     * 
     * @type {ProcessorType}
     * @memberof VersionVisualExcelRepresentation
     */
    'processorType': ProcessorType;
    /**
     * A ReportFileId in the format \'rf_\' followed by a GUID
     * @type {string}
     * @memberof VersionVisualExcelRepresentation
     */
    'reportFileId': string;
    /**
     * A ParticlesFileId in the format \'pf_\' followed by a GUID
     * @type {string}
     * @memberof VersionVisualExcelRepresentation
     */
    'particlesFileId': string;
    /**
     * A VersionFileId in the format \'vf_\' followed by a GUID
     * @type {string}
     * @memberof VersionVisualExcelRepresentation
     */
    'versionFileId': string;
    /**
     * A VersionParticlesFileId in the format \'vpf_\' followed by a GUID
     * @type {string}
     * @memberof VersionVisualExcelRepresentation
     */
    'versionParticlesFileId': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VisualExcelStatus = {
    Pending: 'Pending',
    Failed: 'Failed',
    Succeeded: 'Succeeded'
} as const;

export type VisualExcelStatus = typeof VisualExcelStatus[keyof typeof VisualExcelStatus];


/**
 * 
 * @export
 * @interface WaiveValidationChecksValidator
 */
export interface WaiveValidationChecksValidator {
    /**
     * 
     * @type {Array<string>}
     * @memberof WaiveValidationChecksValidator
     */
    'checkIds'?: Array<string> | null;
    /**
     * Motivation is applied to the bulk of specified checks
     * @type {string}
     * @memberof WaiveValidationChecksValidator
     */
    'motivation': string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetAccessTokenDto} [getAccessTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken: async (getAccessTokenDto?: GetAccessTokenDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getAccessTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientId] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (clientId?: string, refreshToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['ClientId'] = clientId;
            }

            if (refreshToken !== undefined) {
                localVarQueryParameter['RefreshToken'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetAccessTokenDto} [getAccessTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccessToken(getAccessTokenDto?: GetAccessTokenDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccessToken(getAccessTokenDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.createAccessToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [clientId] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(clientId?: string, refreshToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(clientId, refreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.logout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {AuthApiCreateAccessTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken(requestParameters: AuthApiCreateAccessTokenRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AccessTokenRepresentation> {
            return localVarFp.createAccessToken(requestParameters.getAccessTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiLogoutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(requestParameters: AuthApiLogoutRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.logout(requestParameters.clientId, requestParameters.refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAccessToken operation in AuthApi.
 * @export
 * @interface AuthApiCreateAccessTokenRequest
 */
export interface AuthApiCreateAccessTokenRequest {
    /**
     * 
     * @type {GetAccessTokenDto}
     * @memberof AuthApiCreateAccessToken
     */
    readonly getAccessTokenDto?: GetAccessTokenDto
}

/**
 * Request parameters for logout operation in AuthApi.
 * @export
 * @interface AuthApiLogoutRequest
 */
export interface AuthApiLogoutRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiLogout
     */
    readonly clientId?: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiLogout
     */
    readonly refreshToken?: string
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {AuthApiCreateAccessTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAccessToken(requestParameters: AuthApiCreateAccessTokenRequest = {}, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).createAccessToken(requestParameters.getAccessTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiLogoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(requestParameters: AuthApiLogoutRequest = {}, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).logout(requestParameters.clientId, requestParameters.refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {ScopeType} scope 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDashboard: async (clientId: string, scope: ScopeType, reference: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadDashboard', 'clientId', clientId)
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('downloadDashboard', 'scope', scope)
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('downloadDashboard', 'reference', reference)
            const localVarPath = `/api/clients/{clientId}/dashboards/{scope}/downloads/{reference}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"scope"}}`, encodeURIComponent(String(scope)))
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (clientId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('get', 'clientId', clientId)
            const localVarPath = `/api/clients/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ScopeType} scope 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardDownloadStatus: async (clientId: string, scope: ScopeType, reference: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getDashboardDownloadStatus', 'clientId', clientId)
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('getDashboardDownloadStatus', 'scope', scope)
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('getDashboardDownloadStatus', 'reference', reference)
            const localVarPath = `/api/clients/{clientId}/dashboards/{scope}/downloads/{reference}/status`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"scope"}}`, encodeURIComponent(String(scope)))
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ScopeType} scope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbeddedReport: async (clientId: string, scope: ScopeType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getEmbeddedReport', 'clientId', clientId)
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('getEmbeddedReport', 'scope', scope)
            const localVarPath = `/api/clients/{clientId}/dashboards/{scope}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"scope"}}`, encodeURIComponent(String(scope)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all the available scope types for a particular client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientScopeTypes: async (clientId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listClientScopeTypes', 'clientId', clientId)
            const localVarPath = `/api/clients/{clientId}/scopes`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is useful for the Power BI dashboard view
         * @summary List the client\'s scope types that were successfully processed by the reporting engine.
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSuccessfullyProcessedScopeTypes: async (clientId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listSuccessfullyProcessedScopeTypes', 'clientId', clientId)
            const localVarPath = `/api/clients/{clientId}/successfully-processed-scope-types`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ScopeType} scope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startDashboardDownload: async (clientId: string, scope: ScopeType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('startDashboardDownload', 'clientId', clientId)
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('startDashboardDownload', 'scope', scope)
            const localVarPath = `/api/clients/{clientId}/dashboards/{scope}/downloads`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"scope"}}`, encodeURIComponent(String(scope)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ClientValidator} [clientValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (clientId: string, clientValidator?: ClientValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('update', 'clientId', clientId)
            const localVarPath = `/api/clients/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {ScopeType} scope 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDashboard(clientId: string, scope: ScopeType, reference: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDashboard(clientId, scope, reference, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.downloadDashboard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(clientId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(clientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.get']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ScopeType} scope 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardDownloadStatus(clientId: string, scope: ScopeType, reference: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportDashboardStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardDownloadStatus(clientId, scope, reference, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getDashboardDownloadStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ScopeType} scope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmbeddedReport(clientId: string, scope: ScopeType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PowerBiEmbeddedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmbeddedReport(clientId, scope, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getEmbeddedReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all the available scope types for a particular client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClientScopeTypes(clientId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScopeType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClientScopeTypes(clientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.listClientScopeTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This is useful for the Power BI dashboard view
         * @summary List the client\'s scope types that were successfully processed by the reporting engine.
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSuccessfullyProcessedScopeTypes(clientId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScopeType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSuccessfullyProcessedScopeTypes(clientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.listSuccessfullyProcessedScopeTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ScopeType} scope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startDashboardDownload(clientId: string, scope: ScopeType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportDashboardStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startDashboardDownload(clientId, scope, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.startDashboardDownload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ClientValidator} [clientValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(clientId: string, clientValidator?: ClientValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(clientId, clientValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.update']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientApiFp(configuration)
    return {
        /**
         * 
         * @param {ClientApiDownloadDashboardRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDashboard(requestParameters: ClientApiDownloadDashboardRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadDashboard(requestParameters.clientId, requestParameters.scope, requestParameters.reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: ClientApiGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ClientDto> {
            return localVarFp.get(requestParameters.clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientApiGetDashboardDownloadStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardDownloadStatus(requestParameters: ClientApiGetDashboardDownloadStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExportDashboardStatusDto> {
            return localVarFp.getDashboardDownloadStatus(requestParameters.clientId, requestParameters.scope, requestParameters.reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientApiGetEmbeddedReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbeddedReport(requestParameters: ClientApiGetEmbeddedReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<PowerBiEmbeddedDto> {
            return localVarFp.getEmbeddedReport(requestParameters.clientId, requestParameters.scope, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all the available scope types for a particular client
         * @param {ClientApiListClientScopeTypesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientScopeTypes(requestParameters: ClientApiListClientScopeTypesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScopeType>> {
            return localVarFp.listClientScopeTypes(requestParameters.clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * This is useful for the Power BI dashboard view
         * @summary List the client\'s scope types that were successfully processed by the reporting engine.
         * @param {ClientApiListSuccessfullyProcessedScopeTypesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSuccessfullyProcessedScopeTypes(requestParameters: ClientApiListSuccessfullyProcessedScopeTypesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScopeType>> {
            return localVarFp.listSuccessfullyProcessedScopeTypes(requestParameters.clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientApiStartDashboardDownloadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startDashboardDownload(requestParameters: ClientApiStartDashboardDownloadRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExportDashboardStatusDto> {
            return localVarFp.startDashboardDownload(requestParameters.clientId, requestParameters.scope, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientApiUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(requestParameters: ClientApiUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ClientDto> {
            return localVarFp.update(requestParameters.clientId, requestParameters.clientValidator, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for downloadDashboard operation in ClientApi.
 * @export
 * @interface ClientApiDownloadDashboardRequest
 */
export interface ClientApiDownloadDashboardRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiDownloadDashboard
     */
    readonly clientId: string

    /**
     * 
     * @type {ScopeType}
     * @memberof ClientApiDownloadDashboard
     */
    readonly scope: ScopeType

    /**
     * 
     * @type {string}
     * @memberof ClientApiDownloadDashboard
     */
    readonly reference: string
}

/**
 * Request parameters for get operation in ClientApi.
 * @export
 * @interface ClientApiGetRequest
 */
export interface ClientApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGet
     */
    readonly clientId: string
}

/**
 * Request parameters for getDashboardDownloadStatus operation in ClientApi.
 * @export
 * @interface ClientApiGetDashboardDownloadStatusRequest
 */
export interface ClientApiGetDashboardDownloadStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetDashboardDownloadStatus
     */
    readonly clientId: string

    /**
     * 
     * @type {ScopeType}
     * @memberof ClientApiGetDashboardDownloadStatus
     */
    readonly scope: ScopeType

    /**
     * 
     * @type {string}
     * @memberof ClientApiGetDashboardDownloadStatus
     */
    readonly reference: string
}

/**
 * Request parameters for getEmbeddedReport operation in ClientApi.
 * @export
 * @interface ClientApiGetEmbeddedReportRequest
 */
export interface ClientApiGetEmbeddedReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetEmbeddedReport
     */
    readonly clientId: string

    /**
     * 
     * @type {ScopeType}
     * @memberof ClientApiGetEmbeddedReport
     */
    readonly scope: ScopeType
}

/**
 * Request parameters for listClientScopeTypes operation in ClientApi.
 * @export
 * @interface ClientApiListClientScopeTypesRequest
 */
export interface ClientApiListClientScopeTypesRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiListClientScopeTypes
     */
    readonly clientId: string
}

/**
 * Request parameters for listSuccessfullyProcessedScopeTypes operation in ClientApi.
 * @export
 * @interface ClientApiListSuccessfullyProcessedScopeTypesRequest
 */
export interface ClientApiListSuccessfullyProcessedScopeTypesRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiListSuccessfullyProcessedScopeTypes
     */
    readonly clientId: string
}

/**
 * Request parameters for startDashboardDownload operation in ClientApi.
 * @export
 * @interface ClientApiStartDashboardDownloadRequest
 */
export interface ClientApiStartDashboardDownloadRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiStartDashboardDownload
     */
    readonly clientId: string

    /**
     * 
     * @type {ScopeType}
     * @memberof ClientApiStartDashboardDownload
     */
    readonly scope: ScopeType
}

/**
 * Request parameters for update operation in ClientApi.
 * @export
 * @interface ClientApiUpdateRequest
 */
export interface ClientApiUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiUpdate
     */
    readonly clientId: string

    /**
     * 
     * @type {ClientValidator}
     * @memberof ClientApiUpdate
     */
    readonly clientValidator?: ClientValidator
}

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * 
     * @param {ClientApiDownloadDashboardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public downloadDashboard(requestParameters: ClientApiDownloadDashboardRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).downloadDashboard(requestParameters.clientId, requestParameters.scope, requestParameters.reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public get(requestParameters: ClientApiGetRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).get(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetDashboardDownloadStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getDashboardDownloadStatus(requestParameters: ClientApiGetDashboardDownloadStatusRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getDashboardDownloadStatus(requestParameters.clientId, requestParameters.scope, requestParameters.reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetEmbeddedReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getEmbeddedReport(requestParameters: ClientApiGetEmbeddedReportRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getEmbeddedReport(requestParameters.clientId, requestParameters.scope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all the available scope types for a particular client
     * @param {ClientApiListClientScopeTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public listClientScopeTypes(requestParameters: ClientApiListClientScopeTypesRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).listClientScopeTypes(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is useful for the Power BI dashboard view
     * @summary List the client\'s scope types that were successfully processed by the reporting engine.
     * @param {ClientApiListSuccessfullyProcessedScopeTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public listSuccessfullyProcessedScopeTypes(requestParameters: ClientApiListSuccessfullyProcessedScopeTypesRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).listSuccessfullyProcessedScopeTypes(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiStartDashboardDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public startDashboardDownload(requestParameters: ClientApiStartDashboardDownloadRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).startDashboardDownload(requestParameters.clientId, requestParameters.scope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public update(requestParameters: ClientApiUpdateRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).update(requestParameters.clientId, requestParameters.clientValidator, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClientComparisonApi - axios parameter creator
 * @export
 */
export const ClientComparisonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {ComparisonValidator} [comparisonValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComparison: async (clientId: string, comparisonValidator?: ComparisonValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('createComparison', 'clientId', clientId)
            const localVarPath = `/api/clients/{clientId}/comparisons`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comparisonValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisionFileBFT: async (clientId: string, fileId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadComparisionFileBFT', 'clientId', clientId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('downloadComparisionFileBFT', 'fileId', fileId)
            const localVarPath = `/api/clients/{clientId}/comparisons/files/{fileId}/bft.parquet`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisionFileVisualExcel: async (clientId: string, fileId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadComparisionFileVisualExcel', 'clientId', clientId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('downloadComparisionFileVisualExcel', 'fileId', fileId)
            const localVarPath = `/api/clients/{clientId}/comparisons/files/{fileId}/visual-excel.xlsx`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisonFileProcessingLogs: async (clientId: string, fileId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadComparisonFileProcessingLogs', 'clientId', clientId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('downloadComparisonFileProcessingLogs', 'fileId', fileId)
            const localVarPath = `/api/clients/{clientId}/comparisons/files/{fileId}/processing-logs.json`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} comparisonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisonProcessingLogs: async (clientId: string, comparisonId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadComparisonProcessingLogs', 'clientId', clientId)
            // verify required parameter 'comparisonId' is not null or undefined
            assertParamExists('downloadComparisonProcessingLogs', 'comparisonId', comparisonId)
            const localVarPath = `/api/clients/{clientId}/comparisons/{comparisonId}/processing-logs.json`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"comparisonId"}}`, encodeURIComponent(String(comparisonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} comparisonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisonRowsParquet: async (clientId: string, comparisonId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadComparisonRowsParquet', 'clientId', clientId)
            // verify required parameter 'comparisonId' is not null or undefined
            assertParamExists('downloadComparisonRowsParquet', 'comparisonId', comparisonId)
            const localVarPath = `/api/clients/{clientId}/comparisons/{comparisonId}/rows.parquet`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"comparisonId"}}`, encodeURIComponent(String(comparisonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} comparisonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisonVisualExcel: async (clientId: string, comparisonId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadComparisonVisualExcel', 'clientId', clientId)
            // verify required parameter 'comparisonId' is not null or undefined
            assertParamExists('downloadComparisonVisualExcel', 'comparisonId', comparisonId)
            const localVarPath = `/api/clients/{clientId}/comparisons/{comparisonId}/visual-excel.xlsx`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"comparisonId"}}`, encodeURIComponent(String(comparisonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} comparisonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComparison: async (clientId: string, comparisonId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getComparison', 'clientId', clientId)
            // verify required parameter 'comparisonId' is not null or undefined
            assertParamExists('getComparison', 'comparisonId', comparisonId)
            const localVarPath = `/api/clients/{clientId}/comparisons/{comparisonId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"comparisonId"}}`, encodeURIComponent(String(comparisonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComparisonFile: async (clientId: string, fileId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getComparisonFile', 'clientId', clientId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getComparisonFile', 'fileId', fileId)
            const localVarPath = `/api/clients/{clientId}/comparisons/files/{fileId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} fileId 
         * @param {ProcessingLogSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComparisonFileProcessingLogs: async (clientId: string, fileId: string, sort?: ProcessingLogSort, direction?: Direction, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listComparisonFileProcessingLogs', 'clientId', clientId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('listComparisonFileProcessingLogs', 'fileId', fileId)
            const localVarPath = `/api/clients/{clientId}/comparisons/files/{fileId}/processing-logs`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['Sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComparisonFiles: async (clientId: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listComparisonFiles', 'clientId', clientId)
            const localVarPath = `/api/clients/{clientId}/comparisons/files`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} comparisonId 
         * @param {ProcessingLogSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComparisonProcessingLogs: async (clientId: string, comparisonId: string, sort?: ProcessingLogSort, direction?: Direction, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listComparisonProcessingLogs', 'clientId', clientId)
            // verify required parameter 'comparisonId' is not null or undefined
            assertParamExists('listComparisonProcessingLogs', 'comparisonId', comparisonId)
            const localVarPath = `/api/clients/{clientId}/comparisons/{comparisonId}/processing-logs`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"comparisonId"}}`, encodeURIComponent(String(comparisonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['Sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ClientComparisonSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComparisons: async (clientId: string, sort?: ClientComparisonSort, direction?: Direction, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listComparisons', 'clientId', clientId)
            const localVarPath = `/api/clients/{clientId}/comparisons`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['Sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadComparisonFile: async (clientId: string, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('uploadComparisonFile', 'clientId', clientId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadComparisonFile', 'file', file)
            const localVarPath = `/api/clients/{clientId}/comparisons/files`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientComparisonApi - functional programming interface
 * @export
 */
export const ClientComparisonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientComparisonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {ComparisonValidator} [comparisonValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComparison(clientId: string, comparisonValidator?: ComparisonValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComparison(clientId, comparisonValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.createComparison']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadComparisionFileBFT(clientId: string, fileId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadComparisionFileBFT(clientId, fileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.downloadComparisionFileBFT']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadComparisionFileVisualExcel(clientId: string, fileId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadComparisionFileVisualExcel(clientId, fileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.downloadComparisionFileVisualExcel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadComparisonFileProcessingLogs(clientId: string, fileId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadComparisonFileProcessingLogs(clientId, fileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.downloadComparisonFileProcessingLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} comparisonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadComparisonProcessingLogs(clientId: string, comparisonId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadComparisonProcessingLogs(clientId, comparisonId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.downloadComparisonProcessingLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} comparisonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadComparisonRowsParquet(clientId: string, comparisonId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadComparisonRowsParquet(clientId, comparisonId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.downloadComparisonRowsParquet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} comparisonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadComparisonVisualExcel(clientId: string, comparisonId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadComparisonVisualExcel(clientId, comparisonId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.downloadComparisonVisualExcel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} comparisonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComparison(clientId: string, comparisonId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedComparisonRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComparison(clientId, comparisonId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.getComparison']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComparisonFile(clientId: string, fileId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonFileRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComparisonFile(clientId, fileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.getComparisonFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} fileId 
         * @param {ProcessingLogSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listComparisonFileProcessingLogs(clientId: string, fileId: string, sort?: ProcessingLogSort, direction?: Direction, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessingLogRepresentationListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listComparisonFileProcessingLogs(clientId, fileId, sort, direction, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.listComparisonFileProcessingLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listComparisonFiles(clientId: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonFileRepresentationListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listComparisonFiles(clientId, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.listComparisonFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} comparisonId 
         * @param {ProcessingLogSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listComparisonProcessingLogs(clientId: string, comparisonId: string, sort?: ProcessingLogSort, direction?: Direction, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessingLogRepresentationListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listComparisonProcessingLogs(clientId, comparisonId, sort, direction, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.listComparisonProcessingLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ClientComparisonSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listComparisons(clientId: string, sort?: ClientComparisonSort, direction?: Direction, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonRepresentationListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listComparisons(clientId, sort, direction, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.listComparisons']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadComparisonFile(clientId: string, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonFileRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadComparisonFile(clientId, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientComparisonApi.uploadComparisonFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ClientComparisonApi - factory interface
 * @export
 */
export const ClientComparisonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientComparisonApiFp(configuration)
    return {
        /**
         * 
         * @param {ClientComparisonApiCreateComparisonRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComparison(requestParameters: ClientComparisonApiCreateComparisonRequest, options?: RawAxiosRequestConfig): AxiosPromise<ComparisonRepresentation> {
            return localVarFp.createComparison(requestParameters.clientId, requestParameters.comparisonValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientComparisonApiDownloadComparisionFileBFTRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisionFileBFT(requestParameters: ClientComparisonApiDownloadComparisionFileBFTRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadComparisionFileBFT(requestParameters.clientId, requestParameters.fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientComparisonApiDownloadComparisionFileVisualExcelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisionFileVisualExcel(requestParameters: ClientComparisonApiDownloadComparisionFileVisualExcelRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadComparisionFileVisualExcel(requestParameters.clientId, requestParameters.fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientComparisonApiDownloadComparisonFileProcessingLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisonFileProcessingLogs(requestParameters: ClientComparisonApiDownloadComparisonFileProcessingLogsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadComparisonFileProcessingLogs(requestParameters.clientId, requestParameters.fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientComparisonApiDownloadComparisonProcessingLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisonProcessingLogs(requestParameters: ClientComparisonApiDownloadComparisonProcessingLogsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadComparisonProcessingLogs(requestParameters.clientId, requestParameters.comparisonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientComparisonApiDownloadComparisonRowsParquetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisonRowsParquet(requestParameters: ClientComparisonApiDownloadComparisonRowsParquetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadComparisonRowsParquet(requestParameters.clientId, requestParameters.comparisonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientComparisonApiDownloadComparisonVisualExcelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisonVisualExcel(requestParameters: ClientComparisonApiDownloadComparisonVisualExcelRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadComparisonVisualExcel(requestParameters.clientId, requestParameters.comparisonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientComparisonApiGetComparisonRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComparison(requestParameters: ClientComparisonApiGetComparisonRequest, options?: RawAxiosRequestConfig): AxiosPromise<DetailedComparisonRepresentation> {
            return localVarFp.getComparison(requestParameters.clientId, requestParameters.comparisonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientComparisonApiGetComparisonFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComparisonFile(requestParameters: ClientComparisonApiGetComparisonFileRequest, options?: RawAxiosRequestConfig): AxiosPromise<ComparisonFileRepresentation> {
            return localVarFp.getComparisonFile(requestParameters.clientId, requestParameters.fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientComparisonApiListComparisonFileProcessingLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComparisonFileProcessingLogs(requestParameters: ClientComparisonApiListComparisonFileProcessingLogsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProcessingLogRepresentationListRepresentation> {
            return localVarFp.listComparisonFileProcessingLogs(requestParameters.clientId, requestParameters.fileId, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientComparisonApiListComparisonFilesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComparisonFiles(requestParameters: ClientComparisonApiListComparisonFilesRequest, options?: RawAxiosRequestConfig): AxiosPromise<ComparisonFileRepresentationListRepresentation> {
            return localVarFp.listComparisonFiles(requestParameters.clientId, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientComparisonApiListComparisonProcessingLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComparisonProcessingLogs(requestParameters: ClientComparisonApiListComparisonProcessingLogsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProcessingLogRepresentationListRepresentation> {
            return localVarFp.listComparisonProcessingLogs(requestParameters.clientId, requestParameters.comparisonId, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientComparisonApiListComparisonsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComparisons(requestParameters: ClientComparisonApiListComparisonsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ComparisonRepresentationListRepresentation> {
            return localVarFp.listComparisons(requestParameters.clientId, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientComparisonApiUploadComparisonFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadComparisonFile(requestParameters: ClientComparisonApiUploadComparisonFileRequest, options?: RawAxiosRequestConfig): AxiosPromise<ComparisonFileRepresentation> {
            return localVarFp.uploadComparisonFile(requestParameters.clientId, requestParameters.file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createComparison operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiCreateComparisonRequest
 */
export interface ClientComparisonApiCreateComparisonRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiCreateComparison
     */
    readonly clientId: string

    /**
     * 
     * @type {ComparisonValidator}
     * @memberof ClientComparisonApiCreateComparison
     */
    readonly comparisonValidator?: ComparisonValidator
}

/**
 * Request parameters for downloadComparisionFileBFT operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiDownloadComparisionFileBFTRequest
 */
export interface ClientComparisonApiDownloadComparisionFileBFTRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiDownloadComparisionFileBFT
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiDownloadComparisionFileBFT
     */
    readonly fileId: string
}

/**
 * Request parameters for downloadComparisionFileVisualExcel operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiDownloadComparisionFileVisualExcelRequest
 */
export interface ClientComparisonApiDownloadComparisionFileVisualExcelRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiDownloadComparisionFileVisualExcel
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiDownloadComparisionFileVisualExcel
     */
    readonly fileId: string
}

/**
 * Request parameters for downloadComparisonFileProcessingLogs operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiDownloadComparisonFileProcessingLogsRequest
 */
export interface ClientComparisonApiDownloadComparisonFileProcessingLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiDownloadComparisonFileProcessingLogs
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiDownloadComparisonFileProcessingLogs
     */
    readonly fileId: string
}

/**
 * Request parameters for downloadComparisonProcessingLogs operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiDownloadComparisonProcessingLogsRequest
 */
export interface ClientComparisonApiDownloadComparisonProcessingLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiDownloadComparisonProcessingLogs
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiDownloadComparisonProcessingLogs
     */
    readonly comparisonId: string
}

/**
 * Request parameters for downloadComparisonRowsParquet operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiDownloadComparisonRowsParquetRequest
 */
export interface ClientComparisonApiDownloadComparisonRowsParquetRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiDownloadComparisonRowsParquet
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiDownloadComparisonRowsParquet
     */
    readonly comparisonId: string
}

/**
 * Request parameters for downloadComparisonVisualExcel operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiDownloadComparisonVisualExcelRequest
 */
export interface ClientComparisonApiDownloadComparisonVisualExcelRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiDownloadComparisonVisualExcel
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiDownloadComparisonVisualExcel
     */
    readonly comparisonId: string
}

/**
 * Request parameters for getComparison operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiGetComparisonRequest
 */
export interface ClientComparisonApiGetComparisonRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiGetComparison
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiGetComparison
     */
    readonly comparisonId: string
}

/**
 * Request parameters for getComparisonFile operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiGetComparisonFileRequest
 */
export interface ClientComparisonApiGetComparisonFileRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiGetComparisonFile
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiGetComparisonFile
     */
    readonly fileId: string
}

/**
 * Request parameters for listComparisonFileProcessingLogs operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiListComparisonFileProcessingLogsRequest
 */
export interface ClientComparisonApiListComparisonFileProcessingLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiListComparisonFileProcessingLogs
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiListComparisonFileProcessingLogs
     */
    readonly fileId: string

    /**
     * 
     * @type {ProcessingLogSort}
     * @memberof ClientComparisonApiListComparisonFileProcessingLogs
     */
    readonly sort?: ProcessingLogSort

    /**
     * ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
     * @type {Direction}
     * @memberof ClientComparisonApiListComparisonFileProcessingLogs
     */
    readonly direction?: Direction

    /**
     * 
     * @type {number}
     * @memberof ClientComparisonApiListComparisonFileProcessingLogs
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ClientComparisonApiListComparisonFileProcessingLogs
     */
    readonly offset?: number
}

/**
 * Request parameters for listComparisonFiles operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiListComparisonFilesRequest
 */
export interface ClientComparisonApiListComparisonFilesRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiListComparisonFiles
     */
    readonly clientId: string

    /**
     * 
     * @type {number}
     * @memberof ClientComparisonApiListComparisonFiles
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ClientComparisonApiListComparisonFiles
     */
    readonly offset?: number
}

/**
 * Request parameters for listComparisonProcessingLogs operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiListComparisonProcessingLogsRequest
 */
export interface ClientComparisonApiListComparisonProcessingLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiListComparisonProcessingLogs
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiListComparisonProcessingLogs
     */
    readonly comparisonId: string

    /**
     * 
     * @type {ProcessingLogSort}
     * @memberof ClientComparisonApiListComparisonProcessingLogs
     */
    readonly sort?: ProcessingLogSort

    /**
     * ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
     * @type {Direction}
     * @memberof ClientComparisonApiListComparisonProcessingLogs
     */
    readonly direction?: Direction

    /**
     * 
     * @type {number}
     * @memberof ClientComparisonApiListComparisonProcessingLogs
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ClientComparisonApiListComparisonProcessingLogs
     */
    readonly offset?: number
}

/**
 * Request parameters for listComparisons operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiListComparisonsRequest
 */
export interface ClientComparisonApiListComparisonsRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiListComparisons
     */
    readonly clientId: string

    /**
     * 
     * @type {ClientComparisonSort}
     * @memberof ClientComparisonApiListComparisons
     */
    readonly sort?: ClientComparisonSort

    /**
     * ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
     * @type {Direction}
     * @memberof ClientComparisonApiListComparisons
     */
    readonly direction?: Direction

    /**
     * 
     * @type {number}
     * @memberof ClientComparisonApiListComparisons
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ClientComparisonApiListComparisons
     */
    readonly offset?: number
}

/**
 * Request parameters for uploadComparisonFile operation in ClientComparisonApi.
 * @export
 * @interface ClientComparisonApiUploadComparisonFileRequest
 */
export interface ClientComparisonApiUploadComparisonFileRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientComparisonApiUploadComparisonFile
     */
    readonly clientId: string

    /**
     * 
     * @type {File}
     * @memberof ClientComparisonApiUploadComparisonFile
     */
    readonly file: File
}

/**
 * ClientComparisonApi - object-oriented interface
 * @export
 * @class ClientComparisonApi
 * @extends {BaseAPI}
 */
export class ClientComparisonApi extends BaseAPI {
    /**
     * 
     * @param {ClientComparisonApiCreateComparisonRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public createComparison(requestParameters: ClientComparisonApiCreateComparisonRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).createComparison(requestParameters.clientId, requestParameters.comparisonValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientComparisonApiDownloadComparisionFileBFTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public downloadComparisionFileBFT(requestParameters: ClientComparisonApiDownloadComparisionFileBFTRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).downloadComparisionFileBFT(requestParameters.clientId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientComparisonApiDownloadComparisionFileVisualExcelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public downloadComparisionFileVisualExcel(requestParameters: ClientComparisonApiDownloadComparisionFileVisualExcelRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).downloadComparisionFileVisualExcel(requestParameters.clientId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientComparisonApiDownloadComparisonFileProcessingLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public downloadComparisonFileProcessingLogs(requestParameters: ClientComparisonApiDownloadComparisonFileProcessingLogsRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).downloadComparisonFileProcessingLogs(requestParameters.clientId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientComparisonApiDownloadComparisonProcessingLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public downloadComparisonProcessingLogs(requestParameters: ClientComparisonApiDownloadComparisonProcessingLogsRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).downloadComparisonProcessingLogs(requestParameters.clientId, requestParameters.comparisonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientComparisonApiDownloadComparisonRowsParquetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public downloadComparisonRowsParquet(requestParameters: ClientComparisonApiDownloadComparisonRowsParquetRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).downloadComparisonRowsParquet(requestParameters.clientId, requestParameters.comparisonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientComparisonApiDownloadComparisonVisualExcelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public downloadComparisonVisualExcel(requestParameters: ClientComparisonApiDownloadComparisonVisualExcelRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).downloadComparisonVisualExcel(requestParameters.clientId, requestParameters.comparisonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientComparisonApiGetComparisonRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public getComparison(requestParameters: ClientComparisonApiGetComparisonRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).getComparison(requestParameters.clientId, requestParameters.comparisonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientComparisonApiGetComparisonFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public getComparisonFile(requestParameters: ClientComparisonApiGetComparisonFileRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).getComparisonFile(requestParameters.clientId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientComparisonApiListComparisonFileProcessingLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public listComparisonFileProcessingLogs(requestParameters: ClientComparisonApiListComparisonFileProcessingLogsRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).listComparisonFileProcessingLogs(requestParameters.clientId, requestParameters.fileId, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientComparisonApiListComparisonFilesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public listComparisonFiles(requestParameters: ClientComparisonApiListComparisonFilesRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).listComparisonFiles(requestParameters.clientId, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientComparisonApiListComparisonProcessingLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public listComparisonProcessingLogs(requestParameters: ClientComparisonApiListComparisonProcessingLogsRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).listComparisonProcessingLogs(requestParameters.clientId, requestParameters.comparisonId, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientComparisonApiListComparisonsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public listComparisons(requestParameters: ClientComparisonApiListComparisonsRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).listComparisons(requestParameters.clientId, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientComparisonApiUploadComparisonFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientComparisonApi
     */
    public uploadComparisonFile(requestParameters: ClientComparisonApiUploadComparisonFileRequest, options?: RawAxiosRequestConfig) {
        return ClientComparisonApiFp(this.configuration).uploadComparisonFile(requestParameters.clientId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ClientSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList: async (sort?: ClientSort, direction?: Direction, limit?: number, offset?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['Sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the current user\'s client list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ClientSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getList(sort?: ClientSort, direction?: Direction, limit?: number, offset?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDtoListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getList(sort, direction, limit, offset, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientsApi.getList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the current user\'s client list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientsApi.updateClientList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsApiFp(configuration)
    return {
        /**
         * 
         * @param {ClientsApiGetListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(requestParameters: ClientsApiGetListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ClientDtoListRepresentation> {
            return localVarFp.getList(requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the current user\'s client list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientList(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateClientList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getList operation in ClientsApi.
 * @export
 * @interface ClientsApiGetListRequest
 */
export interface ClientsApiGetListRequest {
    /**
     * 
     * @type {ClientSort}
     * @memberof ClientsApiGetList
     */
    readonly sort?: ClientSort

    /**
     * ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
     * @type {Direction}
     * @memberof ClientsApiGetList
     */
    readonly direction?: Direction

    /**
     * 
     * @type {number}
     * @memberof ClientsApiGetList
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ClientsApiGetList
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof ClientsApiGetList
     */
    readonly search?: string
}

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * 
     * @param {ClientsApiGetListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public getList(requestParameters: ClientsApiGetListRequest = {}, options?: RawAxiosRequestConfig) {
        return ClientsApiFp(this.configuration).getList(requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the current user\'s client list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public updateClientList(options?: RawAxiosRequestConfig) {
        return ClientsApiFp(this.configuration).updateClientList(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClientsAdminApi - axios parameter creator
 * @export
 */
export const ClientsAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/admin/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {EmailValidator} [emailValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectUser: async (id: string, emailValidator?: EmailValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('connectUser', 'id', id)
            const localVarPath = `/api/admin/clients/{id}/user`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ClientUsersValidator} [clientUsersValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectUsers: async (id: string, clientUsersValidator?: ClientUsersValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('connectUsers', 'id', id)
            const localVarPath = `/api/admin/clients/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientUsersValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClientCreateValidator} [clientCreateValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (clientCreateValidator?: ClientCreateValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCreateValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectUser: async (id: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('disconnectUser', 'id', id)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('disconnectUser', 'userId', userId)
            const localVarPath = `/api/admin/clients/{id}/users/{userId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/api/admin/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {Array<string>} [clientIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientsUsageReport: async (start?: string, end?: string, clientIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/clients/usage-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (start !== undefined) {
                localVarQueryParameter['Start'] = (start as any instanceof Date) ?
                    (start as any).toISOString().substring(0,10) :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['End'] = (end as any instanceof Date) ?
                    (end as any).toISOString().substring(0,10) :
                    end;
            }

            if (clientIds) {
                localVarQueryParameter['ClientIds'] = clientIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClientSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList: async (sort?: ClientSort, direction?: Direction, limit?: number, offset?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['Sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ConnectivityType} connectivityType 
         * @param {UserSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserList: async (id: string, connectivityType: ConnectivityType, sort?: UserSort, direction?: Direction, limit?: number, offset?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserList', 'id', id)
            // verify required parameter 'connectivityType' is not null or undefined
            assertParamExists('getUserList', 'connectivityType', connectivityType)
            const localVarPath = `/api/admin/clients/{id}/users/{connectivityType}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"connectivityType"}}`, encodeURIComponent(String(connectivityType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['Sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ClientAdminValidator} [clientAdminValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, clientAdminValidator?: ClientAdminValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            const localVarPath = `/api/admin/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientAdminValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsAdminApi - functional programming interface
 * @export
 */
export const ClientsAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientsAdminApi._delete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {EmailValidator} [emailValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectUser(id: string, emailValidator?: EmailValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectUser(id, emailValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientsAdminApi.connectUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ClientUsersValidator} [clientUsersValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectUsers(id: string, clientUsersValidator?: ClientUsersValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectUsers(id, clientUsersValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientsAdminApi.connectUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ClientCreateValidator} [clientCreateValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(clientCreateValidator?: ClientCreateValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(clientCreateValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientsAdminApi.create']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnectUser(id: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disconnectUser(id, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientsAdminApi.disconnectUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientsAdminApi.get']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {Array<string>} [clientIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientsUsageReport(start?: string, end?: string, clientIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientsUsageReport(start, end, clientIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientsAdminApi.getClientsUsageReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ClientSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getList(sort?: ClientSort, direction?: Direction, limit?: number, offset?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAdminDtoListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getList(sort, direction, limit, offset, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientsAdminApi.getList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ConnectivityType} connectivityType 
         * @param {UserSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserList(id: string, connectivityType: ConnectivityType, sort?: UserSort, direction?: Direction, limit?: number, offset?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDtoListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserList(id, connectivityType, sort, direction, limit, offset, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientsAdminApi.getUserList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ClientAdminValidator} [clientAdminValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, clientAdminValidator?: ClientAdminValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, clientAdminValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientsAdminApi.update']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ClientsAdminApi - factory interface
 * @export
 */
export const ClientsAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsAdminApiFp(configuration)
    return {
        /**
         * 
         * @param {ClientsAdminApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: ClientsAdminApiDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp._delete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientsAdminApiConnectUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectUser(requestParameters: ClientsAdminApiConnectUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.connectUser(requestParameters.id, requestParameters.emailValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientsAdminApiConnectUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectUsers(requestParameters: ClientsAdminApiConnectUsersRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.connectUsers(requestParameters.id, requestParameters.clientUsersValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientsAdminApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: ClientsAdminApiCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ClientAdminDto> {
            return localVarFp.create(requestParameters.clientCreateValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientsAdminApiDisconnectUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectUser(requestParameters: ClientsAdminApiDisconnectUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.disconnectUser(requestParameters.id, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientsAdminApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: ClientsAdminApiGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ClientAdminDto> {
            return localVarFp.get(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientsAdminApiGetClientsUsageReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientsUsageReport(requestParameters: ClientsAdminApiGetClientsUsageReportRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getClientsUsageReport(requestParameters.start, requestParameters.end, requestParameters.clientIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientsAdminApiGetListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(requestParameters: ClientsAdminApiGetListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ClientAdminDtoListRepresentation> {
            return localVarFp.getList(requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientsAdminApiGetUserListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserList(requestParameters: ClientsAdminApiGetUserListRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDtoListRepresentation> {
            return localVarFp.getUserList(requestParameters.id, requestParameters.connectivityType, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientsAdminApiUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(requestParameters: ClientsAdminApiUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ClientAdminDto> {
            return localVarFp.update(requestParameters.id, requestParameters.clientAdminValidator, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in ClientsAdminApi.
 * @export
 * @interface ClientsAdminApiDeleteRequest
 */
export interface ClientsAdminApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientsAdminApiDelete
     */
    readonly id: string
}

/**
 * Request parameters for connectUser operation in ClientsAdminApi.
 * @export
 * @interface ClientsAdminApiConnectUserRequest
 */
export interface ClientsAdminApiConnectUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientsAdminApiConnectUser
     */
    readonly id: string

    /**
     * 
     * @type {EmailValidator}
     * @memberof ClientsAdminApiConnectUser
     */
    readonly emailValidator?: EmailValidator
}

/**
 * Request parameters for connectUsers operation in ClientsAdminApi.
 * @export
 * @interface ClientsAdminApiConnectUsersRequest
 */
export interface ClientsAdminApiConnectUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientsAdminApiConnectUsers
     */
    readonly id: string

    /**
     * 
     * @type {ClientUsersValidator}
     * @memberof ClientsAdminApiConnectUsers
     */
    readonly clientUsersValidator?: ClientUsersValidator
}

/**
 * Request parameters for create operation in ClientsAdminApi.
 * @export
 * @interface ClientsAdminApiCreateRequest
 */
export interface ClientsAdminApiCreateRequest {
    /**
     * 
     * @type {ClientCreateValidator}
     * @memberof ClientsAdminApiCreate
     */
    readonly clientCreateValidator?: ClientCreateValidator
}

/**
 * Request parameters for disconnectUser operation in ClientsAdminApi.
 * @export
 * @interface ClientsAdminApiDisconnectUserRequest
 */
export interface ClientsAdminApiDisconnectUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientsAdminApiDisconnectUser
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ClientsAdminApiDisconnectUser
     */
    readonly userId: string
}

/**
 * Request parameters for get operation in ClientsAdminApi.
 * @export
 * @interface ClientsAdminApiGetRequest
 */
export interface ClientsAdminApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientsAdminApiGet
     */
    readonly id: string
}

/**
 * Request parameters for getClientsUsageReport operation in ClientsAdminApi.
 * @export
 * @interface ClientsAdminApiGetClientsUsageReportRequest
 */
export interface ClientsAdminApiGetClientsUsageReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientsAdminApiGetClientsUsageReport
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof ClientsAdminApiGetClientsUsageReport
     */
    readonly end?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ClientsAdminApiGetClientsUsageReport
     */
    readonly clientIds?: Array<string>
}

/**
 * Request parameters for getList operation in ClientsAdminApi.
 * @export
 * @interface ClientsAdminApiGetListRequest
 */
export interface ClientsAdminApiGetListRequest {
    /**
     * 
     * @type {ClientSort}
     * @memberof ClientsAdminApiGetList
     */
    readonly sort?: ClientSort

    /**
     * ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
     * @type {Direction}
     * @memberof ClientsAdminApiGetList
     */
    readonly direction?: Direction

    /**
     * 
     * @type {number}
     * @memberof ClientsAdminApiGetList
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ClientsAdminApiGetList
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof ClientsAdminApiGetList
     */
    readonly search?: string
}

/**
 * Request parameters for getUserList operation in ClientsAdminApi.
 * @export
 * @interface ClientsAdminApiGetUserListRequest
 */
export interface ClientsAdminApiGetUserListRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientsAdminApiGetUserList
     */
    readonly id: string

    /**
     * 
     * @type {ConnectivityType}
     * @memberof ClientsAdminApiGetUserList
     */
    readonly connectivityType: ConnectivityType

    /**
     * 
     * @type {UserSort}
     * @memberof ClientsAdminApiGetUserList
     */
    readonly sort?: UserSort

    /**
     * ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
     * @type {Direction}
     * @memberof ClientsAdminApiGetUserList
     */
    readonly direction?: Direction

    /**
     * 
     * @type {number}
     * @memberof ClientsAdminApiGetUserList
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ClientsAdminApiGetUserList
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof ClientsAdminApiGetUserList
     */
    readonly search?: string
}

/**
 * Request parameters for update operation in ClientsAdminApi.
 * @export
 * @interface ClientsAdminApiUpdateRequest
 */
export interface ClientsAdminApiUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientsAdminApiUpdate
     */
    readonly id: string

    /**
     * 
     * @type {ClientAdminValidator}
     * @memberof ClientsAdminApiUpdate
     */
    readonly clientAdminValidator?: ClientAdminValidator
}

/**
 * ClientsAdminApi - object-oriented interface
 * @export
 * @class ClientsAdminApi
 * @extends {BaseAPI}
 */
export class ClientsAdminApi extends BaseAPI {
    /**
     * 
     * @param {ClientsAdminApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAdminApi
     */
    public _delete(requestParameters: ClientsAdminApiDeleteRequest, options?: RawAxiosRequestConfig) {
        return ClientsAdminApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAdminApiConnectUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAdminApi
     */
    public connectUser(requestParameters: ClientsAdminApiConnectUserRequest, options?: RawAxiosRequestConfig) {
        return ClientsAdminApiFp(this.configuration).connectUser(requestParameters.id, requestParameters.emailValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAdminApiConnectUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAdminApi
     */
    public connectUsers(requestParameters: ClientsAdminApiConnectUsersRequest, options?: RawAxiosRequestConfig) {
        return ClientsAdminApiFp(this.configuration).connectUsers(requestParameters.id, requestParameters.clientUsersValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAdminApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAdminApi
     */
    public create(requestParameters: ClientsAdminApiCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return ClientsAdminApiFp(this.configuration).create(requestParameters.clientCreateValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAdminApiDisconnectUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAdminApi
     */
    public disconnectUser(requestParameters: ClientsAdminApiDisconnectUserRequest, options?: RawAxiosRequestConfig) {
        return ClientsAdminApiFp(this.configuration).disconnectUser(requestParameters.id, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAdminApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAdminApi
     */
    public get(requestParameters: ClientsAdminApiGetRequest, options?: RawAxiosRequestConfig) {
        return ClientsAdminApiFp(this.configuration).get(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAdminApiGetClientsUsageReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAdminApi
     */
    public getClientsUsageReport(requestParameters: ClientsAdminApiGetClientsUsageReportRequest = {}, options?: RawAxiosRequestConfig) {
        return ClientsAdminApiFp(this.configuration).getClientsUsageReport(requestParameters.start, requestParameters.end, requestParameters.clientIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAdminApiGetListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAdminApi
     */
    public getList(requestParameters: ClientsAdminApiGetListRequest = {}, options?: RawAxiosRequestConfig) {
        return ClientsAdminApiFp(this.configuration).getList(requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAdminApiGetUserListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAdminApi
     */
    public getUserList(requestParameters: ClientsAdminApiGetUserListRequest, options?: RawAxiosRequestConfig) {
        return ClientsAdminApiFp(this.configuration).getUserList(requestParameters.id, requestParameters.connectivityType, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAdminApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAdminApi
     */
    public update(requestParameters: ClientsAdminApiUpdateRequest, options?: RawAxiosRequestConfig) {
        return ClientsAdminApiFp(this.configuration).update(requestParameters.id, requestParameters.clientAdminValidator, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ImportsApi - axios parameter creator
 * @export
 */
export const ImportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get import list
         * @param {number} [pagingLimit] 
         * @param {number} [pagingOffset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploads: async (pagingLimit?: number, pagingOffset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/imports/eba-transparency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pagingLimit !== undefined) {
                localVarQueryParameter['paging.Limit'] = pagingLimit;
            }

            if (pagingOffset !== undefined) {
                localVarQueryParameter['paging.Offset'] = pagingOffset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download source import file
         * @param {string} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploads_1: async (importId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('getUploads_1', 'importId', importId)
            const localVarPath = `/api/imports/eba-transparency/{importId}`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a csv file to start a new import of the EBA Transparent Exercise data
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadContentUpdate: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadContentUpdate', 'file', file)
            const localVarPath = `/api/imports/eba-transparency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImportsApi - functional programming interface
 * @export
 */
export const ImportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get import list
         * @param {number} [pagingLimit] 
         * @param {number} [pagingOffset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploads(pagingLimit?: number, pagingOffset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportRepresentationListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploads(pagingLimit, pagingOffset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportsApi.getUploads']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Download source import file
         * @param {string} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploads_1(importId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploads_1(importId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportsApi.getUploads_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload a csv file to start a new import of the EBA Transparent Exercise data
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadContentUpdate(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadContentUpdate(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportsApi.uploadContentUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ImportsApi - factory interface
 * @export
 */
export const ImportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get import list
         * @param {ImportsApiGetUploadsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploads(requestParameters: ImportsApiGetUploadsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ImportRepresentationListRepresentation> {
            return localVarFp.getUploads(requestParameters.pagingLimit, requestParameters.pagingOffset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download source import file
         * @param {ImportsApiGetUploads0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploads_1(requestParameters: ImportsApiGetUploads0Request, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getUploads_1(requestParameters.importId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a csv file to start a new import of the EBA Transparent Exercise data
         * @param {ImportsApiUploadContentUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadContentUpdate(requestParameters: ImportsApiUploadContentUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ImportRepresentation> {
            return localVarFp.uploadContentUpdate(requestParameters.file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getUploads operation in ImportsApi.
 * @export
 * @interface ImportsApiGetUploadsRequest
 */
export interface ImportsApiGetUploadsRequest {
    /**
     * 
     * @type {number}
     * @memberof ImportsApiGetUploads
     */
    readonly pagingLimit?: number

    /**
     * 
     * @type {number}
     * @memberof ImportsApiGetUploads
     */
    readonly pagingOffset?: number
}

/**
 * Request parameters for getUploads_1 operation in ImportsApi.
 * @export
 * @interface ImportsApiGetUploads0Request
 */
export interface ImportsApiGetUploads0Request {
    /**
     * 
     * @type {string}
     * @memberof ImportsApiGetUploads0
     */
    readonly importId: string
}

/**
 * Request parameters for uploadContentUpdate operation in ImportsApi.
 * @export
 * @interface ImportsApiUploadContentUpdateRequest
 */
export interface ImportsApiUploadContentUpdateRequest {
    /**
     * 
     * @type {File}
     * @memberof ImportsApiUploadContentUpdate
     */
    readonly file: File
}

/**
 * ImportsApi - object-oriented interface
 * @export
 * @class ImportsApi
 * @extends {BaseAPI}
 */
export class ImportsApi extends BaseAPI {
    /**
     * 
     * @summary Get import list
     * @param {ImportsApiGetUploadsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportsApi
     */
    public getUploads(requestParameters: ImportsApiGetUploadsRequest = {}, options?: RawAxiosRequestConfig) {
        return ImportsApiFp(this.configuration).getUploads(requestParameters.pagingLimit, requestParameters.pagingOffset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download source import file
     * @param {ImportsApiGetUploads0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportsApi
     */
    public getUploads_1(requestParameters: ImportsApiGetUploads0Request, options?: RawAxiosRequestConfig) {
        return ImportsApiFp(this.configuration).getUploads_1(requestParameters.importId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a csv file to start a new import of the EBA Transparent Exercise data
     * @param {ImportsApiUploadContentUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportsApi
     */
    public uploadContentUpdate(requestParameters: ImportsApiUploadContentUpdateRequest, options?: RawAxiosRequestConfig) {
        return ImportsApiFp(this.configuration).uploadContentUpdate(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InternalApi - axios parameter creator
 * @export
 */
export const InternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [batchSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backfillValidationChecks: async (batchSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/internal/jobs:backfill_validation_checks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (batchSize !== undefined) {
                localVarQueryParameter['batch_size'] = batchSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Launches a background job to clean up the artifacts that are stored after the ATOME Particles engine has processed the reports
         * @param {number} [batchSize] 
         * @param {number} [concurrencyLimit] 
         * @param {string} [createdBefore] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanAtomeParticlesArtifacts: async (batchSize?: number, concurrencyLimit?: number, createdBefore?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/internal/jobs:clean_atome_particles_artifacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (batchSize !== undefined) {
                localVarQueryParameter['batch_size'] = batchSize;
            }

            if (concurrencyLimit !== undefined) {
                localVarQueryParameter['concurrency_limit'] = concurrencyLimit;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['created_before'] = createdBefore;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobs: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteJobs', 'id', id)
            const localVarPath = `/api/internal/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pagingLimit] 
         * @param {number} [pagingOffset] 
         * @param {string} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLogs: async (pagingLimit?: number, pagingOffset?: number, action?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/internal/audit-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pagingLimit !== undefined) {
                localVarQueryParameter['Paging.Limit'] = pagingLimit;
            }

            if (pagingOffset !== undefined) {
                localVarQueryParameter['Paging.Offset'] = pagingOffset;
            }

            if (action !== undefined) {
                localVarQueryParameter['Action'] = action;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getJobs', 'id', id)
            const localVarPath = `/api/internal/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs_1: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/internal/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes and reloads all static validations stored in the database for PowerBI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reloadAtomeParticlesValidations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/internal/atome-particles/reload-validations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calls the Atome Particles update endpoint for each known package until succeeded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAtomeParticlesPackages: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/internal/atome-particles/update-packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalApi - functional programming interface
 * @export
 */
export const InternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [batchSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backfillValidationChecks(batchSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backfillValidationChecks(batchSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.backfillValidationChecks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Launches a background job to clean up the artifacts that are stored after the ATOME Particles engine has processed the reports
         * @param {number} [batchSize] 
         * @param {number} [concurrencyLimit] 
         * @param {string} [createdBefore] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanAtomeParticlesArtifacts(batchSize?: number, concurrencyLimit?: number, createdBefore?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cleanAtomeParticlesArtifacts(batchSize, concurrencyLimit, createdBefore, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.cleanAtomeParticlesArtifacts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJobs(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJobs(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.deleteJobs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [pagingLimit] 
         * @param {number} [pagingOffset] 
         * @param {string} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditLogs(pagingLimit?: number, pagingOffset?: number, action?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditLogRepresentationListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditLogs(pagingLimit, pagingOffset, action, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.getAuditLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobs(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobs(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.getJobs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobs_1(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobs_1(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.getJobs_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Removes and reloads all static validations stored in the database for PowerBI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reloadAtomeParticlesValidations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reloadAtomeParticlesValidations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.reloadAtomeParticlesValidations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Calls the Atome Particles update endpoint for each known package until succeeded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAtomeParticlesPackages(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAtomeParticlesPackages(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.updateAtomeParticlesPackages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InternalApi - factory interface
 * @export
 */
export const InternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalApiFp(configuration)
    return {
        /**
         * 
         * @param {InternalApiBackfillValidationChecksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backfillValidationChecks(requestParameters: InternalApiBackfillValidationChecksRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<JobStatusDto> {
            return localVarFp.backfillValidationChecks(requestParameters.batchSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Launches a background job to clean up the artifacts that are stored after the ATOME Particles engine has processed the reports
         * @param {InternalApiCleanAtomeParticlesArtifactsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanAtomeParticlesArtifacts(requestParameters: InternalApiCleanAtomeParticlesArtifactsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<JobStatusDto> {
            return localVarFp.cleanAtomeParticlesArtifacts(requestParameters.batchSize, requestParameters.concurrencyLimit, requestParameters.createdBefore, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InternalApiDeleteJobsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobs(requestParameters: InternalApiDeleteJobsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteJobs(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InternalApiGetAuditLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLogs(requestParameters: InternalApiGetAuditLogsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AuditLogRepresentationListRepresentation> {
            return localVarFp.getAuditLogs(requestParameters.pagingLimit, requestParameters.pagingOffset, requestParameters.action, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InternalApiGetJobsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs(requestParameters: InternalApiGetJobsRequest, options?: RawAxiosRequestConfig): AxiosPromise<JobStatusDto> {
            return localVarFp.getJobs(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InternalApiGetJobs0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs_1(requestParameters: InternalApiGetJobs0Request = {}, options?: RawAxiosRequestConfig): AxiosPromise<JobListRepresentation> {
            return localVarFp.getJobs_1(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes and reloads all static validations stored in the database for PowerBI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reloadAtomeParticlesValidations(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reloadAtomeParticlesValidations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calls the Atome Particles update endpoint for each known package until succeeded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAtomeParticlesPackages(options?: RawAxiosRequestConfig): AxiosPromise<JobStatusDto> {
            return localVarFp.updateAtomeParticlesPackages(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for backfillValidationChecks operation in InternalApi.
 * @export
 * @interface InternalApiBackfillValidationChecksRequest
 */
export interface InternalApiBackfillValidationChecksRequest {
    /**
     * 
     * @type {number}
     * @memberof InternalApiBackfillValidationChecks
     */
    readonly batchSize?: number
}

/**
 * Request parameters for cleanAtomeParticlesArtifacts operation in InternalApi.
 * @export
 * @interface InternalApiCleanAtomeParticlesArtifactsRequest
 */
export interface InternalApiCleanAtomeParticlesArtifactsRequest {
    /**
     * 
     * @type {number}
     * @memberof InternalApiCleanAtomeParticlesArtifacts
     */
    readonly batchSize?: number

    /**
     * 
     * @type {number}
     * @memberof InternalApiCleanAtomeParticlesArtifacts
     */
    readonly concurrencyLimit?: number

    /**
     * 
     * @type {string}
     * @memberof InternalApiCleanAtomeParticlesArtifacts
     */
    readonly createdBefore?: string
}

/**
 * Request parameters for deleteJobs operation in InternalApi.
 * @export
 * @interface InternalApiDeleteJobsRequest
 */
export interface InternalApiDeleteJobsRequest {
    /**
     * 
     * @type {string}
     * @memberof InternalApiDeleteJobs
     */
    readonly id: string
}

/**
 * Request parameters for getAuditLogs operation in InternalApi.
 * @export
 * @interface InternalApiGetAuditLogsRequest
 */
export interface InternalApiGetAuditLogsRequest {
    /**
     * 
     * @type {number}
     * @memberof InternalApiGetAuditLogs
     */
    readonly pagingLimit?: number

    /**
     * 
     * @type {number}
     * @memberof InternalApiGetAuditLogs
     */
    readonly pagingOffset?: number

    /**
     * 
     * @type {string}
     * @memberof InternalApiGetAuditLogs
     */
    readonly action?: string
}

/**
 * Request parameters for getJobs operation in InternalApi.
 * @export
 * @interface InternalApiGetJobsRequest
 */
export interface InternalApiGetJobsRequest {
    /**
     * 
     * @type {string}
     * @memberof InternalApiGetJobs
     */
    readonly id: string
}

/**
 * Request parameters for getJobs_1 operation in InternalApi.
 * @export
 * @interface InternalApiGetJobs0Request
 */
export interface InternalApiGetJobs0Request {
    /**
     * 
     * @type {number}
     * @memberof InternalApiGetJobs0
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof InternalApiGetJobs0
     */
    readonly offset?: number
}

/**
 * InternalApi - object-oriented interface
 * @export
 * @class InternalApi
 * @extends {BaseAPI}
 */
export class InternalApi extends BaseAPI {
    /**
     * 
     * @param {InternalApiBackfillValidationChecksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public backfillValidationChecks(requestParameters: InternalApiBackfillValidationChecksRequest = {}, options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).backfillValidationChecks(requestParameters.batchSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Launches a background job to clean up the artifacts that are stored after the ATOME Particles engine has processed the reports
     * @param {InternalApiCleanAtomeParticlesArtifactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public cleanAtomeParticlesArtifacts(requestParameters: InternalApiCleanAtomeParticlesArtifactsRequest = {}, options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).cleanAtomeParticlesArtifacts(requestParameters.batchSize, requestParameters.concurrencyLimit, requestParameters.createdBefore, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternalApiDeleteJobsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deleteJobs(requestParameters: InternalApiDeleteJobsRequest, options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).deleteJobs(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternalApiGetAuditLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getAuditLogs(requestParameters: InternalApiGetAuditLogsRequest = {}, options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).getAuditLogs(requestParameters.pagingLimit, requestParameters.pagingOffset, requestParameters.action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternalApiGetJobsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getJobs(requestParameters: InternalApiGetJobsRequest, options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).getJobs(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternalApiGetJobs0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getJobs_1(requestParameters: InternalApiGetJobs0Request = {}, options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).getJobs_1(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes and reloads all static validations stored in the database for PowerBI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public reloadAtomeParticlesValidations(options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).reloadAtomeParticlesValidations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calls the Atome Particles update endpoint for each known package until succeeded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public updateAtomeParticlesPackages(options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).updateAtomeParticlesPackages(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PowerBiExplanationApi - axios parameter creator
 * @export
 */
export const PowerBiExplanationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new powerBI section, positions will not be reshuffled. (Admin endpoint, not available in app)
         * @param {CreatePowerBiExplanationSectionValidator} createPowerBiExplanationSectionValidator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSection: async (createPowerBiExplanationSectionValidator: CreatePowerBiExplanationSectionValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPowerBiExplanationSectionValidator' is not null or undefined
            assertParamExists('createSection', 'createPowerBiExplanationSectionValidator', createPowerBiExplanationSectionValidator)
            const localVarPath = `/api/powerbiexplanation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPowerBiExplanationSectionValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a section with a specific ID. (Admin endpoint, not available in app)
         * @param {string} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSection: async (sectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectionId' is not null or undefined
            assertParamExists('deleteSection', 'sectionId', sectionId)
            const localVarPath = `/api/powerbiexplanation/{sectionId}`
                .replace(`{${"sectionId"}}`, encodeURIComponent(String(sectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the full list of powerBI explanation sections for the provided scope. To be displayed next to dashboard
         * @param {ScopeType} scopeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSections: async (scopeType: ScopeType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scopeType' is not null or undefined
            assertParamExists('getSections', 'scopeType', scopeType)
            const localVarPath = `/api/powerbiexplanation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (scopeType !== undefined) {
                localVarQueryParameter['ScopeType'] = scopeType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a section, overwriting all properties. (Admin endpoint, not available in app)
         * @param {string} sectionId 
         * @param {UpdatePowerBiExplanationSectionValidator} updatePowerBiExplanationSectionValidator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSection: async (sectionId: string, updatePowerBiExplanationSectionValidator: UpdatePowerBiExplanationSectionValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectionId' is not null or undefined
            assertParamExists('updateSection', 'sectionId', sectionId)
            // verify required parameter 'updatePowerBiExplanationSectionValidator' is not null or undefined
            assertParamExists('updateSection', 'updatePowerBiExplanationSectionValidator', updatePowerBiExplanationSectionValidator)
            const localVarPath = `/api/powerbiexplanation/{sectionId}`
                .replace(`{${"sectionId"}}`, encodeURIComponent(String(sectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePowerBiExplanationSectionValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PowerBiExplanationApi - functional programming interface
 * @export
 */
export const PowerBiExplanationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PowerBiExplanationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new powerBI section, positions will not be reshuffled. (Admin endpoint, not available in app)
         * @param {CreatePowerBiExplanationSectionValidator} createPowerBiExplanationSectionValidator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSection(createPowerBiExplanationSectionValidator: CreatePowerBiExplanationSectionValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PowerBiExplanationSection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSection(createPowerBiExplanationSectionValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PowerBiExplanationApi.createSection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a section with a specific ID. (Admin endpoint, not available in app)
         * @param {string} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSection(sectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSection(sectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PowerBiExplanationApi.deleteSection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the full list of powerBI explanation sections for the provided scope. To be displayed next to dashboard
         * @param {ScopeType} scopeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSections(scopeType: ScopeType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PowerBiExplanationSection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSections(scopeType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PowerBiExplanationApi.getSections']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a section, overwriting all properties. (Admin endpoint, not available in app)
         * @param {string} sectionId 
         * @param {UpdatePowerBiExplanationSectionValidator} updatePowerBiExplanationSectionValidator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSection(sectionId: string, updatePowerBiExplanationSectionValidator: UpdatePowerBiExplanationSectionValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PowerBiExplanationSection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSection(sectionId, updatePowerBiExplanationSectionValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PowerBiExplanationApi.updateSection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PowerBiExplanationApi - factory interface
 * @export
 */
export const PowerBiExplanationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PowerBiExplanationApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new powerBI section, positions will not be reshuffled. (Admin endpoint, not available in app)
         * @param {PowerBiExplanationApiCreateSectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSection(requestParameters: PowerBiExplanationApiCreateSectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<PowerBiExplanationSection> {
            return localVarFp.createSection(requestParameters.createPowerBiExplanationSectionValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a section with a specific ID. (Admin endpoint, not available in app)
         * @param {PowerBiExplanationApiDeleteSectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSection(requestParameters: PowerBiExplanationApiDeleteSectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSection(requestParameters.sectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the full list of powerBI explanation sections for the provided scope. To be displayed next to dashboard
         * @param {PowerBiExplanationApiGetSectionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSections(requestParameters: PowerBiExplanationApiGetSectionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PowerBiExplanationSection>> {
            return localVarFp.getSections(requestParameters.scopeType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a section, overwriting all properties. (Admin endpoint, not available in app)
         * @param {PowerBiExplanationApiUpdateSectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSection(requestParameters: PowerBiExplanationApiUpdateSectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<PowerBiExplanationSection> {
            return localVarFp.updateSection(requestParameters.sectionId, requestParameters.updatePowerBiExplanationSectionValidator, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSection operation in PowerBiExplanationApi.
 * @export
 * @interface PowerBiExplanationApiCreateSectionRequest
 */
export interface PowerBiExplanationApiCreateSectionRequest {
    /**
     * 
     * @type {CreatePowerBiExplanationSectionValidator}
     * @memberof PowerBiExplanationApiCreateSection
     */
    readonly createPowerBiExplanationSectionValidator: CreatePowerBiExplanationSectionValidator
}

/**
 * Request parameters for deleteSection operation in PowerBiExplanationApi.
 * @export
 * @interface PowerBiExplanationApiDeleteSectionRequest
 */
export interface PowerBiExplanationApiDeleteSectionRequest {
    /**
     * 
     * @type {string}
     * @memberof PowerBiExplanationApiDeleteSection
     */
    readonly sectionId: string
}

/**
 * Request parameters for getSections operation in PowerBiExplanationApi.
 * @export
 * @interface PowerBiExplanationApiGetSectionsRequest
 */
export interface PowerBiExplanationApiGetSectionsRequest {
    /**
     * 
     * @type {ScopeType}
     * @memberof PowerBiExplanationApiGetSections
     */
    readonly scopeType: ScopeType
}

/**
 * Request parameters for updateSection operation in PowerBiExplanationApi.
 * @export
 * @interface PowerBiExplanationApiUpdateSectionRequest
 */
export interface PowerBiExplanationApiUpdateSectionRequest {
    /**
     * 
     * @type {string}
     * @memberof PowerBiExplanationApiUpdateSection
     */
    readonly sectionId: string

    /**
     * 
     * @type {UpdatePowerBiExplanationSectionValidator}
     * @memberof PowerBiExplanationApiUpdateSection
     */
    readonly updatePowerBiExplanationSectionValidator: UpdatePowerBiExplanationSectionValidator
}

/**
 * PowerBiExplanationApi - object-oriented interface
 * @export
 * @class PowerBiExplanationApi
 * @extends {BaseAPI}
 */
export class PowerBiExplanationApi extends BaseAPI {
    /**
     * 
     * @summary Create a new powerBI section, positions will not be reshuffled. (Admin endpoint, not available in app)
     * @param {PowerBiExplanationApiCreateSectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiExplanationApi
     */
    public createSection(requestParameters: PowerBiExplanationApiCreateSectionRequest, options?: RawAxiosRequestConfig) {
        return PowerBiExplanationApiFp(this.configuration).createSection(requestParameters.createPowerBiExplanationSectionValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a section with a specific ID. (Admin endpoint, not available in app)
     * @param {PowerBiExplanationApiDeleteSectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiExplanationApi
     */
    public deleteSection(requestParameters: PowerBiExplanationApiDeleteSectionRequest, options?: RawAxiosRequestConfig) {
        return PowerBiExplanationApiFp(this.configuration).deleteSection(requestParameters.sectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the full list of powerBI explanation sections for the provided scope. To be displayed next to dashboard
     * @param {PowerBiExplanationApiGetSectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiExplanationApi
     */
    public getSections(requestParameters: PowerBiExplanationApiGetSectionsRequest, options?: RawAxiosRequestConfig) {
        return PowerBiExplanationApiFp(this.configuration).getSections(requestParameters.scopeType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a section, overwriting all properties. (Admin endpoint, not available in app)
     * @param {PowerBiExplanationApiUpdateSectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiExplanationApi
     */
    public updateSection(requestParameters: PowerBiExplanationApiUpdateSectionRequest, options?: RawAxiosRequestConfig) {
        return PowerBiExplanationApiFp(this.configuration).updateSection(requestParameters.sectionId, requestParameters.updatePowerBiExplanationSectionValidator, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {ApplyActionToAllValidationChecksValidator} [applyActionToAllValidationChecksValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyActionToAllChecks: async (clientId: string, reportId: string, versionId: string, applyActionToAllValidationChecksValidator?: ApplyActionToAllValidationChecksValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('applyActionToAllChecks', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('applyActionToAllChecks', 'reportId', reportId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('applyActionToAllChecks', 'versionId', versionId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/versions/{versionId}/reviewed-checks:apply_to_all`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyActionToAllValidationChecksValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ReportScopeValidator} [reportScopeValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportScope: async (clientId: string, reportScopeValidator?: ReportScopeValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('createReportScope', 'clientId', clientId)
            const localVarPath = `/api/clients/{clientId}/reports`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportScopeValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the report file from the latest version, either by removing the version file instance or creating a new draft version
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportFile: async (clientId: string, reportId: string, fileId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('deleteReportFile', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('deleteReportFile', 'reportId', reportId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteReportFile', 'fileId', fileId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/files/{fileId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportScope: async (clientId: string, reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('deleteReportScope', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('deleteReportScope', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVersion: async (clientId: string, reportId: string, versionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('deleteVersion', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('deleteVersion', 'reportId', reportId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('deleteVersion', 'versionId', versionId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/versions/{versionId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadChecks: async (clientId: string, reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadChecks', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('downloadChecks', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/downloads/quality-checks.zip`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFullReport: async (clientId: string, reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadFullReport', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('downloadFullReport', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/downloads/rri.zip`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPowerBi: async (clientId: string, reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadPowerBi', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('downloadPowerBi', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/downloads/power-bi.zip`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportFileProcessingLogs: async (clientId: string, reportId: string, fileId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadReportFileProcessingLogs', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('downloadReportFileProcessingLogs', 'reportId', reportId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('downloadReportFileProcessingLogs', 'fileId', fileId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/files/{fileId}/processing-logs.json`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportFileProcessingLogs_1: async (clientId: string, reportId: string, versionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadReportFileProcessingLogs_1', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('downloadReportFileProcessingLogs_1', 'reportId', reportId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('downloadReportFileProcessingLogs_1', 'versionId', versionId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/versions/{versionId}/processing-logs.json`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportProcessingLogs: async (clientId: string, reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadReportProcessingLogs', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('downloadReportProcessingLogs', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/processing-logs.json`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} fileId A ReportFileId in the format \&#39;rf_\&#39; followed by a GUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVisualExcel: async (clientId: string, reportId: string, fileId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('downloadVisualExcel', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('downloadVisualExcel', 'reportId', reportId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('downloadVisualExcel', 'fileId', fileId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/files/{fileId}/visual-excel.xlsx`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {FlagValidationChecksValidator} [flagValidationChecksValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagChecks: async (clientId: string, reportId: string, versionId: string, flagValidationChecksValidator?: FlagValidationChecksValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('flagChecks', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('flagChecks', 'reportId', reportId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('flagChecks', 'versionId', versionId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/versions/{versionId}/reviewed-checks:flag`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(flagValidationChecksValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportScope: async (clientId: string, reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getReportScope', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportScope', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportingEngineProgress: async (clientId: string, reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getReportingEngineProgress', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportingEngineProgress', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/reporting-engine-progress`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The European Banking Authority (EBA) introduced the Capital Requirements Directive IV (CRD IV) aim to ensure financial transparency across the European Economic Area (EEA). The directive covers banks, building societies and investment firms.The EBA has developed two reporting frameworks being:  - COmmon REPorting Standards (COREP)  - FINancial REPorting Standards (FINREP)   Other regulators also have their own reporting frameworks, i.e. - ALMM (Additional Liquidity Monitoring Metrics). - NSFR (Net Stable Funding Ratio) - LCR (Liquidity Coverage Ratio)  This endpoint will help to indicate the user which frameworks they have to include in a particular ReportScope. Additionally, we provide some rules that the report files of the particular framework should follow.  This still acts as a guideline to the end user, not a tight constraint.
         * @summary Returns the map of reporting frameworks and their constraints that are expected to be uploaded to the report scope
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportingFrameworks: async (clientId: string, reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getReportingFrameworks', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportingFrameworks', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/reporting-frameworks`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the report version. Accepts either the UUID or \'latest\'. The latest version is determined by order
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (clientId: string, reportId: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getVersion', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getVersion', 'reportId', reportId)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getVersion', 'version', version)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/versions/{version}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {ValidationCheckReviewStatus} [status] 
         * @param {string} [subject] Reporting subject aka reporting subcategory - subdomain of the selected reporting scope
         * @param {string} [source] Party that has ‘invented’ the data quality check.  i.e. EBA (European Banking Authority) / ECB (European Central Bank) / PwC (PricewaterhouseCoopers)
         * @param {string} [threshold] 
         * @param {string} [code] 
         * @param {string} [expression] 
         * @param {string} [substitution] 
         * @param {string} [search] Executed on several properties
         * @param {ReviewedValidationCheckSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChecks: async (clientId: string, reportId: string, versionId: string, status?: ValidationCheckReviewStatus, subject?: string, source?: string, threshold?: string, code?: string, expression?: string, substitution?: string, search?: string, sort?: ReviewedValidationCheckSort, direction?: Direction, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listChecks', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('listChecks', 'reportId', reportId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('listChecks', 'versionId', versionId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/versions/{versionId}/reviewed-checks`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (subject !== undefined) {
                localVarQueryParameter['Subject'] = subject;
            }

            if (source !== undefined) {
                localVarQueryParameter['Source'] = source;
            }

            if (threshold !== undefined) {
                localVarQueryParameter['Threshold'] = threshold;
            }

            if (code !== undefined) {
                localVarQueryParameter['Code'] = code;
            }

            if (expression !== undefined) {
                localVarQueryParameter['Expression'] = expression;
            }

            if (substitution !== undefined) {
                localVarQueryParameter['Substitution'] = substitution;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['Sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists all the available download options. Provides the readiness information per download type
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDownloads: async (clientId: string, reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listDownloads', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('listDownloads', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/downloads`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportFiles: async (clientId: string, reportId: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listReportFiles', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('listReportFiles', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/files`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Front-end specific endpoint to indicate users the progress of the file uploads
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportFilesUploadProgresses: async (clientId: string, reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listReportFilesUploadProgresses', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('listReportFilesUploadProgresses', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/files/upload-progresses`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {AuditLogSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportLogs: async (clientId: string, reportId: string, sort?: AuditLogSort, direction?: Direction, limit?: number, offset?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listReportLogs', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('listReportLogs', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/logs`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['Sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ScopeType} [scope] 
         * @param {ReportScopeSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportScopes: async (clientId: string, scope?: ScopeType, sort?: ReportScopeSort, direction?: Direction, limit?: number, offset?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listReportScopes', 'clientId', clientId)
            const localVarPath = `/api/clients/{clientId}/reports`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (sort !== undefined) {
                localVarQueryParameter['Sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Operational metadata regarding the version files
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVersionFilesMetadata: async (clientId: string, reportId: string, versionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listVersionFilesMetadata', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('listVersionFilesMetadata', 'reportId', reportId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('listVersionFilesMetadata', 'versionId', versionId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/versions/{versionId}/files-metadata`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Front-end specific endpoint
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVersionFilesReadyForProcessing: async (clientId: string, reportId: string, versionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listVersionFilesReadyForProcessing', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('listVersionFilesReadyForProcessing', 'reportId', reportId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('listVersionFilesReadyForProcessing', 'versionId', versionId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/versions/{versionId}/files-ready-for-processing`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all the visual excels available for the specific version
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVersionVisualExcels: async (clientId: string, reportId: string, versionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listVersionVisualExcels', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('listVersionVisualExcels', 'reportId', reportId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('listVersionVisualExcels', 'versionId', versionId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/versions/{versionId}/visual-excels`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {VersionSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVersions: async (clientId: string, reportId: string, sort?: VersionSort, direction?: Direction, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listVersions', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('listVersions', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/versions`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['Sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockReport: async (clientId: string, reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('lockReport', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('lockReport', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}:lock`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {MarkValidationChecksBelowThresholdValidator} [markValidationChecksBelowThresholdValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markChecksBelowThreshold: async (clientId: string, reportId: string, versionId: string, markValidationChecksBelowThresholdValidator?: MarkValidationChecksBelowThresholdValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('markChecksBelowThreshold', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('markChecksBelowThreshold', 'reportId', reportId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('markChecksBelowThreshold', 'versionId', versionId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/versions/{versionId}/reviewed-checks:mark_below_threshold`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markValidationChecksBelowThresholdValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set the status and user-created properties to their defaults
         * @summary Resets the checks to the default state
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {MarkValidationChecksPendingValidator} [markValidationChecksPendingValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markChecksPending: async (clientId: string, reportId: string, versionId: string, markValidationChecksPendingValidator?: MarkValidationChecksPendingValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('markChecksPending', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('markChecksPending', 'reportId', reportId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('markChecksPending', 'versionId', versionId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/versions/{versionId}/reviewed-checks:mark_pending`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markValidationChecksPendingValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startReportWorkflow: async (clientId: string, reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('startReportWorkflow', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('startReportWorkflow', 'reportId', reportId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}:start_workflow`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {File} file 
         * @param {string} [framework] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadReportFile: async (clientId: string, reportId: string, file: File, framework?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('uploadReportFile', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('uploadReportFile', 'reportId', reportId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadReportFile', 'file', file)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/files`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (framework !== undefined) {
                localVarQueryParameter['framework'] = framework;
            }


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {WaiveValidationChecksValidator} [waiveValidationChecksValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        waiveChecks: async (clientId: string, reportId: string, versionId: string, waiveValidationChecksValidator?: WaiveValidationChecksValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('waiveChecks', 'clientId', clientId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('waiveChecks', 'reportId', reportId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('waiveChecks', 'versionId', versionId)
            const localVarPath = `/api/clients/{clientId}/reports/{reportId}/versions/{versionId}/reviewed-checks:waive`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(waiveValidationChecksValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {ApplyActionToAllValidationChecksValidator} [applyActionToAllValidationChecksValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyActionToAllChecks(clientId: string, reportId: string, versionId: string, applyActionToAllValidationChecksValidator?: ApplyActionToAllValidationChecksValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyActionToAllChecks(clientId, reportId, versionId, applyActionToAllValidationChecksValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.applyActionToAllChecks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ReportScopeValidator} [reportScopeValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReportScope(clientId: string, reportScopeValidator?: ReportScopeValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportScopeRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReportScope(clientId, reportScopeValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.createReportScope']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Removes the report file from the latest version, either by removing the version file instance or creating a new draft version
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportFile(clientId: string, reportId: string, fileId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportFile(clientId, reportId, fileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.deleteReportFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportScope(clientId: string, reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportScope(clientId, reportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.deleteReportScope']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVersion(clientId: string, reportId: string, versionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVersion(clientId, reportId, versionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.deleteVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadChecks(clientId: string, reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadChecks(clientId, reportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.downloadChecks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFullReport(clientId: string, reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFullReport(clientId, reportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.downloadFullReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPowerBi(clientId: string, reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPowerBi(clientId, reportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.downloadPowerBi']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReportFileProcessingLogs(clientId: string, reportId: string, fileId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReportFileProcessingLogs(clientId, reportId, fileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.downloadReportFileProcessingLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReportFileProcessingLogs_1(clientId: string, reportId: string, versionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReportFileProcessingLogs_1(clientId, reportId, versionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.downloadReportFileProcessingLogs_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReportProcessingLogs(clientId: string, reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReportProcessingLogs(clientId, reportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.downloadReportProcessingLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} fileId A ReportFileId in the format \&#39;rf_\&#39; followed by a GUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadVisualExcel(clientId: string, reportId: string, fileId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadVisualExcel(clientId, reportId, fileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.downloadVisualExcel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {FlagValidationChecksValidator} [flagValidationChecksValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flagChecks(clientId: string, reportId: string, versionId: string, flagValidationChecksValidator?: FlagValidationChecksValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flagChecks(clientId, reportId, versionId, flagValidationChecksValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.flagChecks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportScope(clientId: string, reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportScopeRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportScope(clientId, reportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.getReportScope']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportingEngineProgress(clientId: string, reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportingEngineProgressRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportingEngineProgress(clientId, reportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.getReportingEngineProgress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The European Banking Authority (EBA) introduced the Capital Requirements Directive IV (CRD IV) aim to ensure financial transparency across the European Economic Area (EEA). The directive covers banks, building societies and investment firms.The EBA has developed two reporting frameworks being:  - COmmon REPorting Standards (COREP)  - FINancial REPorting Standards (FINREP)   Other regulators also have their own reporting frameworks, i.e. - ALMM (Additional Liquidity Monitoring Metrics). - NSFR (Net Stable Funding Ratio) - LCR (Liquidity Coverage Ratio)  This endpoint will help to indicate the user which frameworks they have to include in a particular ReportScope. Additionally, we provide some rules that the report files of the particular framework should follow.  This still acts as a guideline to the end user, not a tight constraint.
         * @summary Returns the map of reporting frameworks and their constraints that are expected to be uploaded to the report scope
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportingFrameworks(clientId: string, reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportingFrameworksRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportingFrameworks(clientId, reportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.getReportingFrameworks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the report version. Accepts either the UUID or \'latest\'. The latest version is determined by order
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(clientId: string, reportId: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(clientId, reportId, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.getVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {ValidationCheckReviewStatus} [status] 
         * @param {string} [subject] Reporting subject aka reporting subcategory - subdomain of the selected reporting scope
         * @param {string} [source] Party that has ‘invented’ the data quality check.  i.e. EBA (European Banking Authority) / ECB (European Central Bank) / PwC (PricewaterhouseCoopers)
         * @param {string} [threshold] 
         * @param {string} [code] 
         * @param {string} [expression] 
         * @param {string} [substitution] 
         * @param {string} [search] Executed on several properties
         * @param {ReviewedValidationCheckSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listChecks(clientId: string, reportId: string, versionId: string, status?: ValidationCheckReviewStatus, subject?: string, source?: string, threshold?: string, code?: string, expression?: string, substitution?: string, search?: string, sort?: ReviewedValidationCheckSort, direction?: Direction, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewedValidationCheckRepresentationFilteredListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listChecks(clientId, reportId, versionId, status, subject, source, threshold, code, expression, substitution, search, sort, direction, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.listChecks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Lists all the available download options. Provides the readiness information per download type
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDownloads(clientId: string, reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DownloadRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDownloads(clientId, reportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.listDownloads']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReportFiles(clientId: string, reportId: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportFileRepresentationListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReportFiles(clientId, reportId, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.listReportFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Front-end specific endpoint to indicate users the progress of the file uploads
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReportFilesUploadProgresses(clientId: string, reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportFileUploadProgressRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReportFilesUploadProgresses(clientId, reportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.listReportFilesUploadProgresses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {AuditLogSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReportLogs(clientId: string, reportId: string, sort?: AuditLogSort, direction?: Direction, limit?: number, offset?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditLogRepresentationListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReportLogs(clientId, reportId, sort, direction, limit, offset, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.listReportLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {ScopeType} [scope] 
         * @param {ReportScopeSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReportScopes(clientId: string, scope?: ScopeType, sort?: ReportScopeSort, direction?: Direction, limit?: number, offset?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportScopeRepresentationListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReportScopes(clientId, scope, sort, direction, limit, offset, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.listReportScopes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Operational metadata regarding the version files
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVersionFilesMetadata(clientId: string, reportId: string, versionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VersionFileMetadataRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVersionFilesMetadata(clientId, reportId, versionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.listVersionFilesMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Front-end specific endpoint
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVersionFilesReadyForProcessing(clientId: string, reportId: string, versionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VersionFileRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVersionFilesReadyForProcessing(clientId, reportId, versionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.listVersionFilesReadyForProcessing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all the visual excels available for the specific version
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVersionVisualExcels(clientId: string, reportId: string, versionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VersionVisualExcelRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVersionVisualExcels(clientId, reportId, versionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.listVersionVisualExcels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {VersionSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVersions(clientId: string, reportId: string, sort?: VersionSort, direction?: Direction, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionRepresentationListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVersions(clientId, reportId, sort, direction, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.listVersions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockReport(clientId: string, reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockReport(clientId, reportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.lockReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {MarkValidationChecksBelowThresholdValidator} [markValidationChecksBelowThresholdValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markChecksBelowThreshold(clientId: string, reportId: string, versionId: string, markValidationChecksBelowThresholdValidator?: MarkValidationChecksBelowThresholdValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markChecksBelowThreshold(clientId, reportId, versionId, markValidationChecksBelowThresholdValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.markChecksBelowThreshold']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Set the status and user-created properties to their defaults
         * @summary Resets the checks to the default state
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {MarkValidationChecksPendingValidator} [markValidationChecksPendingValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markChecksPending(clientId: string, reportId: string, versionId: string, markValidationChecksPendingValidator?: MarkValidationChecksPendingValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markChecksPending(clientId, reportId, versionId, markValidationChecksPendingValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.markChecksPending']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startReportWorkflow(clientId: string, reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startReportWorkflow(clientId, reportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.startReportWorkflow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {File} file 
         * @param {string} [framework] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadReportFile(clientId: string, reportId: string, file: File, framework?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadReportFile200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadReportFile(clientId, reportId, file, framework, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.uploadReportFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} reportId 
         * @param {string} versionId 
         * @param {WaiveValidationChecksValidator} [waiveValidationChecksValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async waiveChecks(clientId: string, reportId: string, versionId: string, waiveValidationChecksValidator?: WaiveValidationChecksValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.waiveChecks(clientId, reportId, versionId, waiveValidationChecksValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.waiveChecks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @param {ReportsApiApplyActionToAllChecksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyActionToAllChecks(requestParameters: ReportsApiApplyActionToAllChecksRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.applyActionToAllChecks(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, requestParameters.applyActionToAllValidationChecksValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiCreateReportScopeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportScope(requestParameters: ReportsApiCreateReportScopeRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReportScopeRepresentation> {
            return localVarFp.createReportScope(requestParameters.clientId, requestParameters.reportScopeValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the report file from the latest version, either by removing the version file instance or creating a new draft version
         * @param {ReportsApiDeleteReportFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportFile(requestParameters: ReportsApiDeleteReportFileRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteReportFile(requestParameters.clientId, requestParameters.reportId, requestParameters.fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiDeleteReportScopeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportScope(requestParameters: ReportsApiDeleteReportScopeRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteReportScope(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiDeleteVersionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVersion(requestParameters: ReportsApiDeleteVersionRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteVersion(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiDownloadChecksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadChecks(requestParameters: ReportsApiDownloadChecksRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadChecks(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiDownloadFullReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFullReport(requestParameters: ReportsApiDownloadFullReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadFullReport(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiDownloadPowerBiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPowerBi(requestParameters: ReportsApiDownloadPowerBiRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadPowerBi(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiDownloadReportFileProcessingLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportFileProcessingLogs(requestParameters: ReportsApiDownloadReportFileProcessingLogsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadReportFileProcessingLogs(requestParameters.clientId, requestParameters.reportId, requestParameters.fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiDownloadReportFileProcessingLogs0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportFileProcessingLogs_1(requestParameters: ReportsApiDownloadReportFileProcessingLogs0Request, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadReportFileProcessingLogs_1(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiDownloadReportProcessingLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportProcessingLogs(requestParameters: ReportsApiDownloadReportProcessingLogsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadReportProcessingLogs(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiDownloadVisualExcelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVisualExcel(requestParameters: ReportsApiDownloadVisualExcelRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadVisualExcel(requestParameters.clientId, requestParameters.reportId, requestParameters.fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiFlagChecksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagChecks(requestParameters: ReportsApiFlagChecksRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.flagChecks(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, requestParameters.flagValidationChecksValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiGetReportScopeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportScope(requestParameters: ReportsApiGetReportScopeRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReportScopeRepresentation> {
            return localVarFp.getReportScope(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiGetReportingEngineProgressRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportingEngineProgress(requestParameters: ReportsApiGetReportingEngineProgressRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReportingEngineProgressRepresentation> {
            return localVarFp.getReportingEngineProgress(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * The European Banking Authority (EBA) introduced the Capital Requirements Directive IV (CRD IV) aim to ensure financial transparency across the European Economic Area (EEA). The directive covers banks, building societies and investment firms.The EBA has developed two reporting frameworks being:  - COmmon REPorting Standards (COREP)  - FINancial REPorting Standards (FINREP)   Other regulators also have their own reporting frameworks, i.e. - ALMM (Additional Liquidity Monitoring Metrics). - NSFR (Net Stable Funding Ratio) - LCR (Liquidity Coverage Ratio)  This endpoint will help to indicate the user which frameworks they have to include in a particular ReportScope. Additionally, we provide some rules that the report files of the particular framework should follow.  This still acts as a guideline to the end user, not a tight constraint.
         * @summary Returns the map of reporting frameworks and their constraints that are expected to be uploaded to the report scope
         * @param {ReportsApiGetReportingFrameworksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportingFrameworks(requestParameters: ReportsApiGetReportingFrameworksRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReportingFrameworksRepresentation> {
            return localVarFp.getReportingFrameworks(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the report version. Accepts either the UUID or \'latest\'. The latest version is determined by order
         * @param {ReportsApiGetVersionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(requestParameters: ReportsApiGetVersionRequest, options?: RawAxiosRequestConfig): AxiosPromise<VersionRepresentation> {
            return localVarFp.getVersion(requestParameters.clientId, requestParameters.reportId, requestParameters.version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiListChecksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChecks(requestParameters: ReportsApiListChecksRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReviewedValidationCheckRepresentationFilteredListRepresentation> {
            return localVarFp.listChecks(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, requestParameters.status, requestParameters.subject, requestParameters.source, requestParameters.threshold, requestParameters.code, requestParameters.expression, requestParameters.substitution, requestParameters.search, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists all the available download options. Provides the readiness information per download type
         * @param {ReportsApiListDownloadsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDownloads(requestParameters: ReportsApiListDownloadsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DownloadRepresentation>> {
            return localVarFp.listDownloads(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiListReportFilesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportFiles(requestParameters: ReportsApiListReportFilesRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReportFileRepresentationListRepresentation> {
            return localVarFp.listReportFiles(requestParameters.clientId, requestParameters.reportId, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Front-end specific endpoint to indicate users the progress of the file uploads
         * @param {ReportsApiListReportFilesUploadProgressesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportFilesUploadProgresses(requestParameters: ReportsApiListReportFilesUploadProgressesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ReportFileUploadProgressRepresentation>> {
            return localVarFp.listReportFilesUploadProgresses(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiListReportLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportLogs(requestParameters: ReportsApiListReportLogsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AuditLogRepresentationListRepresentation> {
            return localVarFp.listReportLogs(requestParameters.clientId, requestParameters.reportId, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiListReportScopesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportScopes(requestParameters: ReportsApiListReportScopesRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReportScopeRepresentationListRepresentation> {
            return localVarFp.listReportScopes(requestParameters.clientId, requestParameters.scope, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Operational metadata regarding the version files
         * @param {ReportsApiListVersionFilesMetadataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVersionFilesMetadata(requestParameters: ReportsApiListVersionFilesMetadataRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<VersionFileMetadataRepresentation>> {
            return localVarFp.listVersionFilesMetadata(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Front-end specific endpoint
         * @param {ReportsApiListVersionFilesReadyForProcessingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVersionFilesReadyForProcessing(requestParameters: ReportsApiListVersionFilesReadyForProcessingRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<VersionFileRepresentation>> {
            return localVarFp.listVersionFilesReadyForProcessing(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all the visual excels available for the specific version
         * @param {ReportsApiListVersionVisualExcelsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVersionVisualExcels(requestParameters: ReportsApiListVersionVisualExcelsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<VersionVisualExcelRepresentation>> {
            return localVarFp.listVersionVisualExcels(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiListVersionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVersions(requestParameters: ReportsApiListVersionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<VersionRepresentationListRepresentation> {
            return localVarFp.listVersions(requestParameters.clientId, requestParameters.reportId, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiLockReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockReport(requestParameters: ReportsApiLockReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.lockReport(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiMarkChecksBelowThresholdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markChecksBelowThreshold(requestParameters: ReportsApiMarkChecksBelowThresholdRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.markChecksBelowThreshold(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, requestParameters.markValidationChecksBelowThresholdValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * Set the status and user-created properties to their defaults
         * @summary Resets the checks to the default state
         * @param {ReportsApiMarkChecksPendingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markChecksPending(requestParameters: ReportsApiMarkChecksPendingRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.markChecksPending(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, requestParameters.markValidationChecksPendingValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiStartReportWorkflowRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startReportWorkflow(requestParameters: ReportsApiStartReportWorkflowRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.startReportWorkflow(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiUploadReportFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadReportFile(requestParameters: ReportsApiUploadReportFileRequest, options?: RawAxiosRequestConfig): AxiosPromise<UploadReportFile200Response> {
            return localVarFp.uploadReportFile(requestParameters.clientId, requestParameters.reportId, requestParameters.file, requestParameters.framework, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiWaiveChecksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        waiveChecks(requestParameters: ReportsApiWaiveChecksRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.waiveChecks(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, requestParameters.waiveValidationChecksValidator, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for applyActionToAllChecks operation in ReportsApi.
 * @export
 * @interface ReportsApiApplyActionToAllChecksRequest
 */
export interface ReportsApiApplyActionToAllChecksRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiApplyActionToAllChecks
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiApplyActionToAllChecks
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiApplyActionToAllChecks
     */
    readonly versionId: string

    /**
     * 
     * @type {ApplyActionToAllValidationChecksValidator}
     * @memberof ReportsApiApplyActionToAllChecks
     */
    readonly applyActionToAllValidationChecksValidator?: ApplyActionToAllValidationChecksValidator
}

/**
 * Request parameters for createReportScope operation in ReportsApi.
 * @export
 * @interface ReportsApiCreateReportScopeRequest
 */
export interface ReportsApiCreateReportScopeRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiCreateReportScope
     */
    readonly clientId: string

    /**
     * 
     * @type {ReportScopeValidator}
     * @memberof ReportsApiCreateReportScope
     */
    readonly reportScopeValidator?: ReportScopeValidator
}

/**
 * Request parameters for deleteReportFile operation in ReportsApi.
 * @export
 * @interface ReportsApiDeleteReportFileRequest
 */
export interface ReportsApiDeleteReportFileRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiDeleteReportFile
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDeleteReportFile
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDeleteReportFile
     */
    readonly fileId: string
}

/**
 * Request parameters for deleteReportScope operation in ReportsApi.
 * @export
 * @interface ReportsApiDeleteReportScopeRequest
 */
export interface ReportsApiDeleteReportScopeRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiDeleteReportScope
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDeleteReportScope
     */
    readonly reportId: string
}

/**
 * Request parameters for deleteVersion operation in ReportsApi.
 * @export
 * @interface ReportsApiDeleteVersionRequest
 */
export interface ReportsApiDeleteVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiDeleteVersion
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDeleteVersion
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDeleteVersion
     */
    readonly versionId: string
}

/**
 * Request parameters for downloadChecks operation in ReportsApi.
 * @export
 * @interface ReportsApiDownloadChecksRequest
 */
export interface ReportsApiDownloadChecksRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadChecks
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadChecks
     */
    readonly reportId: string
}

/**
 * Request parameters for downloadFullReport operation in ReportsApi.
 * @export
 * @interface ReportsApiDownloadFullReportRequest
 */
export interface ReportsApiDownloadFullReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadFullReport
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadFullReport
     */
    readonly reportId: string
}

/**
 * Request parameters for downloadPowerBi operation in ReportsApi.
 * @export
 * @interface ReportsApiDownloadPowerBiRequest
 */
export interface ReportsApiDownloadPowerBiRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadPowerBi
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadPowerBi
     */
    readonly reportId: string
}

/**
 * Request parameters for downloadReportFileProcessingLogs operation in ReportsApi.
 * @export
 * @interface ReportsApiDownloadReportFileProcessingLogsRequest
 */
export interface ReportsApiDownloadReportFileProcessingLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadReportFileProcessingLogs
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadReportFileProcessingLogs
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadReportFileProcessingLogs
     */
    readonly fileId: string
}

/**
 * Request parameters for downloadReportFileProcessingLogs_1 operation in ReportsApi.
 * @export
 * @interface ReportsApiDownloadReportFileProcessingLogs0Request
 */
export interface ReportsApiDownloadReportFileProcessingLogs0Request {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadReportFileProcessingLogs0
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadReportFileProcessingLogs0
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadReportFileProcessingLogs0
     */
    readonly versionId: string
}

/**
 * Request parameters for downloadReportProcessingLogs operation in ReportsApi.
 * @export
 * @interface ReportsApiDownloadReportProcessingLogsRequest
 */
export interface ReportsApiDownloadReportProcessingLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadReportProcessingLogs
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadReportProcessingLogs
     */
    readonly reportId: string
}

/**
 * Request parameters for downloadVisualExcel operation in ReportsApi.
 * @export
 * @interface ReportsApiDownloadVisualExcelRequest
 */
export interface ReportsApiDownloadVisualExcelRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadVisualExcel
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDownloadVisualExcel
     */
    readonly reportId: string

    /**
     * A ReportFileId in the format \&#39;rf_\&#39; followed by a GUID
     * @type {string}
     * @memberof ReportsApiDownloadVisualExcel
     */
    readonly fileId: string
}

/**
 * Request parameters for flagChecks operation in ReportsApi.
 * @export
 * @interface ReportsApiFlagChecksRequest
 */
export interface ReportsApiFlagChecksRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiFlagChecks
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiFlagChecks
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiFlagChecks
     */
    readonly versionId: string

    /**
     * 
     * @type {FlagValidationChecksValidator}
     * @memberof ReportsApiFlagChecks
     */
    readonly flagValidationChecksValidator?: FlagValidationChecksValidator
}

/**
 * Request parameters for getReportScope operation in ReportsApi.
 * @export
 * @interface ReportsApiGetReportScopeRequest
 */
export interface ReportsApiGetReportScopeRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiGetReportScope
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiGetReportScope
     */
    readonly reportId: string
}

/**
 * Request parameters for getReportingEngineProgress operation in ReportsApi.
 * @export
 * @interface ReportsApiGetReportingEngineProgressRequest
 */
export interface ReportsApiGetReportingEngineProgressRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiGetReportingEngineProgress
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiGetReportingEngineProgress
     */
    readonly reportId: string
}

/**
 * Request parameters for getReportingFrameworks operation in ReportsApi.
 * @export
 * @interface ReportsApiGetReportingFrameworksRequest
 */
export interface ReportsApiGetReportingFrameworksRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiGetReportingFrameworks
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiGetReportingFrameworks
     */
    readonly reportId: string
}

/**
 * Request parameters for getVersion operation in ReportsApi.
 * @export
 * @interface ReportsApiGetVersionRequest
 */
export interface ReportsApiGetVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiGetVersion
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiGetVersion
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiGetVersion
     */
    readonly version: string
}

/**
 * Request parameters for listChecks operation in ReportsApi.
 * @export
 * @interface ReportsApiListChecksRequest
 */
export interface ReportsApiListChecksRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiListChecks
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListChecks
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListChecks
     */
    readonly versionId: string

    /**
     * 
     * @type {ValidationCheckReviewStatus}
     * @memberof ReportsApiListChecks
     */
    readonly status?: ValidationCheckReviewStatus

    /**
     * Reporting subject aka reporting subcategory - subdomain of the selected reporting scope
     * @type {string}
     * @memberof ReportsApiListChecks
     */
    readonly subject?: string

    /**
     * Party that has ‘invented’ the data quality check.  i.e. EBA (European Banking Authority) / ECB (European Central Bank) / PwC (PricewaterhouseCoopers)
     * @type {string}
     * @memberof ReportsApiListChecks
     */
    readonly source?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListChecks
     */
    readonly threshold?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListChecks
     */
    readonly code?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListChecks
     */
    readonly expression?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListChecks
     */
    readonly substitution?: string

    /**
     * Executed on several properties
     * @type {string}
     * @memberof ReportsApiListChecks
     */
    readonly search?: string

    /**
     * 
     * @type {ReviewedValidationCheckSort}
     * @memberof ReportsApiListChecks
     */
    readonly sort?: ReviewedValidationCheckSort

    /**
     * ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
     * @type {Direction}
     * @memberof ReportsApiListChecks
     */
    readonly direction?: Direction

    /**
     * 
     * @type {number}
     * @memberof ReportsApiListChecks
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiListChecks
     */
    readonly offset?: number
}

/**
 * Request parameters for listDownloads operation in ReportsApi.
 * @export
 * @interface ReportsApiListDownloadsRequest
 */
export interface ReportsApiListDownloadsRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiListDownloads
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListDownloads
     */
    readonly reportId: string
}

/**
 * Request parameters for listReportFiles operation in ReportsApi.
 * @export
 * @interface ReportsApiListReportFilesRequest
 */
export interface ReportsApiListReportFilesRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiListReportFiles
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListReportFiles
     */
    readonly reportId: string

    /**
     * 
     * @type {number}
     * @memberof ReportsApiListReportFiles
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiListReportFiles
     */
    readonly offset?: number
}

/**
 * Request parameters for listReportFilesUploadProgresses operation in ReportsApi.
 * @export
 * @interface ReportsApiListReportFilesUploadProgressesRequest
 */
export interface ReportsApiListReportFilesUploadProgressesRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiListReportFilesUploadProgresses
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListReportFilesUploadProgresses
     */
    readonly reportId: string
}

/**
 * Request parameters for listReportLogs operation in ReportsApi.
 * @export
 * @interface ReportsApiListReportLogsRequest
 */
export interface ReportsApiListReportLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiListReportLogs
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListReportLogs
     */
    readonly reportId: string

    /**
     * 
     * @type {AuditLogSort}
     * @memberof ReportsApiListReportLogs
     */
    readonly sort?: AuditLogSort

    /**
     * ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
     * @type {Direction}
     * @memberof ReportsApiListReportLogs
     */
    readonly direction?: Direction

    /**
     * 
     * @type {number}
     * @memberof ReportsApiListReportLogs
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiListReportLogs
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListReportLogs
     */
    readonly search?: string
}

/**
 * Request parameters for listReportScopes operation in ReportsApi.
 * @export
 * @interface ReportsApiListReportScopesRequest
 */
export interface ReportsApiListReportScopesRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiListReportScopes
     */
    readonly clientId: string

    /**
     * 
     * @type {ScopeType}
     * @memberof ReportsApiListReportScopes
     */
    readonly scope?: ScopeType

    /**
     * 
     * @type {ReportScopeSort}
     * @memberof ReportsApiListReportScopes
     */
    readonly sort?: ReportScopeSort

    /**
     * ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
     * @type {Direction}
     * @memberof ReportsApiListReportScopes
     */
    readonly direction?: Direction

    /**
     * 
     * @type {number}
     * @memberof ReportsApiListReportScopes
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiListReportScopes
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListReportScopes
     */
    readonly search?: string
}

/**
 * Request parameters for listVersionFilesMetadata operation in ReportsApi.
 * @export
 * @interface ReportsApiListVersionFilesMetadataRequest
 */
export interface ReportsApiListVersionFilesMetadataRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiListVersionFilesMetadata
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListVersionFilesMetadata
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListVersionFilesMetadata
     */
    readonly versionId: string
}

/**
 * Request parameters for listVersionFilesReadyForProcessing operation in ReportsApi.
 * @export
 * @interface ReportsApiListVersionFilesReadyForProcessingRequest
 */
export interface ReportsApiListVersionFilesReadyForProcessingRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiListVersionFilesReadyForProcessing
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListVersionFilesReadyForProcessing
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListVersionFilesReadyForProcessing
     */
    readonly versionId: string
}

/**
 * Request parameters for listVersionVisualExcels operation in ReportsApi.
 * @export
 * @interface ReportsApiListVersionVisualExcelsRequest
 */
export interface ReportsApiListVersionVisualExcelsRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiListVersionVisualExcels
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListVersionVisualExcels
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListVersionVisualExcels
     */
    readonly versionId: string
}

/**
 * Request parameters for listVersions operation in ReportsApi.
 * @export
 * @interface ReportsApiListVersionsRequest
 */
export interface ReportsApiListVersionsRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiListVersions
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiListVersions
     */
    readonly reportId: string

    /**
     * 
     * @type {VersionSort}
     * @memberof ReportsApiListVersions
     */
    readonly sort?: VersionSort

    /**
     * ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
     * @type {Direction}
     * @memberof ReportsApiListVersions
     */
    readonly direction?: Direction

    /**
     * 
     * @type {number}
     * @memberof ReportsApiListVersions
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiListVersions
     */
    readonly offset?: number
}

/**
 * Request parameters for lockReport operation in ReportsApi.
 * @export
 * @interface ReportsApiLockReportRequest
 */
export interface ReportsApiLockReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiLockReport
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiLockReport
     */
    readonly reportId: string
}

/**
 * Request parameters for markChecksBelowThreshold operation in ReportsApi.
 * @export
 * @interface ReportsApiMarkChecksBelowThresholdRequest
 */
export interface ReportsApiMarkChecksBelowThresholdRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiMarkChecksBelowThreshold
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiMarkChecksBelowThreshold
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiMarkChecksBelowThreshold
     */
    readonly versionId: string

    /**
     * 
     * @type {MarkValidationChecksBelowThresholdValidator}
     * @memberof ReportsApiMarkChecksBelowThreshold
     */
    readonly markValidationChecksBelowThresholdValidator?: MarkValidationChecksBelowThresholdValidator
}

/**
 * Request parameters for markChecksPending operation in ReportsApi.
 * @export
 * @interface ReportsApiMarkChecksPendingRequest
 */
export interface ReportsApiMarkChecksPendingRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiMarkChecksPending
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiMarkChecksPending
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiMarkChecksPending
     */
    readonly versionId: string

    /**
     * 
     * @type {MarkValidationChecksPendingValidator}
     * @memberof ReportsApiMarkChecksPending
     */
    readonly markValidationChecksPendingValidator?: MarkValidationChecksPendingValidator
}

/**
 * Request parameters for startReportWorkflow operation in ReportsApi.
 * @export
 * @interface ReportsApiStartReportWorkflowRequest
 */
export interface ReportsApiStartReportWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiStartReportWorkflow
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiStartReportWorkflow
     */
    readonly reportId: string
}

/**
 * Request parameters for uploadReportFile operation in ReportsApi.
 * @export
 * @interface ReportsApiUploadReportFileRequest
 */
export interface ReportsApiUploadReportFileRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiUploadReportFile
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiUploadReportFile
     */
    readonly reportId: string

    /**
     * 
     * @type {File}
     * @memberof ReportsApiUploadReportFile
     */
    readonly file: File

    /**
     * 
     * @type {string}
     * @memberof ReportsApiUploadReportFile
     */
    readonly framework?: string
}

/**
 * Request parameters for waiveChecks operation in ReportsApi.
 * @export
 * @interface ReportsApiWaiveChecksRequest
 */
export interface ReportsApiWaiveChecksRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiWaiveChecks
     */
    readonly clientId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiWaiveChecks
     */
    readonly reportId: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiWaiveChecks
     */
    readonly versionId: string

    /**
     * 
     * @type {WaiveValidationChecksValidator}
     * @memberof ReportsApiWaiveChecks
     */
    readonly waiveValidationChecksValidator?: WaiveValidationChecksValidator
}

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @param {ReportsApiApplyActionToAllChecksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public applyActionToAllChecks(requestParameters: ReportsApiApplyActionToAllChecksRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).applyActionToAllChecks(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, requestParameters.applyActionToAllValidationChecksValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiCreateReportScopeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public createReportScope(requestParameters: ReportsApiCreateReportScopeRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).createReportScope(requestParameters.clientId, requestParameters.reportScopeValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the report file from the latest version, either by removing the version file instance or creating a new draft version
     * @param {ReportsApiDeleteReportFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteReportFile(requestParameters: ReportsApiDeleteReportFileRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deleteReportFile(requestParameters.clientId, requestParameters.reportId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiDeleteReportScopeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteReportScope(requestParameters: ReportsApiDeleteReportScopeRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deleteReportScope(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiDeleteVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteVersion(requestParameters: ReportsApiDeleteVersionRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deleteVersion(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiDownloadChecksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public downloadChecks(requestParameters: ReportsApiDownloadChecksRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).downloadChecks(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiDownloadFullReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public downloadFullReport(requestParameters: ReportsApiDownloadFullReportRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).downloadFullReport(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiDownloadPowerBiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public downloadPowerBi(requestParameters: ReportsApiDownloadPowerBiRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).downloadPowerBi(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiDownloadReportFileProcessingLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public downloadReportFileProcessingLogs(requestParameters: ReportsApiDownloadReportFileProcessingLogsRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).downloadReportFileProcessingLogs(requestParameters.clientId, requestParameters.reportId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiDownloadReportFileProcessingLogs0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public downloadReportFileProcessingLogs_1(requestParameters: ReportsApiDownloadReportFileProcessingLogs0Request, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).downloadReportFileProcessingLogs_1(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiDownloadReportProcessingLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public downloadReportProcessingLogs(requestParameters: ReportsApiDownloadReportProcessingLogsRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).downloadReportProcessingLogs(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiDownloadVisualExcelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public downloadVisualExcel(requestParameters: ReportsApiDownloadVisualExcelRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).downloadVisualExcel(requestParameters.clientId, requestParameters.reportId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiFlagChecksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public flagChecks(requestParameters: ReportsApiFlagChecksRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).flagChecks(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, requestParameters.flagValidationChecksValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiGetReportScopeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportScope(requestParameters: ReportsApiGetReportScopeRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReportScope(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiGetReportingEngineProgressRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportingEngineProgress(requestParameters: ReportsApiGetReportingEngineProgressRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReportingEngineProgress(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The European Banking Authority (EBA) introduced the Capital Requirements Directive IV (CRD IV) aim to ensure financial transparency across the European Economic Area (EEA). The directive covers banks, building societies and investment firms.The EBA has developed two reporting frameworks being:  - COmmon REPorting Standards (COREP)  - FINancial REPorting Standards (FINREP)   Other regulators also have their own reporting frameworks, i.e. - ALMM (Additional Liquidity Monitoring Metrics). - NSFR (Net Stable Funding Ratio) - LCR (Liquidity Coverage Ratio)  This endpoint will help to indicate the user which frameworks they have to include in a particular ReportScope. Additionally, we provide some rules that the report files of the particular framework should follow.  This still acts as a guideline to the end user, not a tight constraint.
     * @summary Returns the map of reporting frameworks and their constraints that are expected to be uploaded to the report scope
     * @param {ReportsApiGetReportingFrameworksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportingFrameworks(requestParameters: ReportsApiGetReportingFrameworksRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReportingFrameworks(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the report version. Accepts either the UUID or \'latest\'. The latest version is determined by order
     * @param {ReportsApiGetVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getVersion(requestParameters: ReportsApiGetVersionRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getVersion(requestParameters.clientId, requestParameters.reportId, requestParameters.version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiListChecksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listChecks(requestParameters: ReportsApiListChecksRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).listChecks(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, requestParameters.status, requestParameters.subject, requestParameters.source, requestParameters.threshold, requestParameters.code, requestParameters.expression, requestParameters.substitution, requestParameters.search, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists all the available download options. Provides the readiness information per download type
     * @param {ReportsApiListDownloadsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listDownloads(requestParameters: ReportsApiListDownloadsRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).listDownloads(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiListReportFilesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listReportFiles(requestParameters: ReportsApiListReportFilesRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).listReportFiles(requestParameters.clientId, requestParameters.reportId, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Front-end specific endpoint to indicate users the progress of the file uploads
     * @param {ReportsApiListReportFilesUploadProgressesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listReportFilesUploadProgresses(requestParameters: ReportsApiListReportFilesUploadProgressesRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).listReportFilesUploadProgresses(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiListReportLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listReportLogs(requestParameters: ReportsApiListReportLogsRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).listReportLogs(requestParameters.clientId, requestParameters.reportId, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiListReportScopesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listReportScopes(requestParameters: ReportsApiListReportScopesRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).listReportScopes(requestParameters.clientId, requestParameters.scope, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Operational metadata regarding the version files
     * @param {ReportsApiListVersionFilesMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listVersionFilesMetadata(requestParameters: ReportsApiListVersionFilesMetadataRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).listVersionFilesMetadata(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Front-end specific endpoint
     * @param {ReportsApiListVersionFilesReadyForProcessingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listVersionFilesReadyForProcessing(requestParameters: ReportsApiListVersionFilesReadyForProcessingRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).listVersionFilesReadyForProcessing(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all the visual excels available for the specific version
     * @param {ReportsApiListVersionVisualExcelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listVersionVisualExcels(requestParameters: ReportsApiListVersionVisualExcelsRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).listVersionVisualExcels(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiListVersionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listVersions(requestParameters: ReportsApiListVersionsRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).listVersions(requestParameters.clientId, requestParameters.reportId, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiLockReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public lockReport(requestParameters: ReportsApiLockReportRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).lockReport(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiMarkChecksBelowThresholdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public markChecksBelowThreshold(requestParameters: ReportsApiMarkChecksBelowThresholdRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).markChecksBelowThreshold(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, requestParameters.markValidationChecksBelowThresholdValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set the status and user-created properties to their defaults
     * @summary Resets the checks to the default state
     * @param {ReportsApiMarkChecksPendingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public markChecksPending(requestParameters: ReportsApiMarkChecksPendingRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).markChecksPending(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, requestParameters.markValidationChecksPendingValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiStartReportWorkflowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public startReportWorkflow(requestParameters: ReportsApiStartReportWorkflowRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).startReportWorkflow(requestParameters.clientId, requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiUploadReportFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public uploadReportFile(requestParameters: ReportsApiUploadReportFileRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).uploadReportFile(requestParameters.clientId, requestParameters.reportId, requestParameters.file, requestParameters.framework, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiWaiveChecksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public waiveChecks(requestParameters: ReportsApiWaiveChecksRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).waiveChecks(requestParameters.clientId, requestParameters.reportId, requestParameters.versionId, requestParameters.waiveValidationChecksValidator, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TaxonomiesApi - axios parameter creator
 * @export
 */
export const TaxonomiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/taxonomies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxonomiesApi - functional programming interface
 * @export
 */
export const TaxonomiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxonomiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxonomyDtoListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TaxonomiesApi.get']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TaxonomiesApi - factory interface
 * @export
 */
export const TaxonomiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxonomiesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: RawAxiosRequestConfig): AxiosPromise<TaxonomyDtoListRepresentation> {
            return localVarFp.get(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaxonomiesApi - object-oriented interface
 * @export
 * @class TaxonomiesApi
 * @extends {BaseAPI}
 */
export class TaxonomiesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxonomiesApi
     */
    public get(options?: RawAxiosRequestConfig) {
        return TaxonomiesApiFp(this.configuration).get(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.get']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.get(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public get(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).get(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersAdminApi - axios parameter creator
 * @export
 */
export const UsersAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectClient: async (id: string, clientId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('connectClient', 'id', id)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('connectClient', 'clientId', clientId)
            const localVarPath = `/api/admin/users/{id}/clients/{clientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserClientsValidator} [userClientsValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectClients: async (id: string, userClientsValidator?: UserClientsValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('connectClients', 'id', id)
            const localVarPath = `/api/admin/users/{id}/clients`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userClientsValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserValidator} [userValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (userValidator?: UserValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectClient: async (id: string, clientId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('disconnectClient', 'id', id)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('disconnectClient', 'clientId', clientId)
            const localVarPath = `/api/admin/users/{id}/clients/{clientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/api/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ConnectivityType} connectivityType 
         * @param {ClientSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientList: async (id: string, connectivityType: ConnectivityType, sort?: ClientSort, direction?: Direction, limit?: number, offset?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClientList', 'id', id)
            // verify required parameter 'connectivityType' is not null or undefined
            assertParamExists('getClientList', 'connectivityType', connectivityType)
            const localVarPath = `/api/admin/users/{id}/clients/{connectivityType}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"connectivityType"}}`, encodeURIComponent(String(connectivityType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['Sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList: async (sort?: UserSort, direction?: Direction, limit?: number, offset?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['Sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserValidator} [userValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, userValidator?: UserValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            const localVarPath = `/api/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserCountriesValidator} [userCountriesValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminCountries: async (id: string, userCountriesValidator?: UserCountriesValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdminCountries', 'id', id)
            const localVarPath = `/api/admin/users/{id}/countries`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCountriesValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} clientId 
         * @param {ClientUserValidator} [clientUserValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientConnection: async (id: string, clientId: string, clientUserValidator?: ClientUserValidator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientConnection', 'id', id)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('updateClientConnection', 'clientId', clientId)
            const localVarPath = `/api/admin/users/{id}/clients/{clientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientUserValidator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersAdminApi - functional programming interface
 * @export
 */
export const UsersAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAdminApi._delete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectClient(id: string, clientId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectClient(id, clientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAdminApi.connectClient']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UserClientsValidator} [userClientsValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectClients(id: string, userClientsValidator?: UserClientsValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectClients(id, userClientsValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAdminApi.connectClients']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserValidator} [userValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(userValidator?: UserValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(userValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAdminApi.create']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnectClient(id: string, clientId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disconnectClient(id, clientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAdminApi.disconnectClient']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAdminApi.get']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ConnectivityType} connectivityType 
         * @param {ClientSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientList(id: string, connectivityType: ConnectivityType, sort?: ClientSort, direction?: Direction, limit?: number, offset?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAdminDtoListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientList(id, connectivityType, sort, direction, limit, offset, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAdminApi.getClientList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserSort} [sort] 
         * @param {Direction} [direction] ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getList(sort?: UserSort, direction?: Direction, limit?: number, offset?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDtoListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getList(sort, direction, limit, offset, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAdminApi.getList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UserValidator} [userValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, userValidator?: UserValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, userValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAdminApi.update']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UserCountriesValidator} [userCountriesValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminCountries(id: string, userCountriesValidator?: UserCountriesValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminCountries(id, userCountriesValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAdminApi.updateAdminCountries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} clientId 
         * @param {ClientUserValidator} [clientUserValidator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientConnection(id: string, clientId: string, clientUserValidator?: ClientUserValidator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientConnection(id, clientId, clientUserValidator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAdminApi.updateClientConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersAdminApi - factory interface
 * @export
 */
export const UsersAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersAdminApiFp(configuration)
    return {
        /**
         * 
         * @param {UsersAdminApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: UsersAdminApiDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp._delete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersAdminApiConnectClientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectClient(requestParameters: UsersAdminApiConnectClientRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.connectClient(requestParameters.id, requestParameters.clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersAdminApiConnectClientsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectClients(requestParameters: UsersAdminApiConnectClientsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.connectClients(requestParameters.id, requestParameters.userClientsValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersAdminApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: UsersAdminApiCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.create(requestParameters.userValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersAdminApiDisconnectClientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectClient(requestParameters: UsersAdminApiDisconnectClientRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.disconnectClient(requestParameters.id, requestParameters.clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersAdminApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: UsersAdminApiGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.get(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersAdminApiGetClientListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientList(requestParameters: UsersAdminApiGetClientListRequest, options?: RawAxiosRequestConfig): AxiosPromise<ClientAdminDtoListRepresentation> {
            return localVarFp.getClientList(requestParameters.id, requestParameters.connectivityType, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersAdminApiGetListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(requestParameters: UsersAdminApiGetListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<UserDtoListRepresentation> {
            return localVarFp.getList(requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersAdminApiUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(requestParameters: UsersAdminApiUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.update(requestParameters.id, requestParameters.userValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersAdminApiUpdateAdminCountriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminCountries(requestParameters: UsersAdminApiUpdateAdminCountriesRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.updateAdminCountries(requestParameters.id, requestParameters.userCountriesValidator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersAdminApiUpdateClientConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientConnection(requestParameters: UsersAdminApiUpdateClientConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateClientConnection(requestParameters.id, requestParameters.clientId, requestParameters.clientUserValidator, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in UsersAdminApi.
 * @export
 * @interface UsersAdminApiDeleteRequest
 */
export interface UsersAdminApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiDelete
     */
    readonly id: string
}

/**
 * Request parameters for connectClient operation in UsersAdminApi.
 * @export
 * @interface UsersAdminApiConnectClientRequest
 */
export interface UsersAdminApiConnectClientRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiConnectClient
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiConnectClient
     */
    readonly clientId: string
}

/**
 * Request parameters for connectClients operation in UsersAdminApi.
 * @export
 * @interface UsersAdminApiConnectClientsRequest
 */
export interface UsersAdminApiConnectClientsRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiConnectClients
     */
    readonly id: string

    /**
     * 
     * @type {UserClientsValidator}
     * @memberof UsersAdminApiConnectClients
     */
    readonly userClientsValidator?: UserClientsValidator
}

/**
 * Request parameters for create operation in UsersAdminApi.
 * @export
 * @interface UsersAdminApiCreateRequest
 */
export interface UsersAdminApiCreateRequest {
    /**
     * 
     * @type {UserValidator}
     * @memberof UsersAdminApiCreate
     */
    readonly userValidator?: UserValidator
}

/**
 * Request parameters for disconnectClient operation in UsersAdminApi.
 * @export
 * @interface UsersAdminApiDisconnectClientRequest
 */
export interface UsersAdminApiDisconnectClientRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiDisconnectClient
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiDisconnectClient
     */
    readonly clientId: string
}

/**
 * Request parameters for get operation in UsersAdminApi.
 * @export
 * @interface UsersAdminApiGetRequest
 */
export interface UsersAdminApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiGet
     */
    readonly id: string
}

/**
 * Request parameters for getClientList operation in UsersAdminApi.
 * @export
 * @interface UsersAdminApiGetClientListRequest
 */
export interface UsersAdminApiGetClientListRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiGetClientList
     */
    readonly id: string

    /**
     * 
     * @type {ConnectivityType}
     * @memberof UsersAdminApiGetClientList
     */
    readonly connectivityType: ConnectivityType

    /**
     * 
     * @type {ClientSort}
     * @memberof UsersAdminApiGetClientList
     */
    readonly sort?: ClientSort

    /**
     * ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
     * @type {Direction}
     * @memberof UsersAdminApiGetClientList
     */
    readonly direction?: Direction

    /**
     * 
     * @type {number}
     * @memberof UsersAdminApiGetClientList
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof UsersAdminApiGetClientList
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiGetClientList
     */
    readonly search?: string
}

/**
 * Request parameters for getList operation in UsersAdminApi.
 * @export
 * @interface UsersAdminApiGetListRequest
 */
export interface UsersAdminApiGetListRequest {
    /**
     * 
     * @type {UserSort}
     * @memberof UsersAdminApiGetList
     */
    readonly sort?: UserSort

    /**
     * ASC -&gt; Ascending (A-Z); DESC -&gt; Descending (Z-A)
     * @type {Direction}
     * @memberof UsersAdminApiGetList
     */
    readonly direction?: Direction

    /**
     * 
     * @type {number}
     * @memberof UsersAdminApiGetList
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof UsersAdminApiGetList
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiGetList
     */
    readonly search?: string
}

/**
 * Request parameters for update operation in UsersAdminApi.
 * @export
 * @interface UsersAdminApiUpdateRequest
 */
export interface UsersAdminApiUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UserValidator}
     * @memberof UsersAdminApiUpdate
     */
    readonly userValidator?: UserValidator
}

/**
 * Request parameters for updateAdminCountries operation in UsersAdminApi.
 * @export
 * @interface UsersAdminApiUpdateAdminCountriesRequest
 */
export interface UsersAdminApiUpdateAdminCountriesRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiUpdateAdminCountries
     */
    readonly id: string

    /**
     * 
     * @type {UserCountriesValidator}
     * @memberof UsersAdminApiUpdateAdminCountries
     */
    readonly userCountriesValidator?: UserCountriesValidator
}

/**
 * Request parameters for updateClientConnection operation in UsersAdminApi.
 * @export
 * @interface UsersAdminApiUpdateClientConnectionRequest
 */
export interface UsersAdminApiUpdateClientConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiUpdateClientConnection
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UsersAdminApiUpdateClientConnection
     */
    readonly clientId: string

    /**
     * 
     * @type {ClientUserValidator}
     * @memberof UsersAdminApiUpdateClientConnection
     */
    readonly clientUserValidator?: ClientUserValidator
}

/**
 * UsersAdminApi - object-oriented interface
 * @export
 * @class UsersAdminApi
 * @extends {BaseAPI}
 */
export class UsersAdminApi extends BaseAPI {
    /**
     * 
     * @param {UsersAdminApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    public _delete(requestParameters: UsersAdminApiDeleteRequest, options?: RawAxiosRequestConfig) {
        return UsersAdminApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersAdminApiConnectClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    public connectClient(requestParameters: UsersAdminApiConnectClientRequest, options?: RawAxiosRequestConfig) {
        return UsersAdminApiFp(this.configuration).connectClient(requestParameters.id, requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersAdminApiConnectClientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    public connectClients(requestParameters: UsersAdminApiConnectClientsRequest, options?: RawAxiosRequestConfig) {
        return UsersAdminApiFp(this.configuration).connectClients(requestParameters.id, requestParameters.userClientsValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersAdminApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    public create(requestParameters: UsersAdminApiCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return UsersAdminApiFp(this.configuration).create(requestParameters.userValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersAdminApiDisconnectClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    public disconnectClient(requestParameters: UsersAdminApiDisconnectClientRequest, options?: RawAxiosRequestConfig) {
        return UsersAdminApiFp(this.configuration).disconnectClient(requestParameters.id, requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersAdminApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    public get(requestParameters: UsersAdminApiGetRequest, options?: RawAxiosRequestConfig) {
        return UsersAdminApiFp(this.configuration).get(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersAdminApiGetClientListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    public getClientList(requestParameters: UsersAdminApiGetClientListRequest, options?: RawAxiosRequestConfig) {
        return UsersAdminApiFp(this.configuration).getClientList(requestParameters.id, requestParameters.connectivityType, requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersAdminApiGetListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    public getList(requestParameters: UsersAdminApiGetListRequest = {}, options?: RawAxiosRequestConfig) {
        return UsersAdminApiFp(this.configuration).getList(requestParameters.sort, requestParameters.direction, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersAdminApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    public update(requestParameters: UsersAdminApiUpdateRequest, options?: RawAxiosRequestConfig) {
        return UsersAdminApiFp(this.configuration).update(requestParameters.id, requestParameters.userValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersAdminApiUpdateAdminCountriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    public updateAdminCountries(requestParameters: UsersAdminApiUpdateAdminCountriesRequest, options?: RawAxiosRequestConfig) {
        return UsersAdminApiFp(this.configuration).updateAdminCountries(requestParameters.id, requestParameters.userCountriesValidator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersAdminApiUpdateClientConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    public updateClientConnection(requestParameters: UsersAdminApiUpdateClientConnectionRequest, options?: RawAxiosRequestConfig) {
        return UsersAdminApiFp(this.configuration).updateClientConnection(requestParameters.id, requestParameters.clientId, requestParameters.clientUserValidator, options).then((request) => request(this.axios, this.basePath));
    }
}



