import React from 'react';
import Select, { components } from 'react-select';
import { Icon } from '~/ui/components/icon/index';

import s from './styles.module.scss';
import { fontFamily } from '~/ui/utils/MUItheme';

const customStyles = (isDisabled, secondary) => ({
  container: (base, { selectProps }) => ({
    ...base,
    color: '#d1d1d1',
    fontSize: 16,
    fontWeight: 300,
    fontFamily: fontFamily,
    width: '100%',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: isDisabled ? '#CFD8DC' : '#d1d1d1',
    '&:hover': {
      cursor: 'default',
      borderColor: selectProps.menuIsOpen ? 'transparent' : '#90A4AE',
    },
    borderRadius: '4px',
    boxShadow: selectProps.menuIsOpen && '0px 0px 0px 2px #d04a02',
    '&:focus': {
      outline: '3px solid #d04a02',
    },
    '&:focus-visible': {
      outline: 'none',
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    color: '#445A64',
    paddingRight: 0,
    '&:hover': {
      cursor: 'pointer',
      color: '#f44336',
    },
  }),
  control: (base, { selectProps, isFocused }) => ({
    ...base,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: isDisabled ? '#eceff1' : secondary ? '#d04a02' : '#fff',
    cursor: selectProps.menuIsOpen && 'text',
    border: 'none',
    borderRadius: '4px',
    minHeight: 36,
    padding: secondary ? '6px 12px' : '6px 0',
    '&:focus-visible': {
      outline: 'none',
    },
    boxShadow: isFocused ? 0 : 0,
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: 10,
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: fontFamily,
    color: '#404041',
  }),
  singleValue: (base) => ({
    ...base,
    paddingLeft: '1px',
    top: '49%',
  }),
  menu: (base) => ({
    ...base,
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: 0,
    marginTop: 2,
    padding: 0,
    zIndex: 100500,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 100500,
  }),
  menuList: (base) => ({
    ...base,
    padding: '10px',
  }),
  multiValue: () => ({
    display: 'none',
  }),
  option: (base, { isSelected }) => ({
    ...base,
    color: '#474747',
    backgroundColor: isSelected ? '#F3F3F3' : '#ffffff',
    fontSize: 14,
    fontWeight: 'medium',
    fontFamily: fontFamily,
    padding: '9px 0 11px 12px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: '#F3F3F3',
      color: '#474747',
    },
    cursor: 'pointer',
  }),
  placeholder: (base) => ({
    ...base,
    color: secondary && !isDisabled ? '#fff' : '#696969',
    fontSize: secondary ? 14 : 16,
    fontWeight: secondary ? 700 : 300,
    fontFamily: fontFamily,
  }),
  dropdownIndicator: (base, { selectProps }) => ({
    ...base,
    color: secondary && !isDisabled ? '#fff' : '#445A64',
    padding: 12,
    transform: selectProps.menuIsOpen && 'rotate(180deg)',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: secondary && !isDisabled ? '#fff' : 'gray',
    display: secondary ? 'block' : 'none',
  }),
});

const DropdownIndicator = (props) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <Icon
        icon={Icon.icons.chevronDown}
        className={s.multiSelectDropdown}
        style={{ color: props.secondary && !props.isDisabled ? 'white' : 'black' }}
      />
    </components.DropdownIndicator>
  );

const ClearIndicator = (props) =>
  components.ClearIndicator && (
    <components.ClearIndicator {...props}>
      <Icon icon={Icon.icons.close} className={s.multiSelectClear} />
    </components.ClearIndicator>
  );

class SelectInput extends React.Component<any> {
  static defaultProps = {
    placeholder: 'Placeholder',
  };

  onDelete = (option) => {
    const { value, name, setFieldValue } = this.props;
    const newOptions = value.filter((o) => o !== option);
    setFieldValue(name, newOptions);
  };

  render() {
    const { placeholder, isDisabled, secondary, dataCy } = this.props;
    return (
      <div data-cy={dataCy}>
        <Select
          // @ts-expect-error - 'menuPortal' is specified more than once, so this usage will be overwritten.
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), ...customStyles(isDisabled, secondary) }}
          noOptionsMessage={() => 'Not found'}
          loadingMessage={() => 'Loading...'}
          placeholder={placeholder}
          components={{
            DropdownIndicator: (props) => <DropdownIndicator secondary={secondary} {...props} />,
            ClearIndicator,
          }}
          menuPortalTarget={document.body}
          closeMenuOnSelect
          {...this.props}
        />
      </div>
    );
  }
}

// SelectInput.propTypes = {
//   placeholder: PropTypes.string,
//   setFieldValue: PropTypes.func.isRequired,
//   isDisabled: PropTypes.bool.isRequired,
//   value: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
// };

export { SelectInput };
